import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const CrearRecurso = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const formData = new FormData();
        let extraData = params.extraData
        for (const key in extraData) {
            if (extraData.hasOwnProperty(key)) {
                formData.append(key, extraData[key]);
            }
        }
        params.files.forEach(file => {
            formData.append('files[]', file, file.name );
        })
        const data  = await api.post(`/recursos`, formData, {
            dataType: "formData",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        crearRecursoMutation: mutation.mutate,
    };
}