import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const DeleteItemFormato = () => {
  const api = useApi();

  const mutate = async (params) => {
    const { data } = await api.del(`/formatos-etiquetas-item/${params}`, {
      dataType: "json",
      hasFormData: false,
      hasCustomUrl: false,
    });
    return data;
  };

  const mutation = useMutation(mutate);

  return {
    deleteItemFormato: mutation.mutate,
  };
};
