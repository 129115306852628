import { message } from "antd";

export const getFileExt = (fileName) => {
    let sFileName = fileName.split('.');
    return sFileName[sFileName.length - 1].toLocaleLowerCase();
}


export const extFileValidate = (extensions, files) => {
    if (extensions) {
        let isValid = true;
        extensions = extensions.map(ext => ext.toLowerCase());
        for (let file of files) {
            let ext = getFileExt(file.name);
            if (!extensions.includes(ext.toLocaleLowerCase())) {
                isValid = false;
            }
        }
        if (!isValid)
            message.error("Tipo de archivo no permitido");
        return isValid;
    } else {
        return true;
    }
}
