import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetMovOrdenes = ()  => {
    const api = useApi();

    const mutate = async (param) => {
        const data  = await api.get(`/documentos/orden/movimientos/${param}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getMovOrdenesMutation: mutation.mutate,
    };
}

export const GetMovOrdenesNew = ()  => {
    const api = useApi();

    const mutate = async (param) => {
        const data  = await api.get(`/movimientos-inventario/salidas/empresa/${param.empresa}/estatus/${param.tipo}/orden/${param.orden}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getMovOrdenesNewMutation: mutation.mutate,
    };
}