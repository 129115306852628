import { EstatusMovimientoEnum } from "../@enums/status_mov_inv";
import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetExistenciaIndividual = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/movimientos-inventario/almacen/${params.almacen}/parte/${params.parte}/status/${EstatusMovimientoEnum.ACTIVO}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getExistenciaIndividualMutation: mutation.mutate,
    };
}

export const GetExistenciaIndividualCargado = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/movimientos-inventario/almacen/${params.almacen}/parte/${params.parte}/status/${EstatusMovimientoEnum.CARGADO}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getExistenciaIndividualCargadoMutation: mutation.mutate,
    };
}

export const GetExistenciaIndividualProcesado = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/movimientos-inventario/almacen/${params.almacen}/parte/${params.parte}/status/${EstatusMovimientoEnum.PROCESADO}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getExistenciaIndividualProcesadoMutation: mutation.mutate,
    };
}