import useApi from "./useApiRequest";
import { useMutation } from "react-query";


export const UseLogin = () => {
    const api = useApi();

    const mutate = async (params) => {
            const data  = await api.noSessionPost(`/login`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        mutateLogin: mutation.mutate,
    };
};