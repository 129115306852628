import { ThemeContext } from "@emotion/react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import React, { useContext } from "react";
import { TableVirtuoso } from "react-virtuoso";


export const TablaEnviados = ({sample, setOrden}) => {

  const theme = useContext(ThemeContext);
  const rows = sample;

  const columns = [
    {
        width: 30,
        label: 'Folio',
        dataKey: 'docu_folio',
    },
    {
        width: 30,
        label: 'Fecha',
        dataKey: 'docu_fecha',
    },
    {
        width: 50,
        label: 'Cliente',
        dataKey: 'cliente',
    },
  ];

  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };

  function fixedHeaderContent() {
      return (
          <TableRow>
              {columns.map((column) => (
              <TableCell
                  key={column.dataKey}
                  variant="head"
                  align="center"
                  style={{ width: column.width }}
                  sx={{
                      display: 'table-cell',
                  backgroundColor: theme.palette.primary.main,
                  color:'white',
                  fontWeight:600
                  }}
              >
                  {column.label}
              </TableCell>
              ))}
          </TableRow>
      );
  }

  function rowContent(_index, row) {
    return (
        <React.Fragment>
            {columns.map((column) => (
                <TableCell
                key={column.dataKey}
                align="center"
                sx={{
                    display: 'table-cell !important',
                    fontWeight:500,
                    color: theme.palette.primary.input,
                    background: 'white'
                }}
                onClick={()=>setOrden(row)}
                >
                    {column.dataKey === 'cliente' ? (
                        <>
                            {row['empresas']?.emp_razonsocial}
                        </>
                    ):column.dataKey === 'docu_fecha'? (
                        <>{ moment(row['docu_fecharegistro'], "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY")}</>
                    ) : (
                        (
                            <>
                                {
                                    row[column.dataKey] === null 
                                    ? '' : row[column.dataKey]}
                            </>
                        )
                    )}
                </TableCell>
            ))}
        </React.Fragment>
    );
  }

  return(
    <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
        <TableVirtuoso
            data={rows.sort((a, b) => b.docu_id - a.docu_id)}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
            style={{
                boxShadow:'none',
                border:'1px solid #f2f3f3'
            }}
        />
    </Paper>
  )
}