import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { GetItemFormato } from "../../../hooks/getItemFormato";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { EditItemForm } from "./componentes/EditItemForm";
import { GetCamposExtra } from "../../../hooks/getAllCamposExtra";
import { GetAllEmpresas } from "../../../hooks/getAllEmpresas";

export const EtiquetasEditarItem = () => {
  const [camposExtra, setCamposExtra] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const dispatch = useDispatch();
  const { getFormatoItem } = GetItemFormato();
  const { getAllCamposExtra } = GetCamposExtra();
  const { getAllEmpresasMutation } = GetAllEmpresas();
  const [formatoItemData, setFormatoItemData] = useState();
  const { id } = useParams();
  useEffect(() => {
    dispatch(HEADER_DATA_UPDATE({ nombreData: "Editar Item" }));
    getFormatoItem(id, { onSuccess: (data) => {
        setFormatoItemData(data);
      },
      onError: (error) => {
        alert("Error al cargar los datos");
      },
    });
    getAllCamposExtra("2", {
      onSuccess: (data) => {
        setCamposExtra(data);
      },
      onError: (error) => {
        alert("Error al cargar los datos");
      },
    });
    getAllEmpresasMutation("", {
      onSuccess: (data) => {
        data.unshift({ emp_id: 0, emp_rfc: "Todas" });
        setEmpresas(data);
      },
      onError: (error) => {
        alert("Error al cargar los datos");
      },
    });
  }, []);
  return (
    <>
      {formatoItemData && (
        <EditItemForm
          defaultData={formatoItemData}
          camposExtra={camposExtra}
          empresas={empresas}
        />
      )}
    </>
  );
};
