function isNumberString(str) {
    // Verifica si la cadena puede ser convertida a un número
    return !isNaN(str) && !isNaN(parseFloat(str));
}

function isArrayString(str) {
    // Verifica si la cadena puede ser un array en formato JSON
    try {
        let isArr = {
            resp: false,
            data: null
        }
        let parsed = JSON.parse(str);
        if(Array.isArray(parsed)){
            isArr.resp = true
            isArr.data = parsed
        }else{
            let second = JSON.parse(parsed)
            if(Array.isArray(second)){
                isArr.resp = true;
                isArr.data = second
            }
        }
        
        return isArr;
    } catch (e) {
        return false;
    }
}

function processStrings(string) {
    if (isNumberString(string)) {
        return JSON.parse(string);  // Convierte a número
    } else if (isArrayString(string).resp) {
        return isArrayString(string).data;  // Convierte a array
    } else {
        return string;  // Deja la cadena de texto sin cambios
    }
}

export default processStrings;