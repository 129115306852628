import { ThemeContext } from "@emotion/react";
import React, { useContext, useRef, useState } from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableContainer,
  TextField,
  Typography,
} from "@mui/material";
import { ProcesarMovimiento } from "../../../hooks/procesarMov";
import swal from "sweetalert2";
import { useLoading } from "../../../components/Loading/useLoading";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const TableProcesosPanel = ({
  sample,
  codes,
  almacen,
  movimientos,
  get_cargados,
  get_procesados,
  setIsReporting,
  setMovReporting,
  canReport
}) => {
  const theme = useContext(ThemeContext);
  const inputCodigo = useRef(null);
  const inputCantidad = useRef(null);
  const inputRack = useRef(null);
  const inputEstacion = useRef(null);
  const { procesarMovimientoMutation } = ProcesarMovimiento();
  const [cantidad, setCantidad] = useState(null);
  const [rack, setRack] = useState(null);
  const [estacion, setEstacion] = useState(null);
  const [errorCode, setErrorCode] = useState(false);
  const [errorCantidad, setErrorCantidad] = useState(false);
  const [product, setProduct] = useState(null);
  const [valueAuto, setValueAuto] = useState(null);
  const { setAppLoading } = useLoading();

  const rows = sample;

  const autocomplete_code = (e) => {
    if (e !== null) {
      setProduct(e);
      setValueAuto(e);
      setErrorCode(false);
      inputCantidad.current.focus();
    } else {
      setErrorCode(true);
      setProduct(null);
    }
  };

  const validate = (value) => {
    let mov = movimientos.find((item) => item?.part_id === product?.id);
    if (
      product === null ||
      value === "" ||
      parseInt(value) > mov?.existencia_total ||
      parseInt(value) < 1 ||
      isNaN(parseInt(value))
    ) {
      if (product === null) setErrorCode(true);
      if (
        value === "" ||
        parseInt(value) > mov?.existencia_total ||
        parseInt(value) < 1 ||
        isNaN(parseInt(value))
      )
        setErrorCantidad(true);
      return false;
    } else {
      setErrorCantidad(false);
      setErrorCode(false);
      return true;
    }
  };

  const procesar = (e) => {
    if (e.key === "Enter") {
      setAppLoading(true);
      if (validate(cantidad)) {
        let data = {
          cantidad: cantidad,
          almacen: almacen,
          parte: product?.id,
          rack: rack,
          estacion: estacion
        };
        procesarMovimientoMutation(data, {
          onSuccess: (data) => {
            if (data?.success) {
              setAppLoading(false);
              swal
                .fire({
                  position: "top-end",
                  icon: "success",
                  title: data?.mensaje,
                  showConfirmButton: false,
                  timer: 1000,
                })
                .then(() => {
                  inputCantidad.current.value = "";
                  inputRack.current.value = "";
                  inputEstacion.current.value = "";
                  inputCodigo.current.focus();
                  get_cargados(almacen);
                  get_procesados(almacen);
                });
            } else {
              swal.fire({
                position: "top-end",
                icon: "error",
                title: data?.mensaje,
                showConfirmButton: false,
                timer: 1000,
              });
            }
            setValueAuto(null);
            setProduct(null);
            setCantidad(null);
            setEstacion(null);
            setRack(null);
            inputCodigo.current.focus();
            get_cargados(almacen);
            get_procesados(almacen);
          },
          onError: (error) => {
            setAppLoading(false);
            swal.fire({
              position: "top-end",
              icon: "error",
              title: error?.mensaje,
              showConfirmButton: false,
              timer: 1000,
            });
            setValueAuto(null)
            get_cargados(almacen);
            get_procesados(almacen);
          },
        });
      } else {
        setAppLoading(false);
      }
    }
    get_cargados(almacen);
    get_procesados(almacen);
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [movimientos, setMovimientos] = useState([]);

    const goReporting = (mov) => {
      const data = rows.filter(item => item.part_id === mov && item.activo);
      const movimiento = data[0]
      const sumaExistenciaTotal = data.reduce((acumuladorProcesos, procesoActual) => {
        const sumaProceso = procesoActual?.datos?.reduce((acumuladorDatos, datoActual) => acumuladorDatos + datoActual?.existencia_total
        , 0);
        return acumuladorProcesos + sumaProceso;
      }, 0);
      movimiento.existencia_total = sumaExistenciaTotal
      setMovReporting({...movimiento})
      setIsReporting(true)
    }
    const open_subtabla = (index) => {
      if (open) {
        setOpen(!open);
        setMovimientos([]);
      } else {
        get_movimientos(index);
        setOpen(!open);
      }
    };
    const get_movimientos= () => {
      setMovimientos(row?.datos)
    }

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell sx={{p:0.5}}>
            <IconButton
              aria-label="expand row"
              size="small"
              style={{width:25}}
              onClick={() => open_subtabla(row?.part_id)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:0.5
            }}
          >
            {row.codigo}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:0.5
            }}
          >
            {row.nombre}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:0.5
            }}
          >
            {row?.acciones && canReport && row?.activo &&
              <Button
              variant="contained"
              className="btn_system"
              sx={{ fontSize: 12, height: 35 }}
              disableElevation
              onClick={()=>goReporting(row?.part_id)}
              >
                Reportar
              </Button>
            }
            { canReport && !row?.activo &&
              <Chip label="Reportado" variant="outlined" sx={{borderRadius:2}}/>
            }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Desgloce de movimientos
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ color: theme.palette.primary.main, fontSize: 16 }}
                      >
                        Serial
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: theme.palette.primary.main, fontSize: 16 }}
                      >
                        Etiqueta
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: theme.palette.primary.main, fontSize: 16 }}
                      >
                        Cantidad
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {movimientos.map((mov) => (
                      <TableRow key={mov?.moin_id}>
                        <TableCell align="center">
                          {mov?.serial}
                        </TableCell>
                        <TableCell align="center">
                          {mov?.tag}
                        </TableCell>
                        <TableCell align="center">
                          {mov?.existencia_total}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <Grid container spacing={0} sx={{ display: "flex", alignItems: "start" }}>
      <Grid item xs={6} sx={{ p: 0, pr: 1, mb:errorCantidad ? 3 : 0 }}>
        {codes !== null && (
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={codes}
            onChange={(e, newValue) => autocomplete_code(newValue)}
            value={valueAuto}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Código"
                inputRef={inputCodigo}
                error={errorCode}
                helperText={errorCode ? "Ingrese un código válido" : ""}
                size="small"
              />
            )}
          />
        )}
      </Grid>
      <Grid item xs={6} sx={{ p: 0, pl: 1, mb:errorCantidad ? 3 : 0 }}>
        <TextField
          id="cantidad_reporte"
          label="Ingrese una cantidad válida"
          fullWidth
          size="small"
          inputRef={inputCantidad}
          onKeyDown={(e) => procesar(e)}
          onChange={(e)=> setCantidad(e.target.value !== '' ? parseInt(e.target.value): null)}
          error={errorCantidad}
          helperText={errorCantidad ? "Ingrese una cantidad válida" : ""}
          sx={{ color: theme.palette.primary.input, height: "10px !important" }}
        />
      </Grid>
      <Grid item xs={6} sx={{ p: 0, pr: 1, mt:1 }}>
        <TextField
          id="rack"
          label="Rack/Barril"
          fullWidth
          size="small"
          inputRef={inputRack}
          onKeyDown={(e) => procesar(e)}
          onChange={(e)=>setRack(e.target.value)}
          sx={{ color: theme.palette.primary.input, height: "10px !important" }}
        />
      </Grid>
      <Grid item xs={6} sx={{ p: 0, pl: 1, mt:1 }}>
        <TextField
          id="estacion"
          label="Estación recubrimiento"
          fullWidth
          size="small"
          inputRef={inputEstacion}
          onKeyDown={(e) => procesar(e)}
          onChange={(e) => setEstacion(e.target.value)}
          sx={{ color: theme.palette.primary.input, height: "10px !important" }}
        />
      </Grid>
      <Grid item xs={12} sx={{ p: 0, mt: 4.5 }}>
        <Paper
          style={{ maxHeight: '100vh', overflowY: "scroll", width: "100%" }}
          sx={{ boxShadow: "none" }}
        >
        <Typography variant="h8" sx={{fontWeight:500}} gutterBottom component="div">
          Procesados
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead
              sx={{
                display: "table-head",
                backgroundColor: theme.palette.primary.main,
                fontWeight: 600,
              }}
            >
              <TableRow>
                <TableCell align="center" sx={{ color: "white" }}/>
                <TableCell align="center" sx={{ color: "white" }}>
                  Código
                </TableCell>
                <TableCell align="center" sx={{ color: "white" }}>
                  Nombre
                </TableCell>
                <TableCell align="center" sx={{ color: "white" }}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => (
                <Row key={index} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TableProcesosPanel;
