import { addEdge, Background, ReactFlow} from "@xyflow/react"
import { useCallback, useState } from "react";
import "@xyflow/react/dist/style.css";
import { Divider, IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";




export const MapaTrazabilidad = ({initialEdges, initialNodes, onNodesChange, onEdgesChange, setEdges, setNodes}) => {

	const [selectedNode, setSelectedNode] = useState(null);

  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges],
  );

	const markNodeAsSelected = (node) => {
		return {
			...node,
			style: {
				...node.style,
				borderColor: "#9dab67",
				scale:1.5
			},
		};
	};

	const markNodeAsUnselected = (node) => {
    return {
      ...node,
      style: {
        ...node.style,
        borderColor: "#000",
				scale: 1
      },
    };
  }

	const onNodeClick = (event, node) => {
		setSelectedNode(node);
		setNodes((nodes) =>
			nodes.map((n) => (n.id === node.id ? markNodeAsSelected(n) : ["input", "output"].includes(n.type) ? n : markNodeAsUnselected(n)))
		);
	};
	

  return(	
    <div
			className="horizontal-container"
			style={{ width: selectedNode !== null ? "90vw" : "100vw", height: "75vh", marginTop: 20 }}
		>
			<ReactFlow
				nodes={initialNodes}
				edges={initialEdges}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				onConnect={onConnect}
				onNodeClick={onNodeClick}
        nodesDraggable={false}  // No permite arrastrar nodos
        nodesConnectable={false} // No permite conectar nodos
        elementsSelectable={false}// Deshabilita el zoom con doble clic
			>
				<Background
					color="#000"
					bgColor="#fff"
					variant="dots"
					gap={12}
					size={1}
				/>
			</ReactFlow>
			{
				selectedNode !== null &&
				<div className="sidebar">
					<div className="sidebar-node-information">
						<div style={{display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
							<span style={{fontSize:20, fontWeight: 600}}>Detalles de Movimiento</span>
							<IconButton
								aria-label="open drawer"
								onClick={()=>setSelectedNode(null)}
								edge="start"
								sx={{
										color:'#B6B6B6',
										marginRight: 0,
										width:'auto',

								}}
							>
								<CloseOutlined />
							</IconButton>
						</div>
						<Divider  style={{width: '100%', border:'0.5px solid #ffffff91'}}/>
						{selectedNode && (
							<>
							{	//folio documento
								selectedNode?.data?.label?.props?.data?.documento && (
									<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15}}>
										<span style={{fontSize:16, fontWeight:400}}>
											Folio de documento:
										</span>
										<span style={{fontSize:16, fontWeight:600, marginLeft:5}}>
											{selectedNode?.data?.label?.props?.data?.documento}
										</span>
									</div>
								)
							}
							{	//etiqueta individual
								selectedNode?.data?.label?.props?.data?.etiqueta && (
									<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15}}>
										<span style={{fontSize:16, fontWeight:400}}>
											Etiqueta:
										</span>
										<span style={{fontSize:16, fontWeight:600, marginLeft:5}}>
											{selectedNode?.data?.label?.props?.data?.etiqueta}
										</span>
									</div>
								)
							}
							{	//array de etiquetas
								selectedNode?.data?.label?.props?.data?.etiquetas && (
									<div style={{marginTop:15}}>
										<span style={{fontSize:16, fontWeight:400}}>
											Etiquetas
										</span>
										<div style={{display:'flex', flexDirection:'column', marginTop:5}}>
											{selectedNode?.data?.label?.props?.data?.etiquetas?.map((etiq, index) => (
												<li key={`etiqueta_${index}`} 	style={{color: 'white', fontWeight: 600}} >
													{etiq}
												</li>
											))}
										</div>
									</div>
								)
							}
							{	//almacen
								selectedNode?.data?.label?.props?.data?.almacen &&
								(
									<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15}}>
										<span style={{fontSize:16, fontWeight:400}}>
											Almacen:
										</span>
										<span style={{fontSize:16, fontWeight:600, marginLeft:5}}>
											{selectedNode?.data?.label?.props?.data?.almacen}
										</span>
									</div>
								)
							}
							{	//cantidad
								selectedNode?.data?.label?.props?.data?.cantidad &&
								(
									<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15}}>
										<span style={{fontSize:16, fontWeight:400}}>
											Cantidad:
										</span>
										<span style={{fontSize:16, fontWeight:600, marginLeft:5}}>
											{selectedNode?.data?.label?.props?.data?.cantidad}
										</span>
									</div>
								)
							}
							
							<div style={{marginTop:15}}>
								<span style={{fontSize:16, fontWeight:400}}>
									Responsables y fechas de movimientos
								</span>
								<div style={{display:'flex', flexDirection:'column', marginTop:5}}>
									{selectedNode?.data?.label?.props?.data?.responsables?.map((resp, index) => (
										<li key={`resp_${index}`} 	style={{color: 'white', fontWeight:600}} >
											{resp}
										</li>
									))}
								</div>
							</div>
							{/* <div style={{marginTop:15}}>
								<span style={{fontSize:16, fontWeight:600}}>
									Fechas de movimientos
								</span>
								<div style={{display:'flex', flexDirection:'column', marginTop:5}}>
									{selectedNode?.data?.label?.props?.data?.fecha?.map(fecha => (
										<li key='cantidad' 	style={{color: 'white'}} >
											{fecha}
										</li>
									))}
								</div>
							</div> */}
							</>
						)}
					</div>
					
			</div>
			}
		</div>
  )
}