import { ThemeContext } from "@emotion/react";
import { Button, Chip, Grid } from "@mui/material";
import React, { useContext} from "react";


const HeaderPanelControl = ({btn_seccion, setOpen, nameAlm, total, isReporting, movimiento, setMovReporting, setIsReporting, setSaveReport, getMovimientos, get_Cargados, get_procesados, alm}) => {

    const theme = useContext(ThemeContext);

    const cancel = () => {
      getMovimientos(alm);
      get_Cargados(alm);
      get_procesados(alm);
      setMovReporting(null);
      setIsReporting(false);
    }

    return(
        <Grid container spacing={0}>
            {isReporting ? (
                <>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{p:1, pl:1, pr:1}} className="center_item">
                      <Grid container spacing={0}>
                        <Grid key='serial_mov' item xs={6} sm={6} md={3} lg={3} xl={3} sx={{p:1, fontWeight:500}} className="center_item">
                          {movimiento?.codigo}
                        </Grid>
                        <Grid key='name_mov' item xs={6} sm={6} md={3} lg={3} xl={3} sx={{p:1, fontWeight:500}} className="center_item">
                          {movimiento?.nombre}
                        </Grid>
                        <Grid key='exist_mov' item xs={6} sm={6} md={3} lg={3} xl={3} sx={{p:1, fontWeight:500}} className="center_item">
                          {`Disponibles: ${movimiento?.existencia_total}`}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{p:1, pl:1, pr:1}} className="center_item">
                      <Grid container spacing={0}>
                        <Grid key='btn_cancel_report' item xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:1}} className="center_item">
                          <Button variant="outlined"
                              className="btn_system"
                              sx={{fontSize:12, height:35}} 
                              disableElevation
                              onClick={()=>cancel()}>
                              Cancelar
                          </Button>
                        </Grid>
                        <Grid key='btn_save_report' item xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:1}} className="center_item">
                          <Button variant="contained"
                              className="btn_system"
                              sx={{fontSize:12, height:35}}
                              disableElevation
                              onClick={()=>setSaveReport(true)}>
                              Guardar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                </>
            ): 
            (
                <>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{p:1, pl:1, pr:1}} className="center_item">
                        <Grid container spacing={0}>
                            {btn_seccion.map((btn) => (
                                <Grid key={btn.name} item xs={6} sm={6} md={btn.id === 5 ? 3 : 2} lg={btn.id === 5 ? 3 : 2} xl={btn.id === 5 ? 3 : 2} sx={{p:1}} className="center_item">
                                    <Button variant="contained"
                                        className="btn_system"
                                        sx={{fontSize:12, height:35}} 
                                        onClick={btn.function}
                                        disableElevation
                                        disabled={btn.disabled}>
                                        {btn.name}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid> 
                    <Grid 
                    key='switch_alm' 
                    item xs={12} sm={12} md={5} lg={5} xl={5} 
                    sx={{p:1, display:'flex', justifyContent:'flex-end', alignItems:'center', pr:2}}
                    >
                        <Chip label={total}  sx={{height:35, borderRadius:2, fontSize:16, fontWeight:600, backgroundColor:theme.palette.primary.main, color: "white", mr:3}}/>
                        <Button 
                        variant="text"
                        onClick={()=>setOpen(true)}>
                            {nameAlm}
                        </Button>
                    </Grid>
                </>
            )}
        </Grid>
    )
}

export default HeaderPanelControl;