import React, { useEffect, useState } from "react";
import TablaClientes from "./components/tabla_clientes";
import { useDispatch } from "react-redux";
import { GetAllEmpresas } from "../../../hooks/getAllEmpresas";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { Button, Divider, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const IndexClientes = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {getAllEmpresasMutation} = GetAllEmpresas()
    const [sample, setSample] = useState([])
    const [filtro, setFiltro] = useState('');

    const get_data_tabla = () => {
        getAllEmpresasMutation({},{
            onSuccess: (data) => {
                setSample(data)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        dispatch(
            HEADER_DATA_UPDATE({nombreData: null})
        )
        get_data_tabla();
    }, []);

    const goNuevo = () => {
        navigate(`/inventarios/clientes/nuevo`);
    }

    const clientesFiltrados = sample.filter((empresa) => {
        return (
            empresa.emp_rfc.toLowerCase().includes(filtro.toLowerCase()) ||
            empresa.emp_codigo.toLowerCase().includes(filtro.toLowerCase()) ||
            empresa.emp_razonsocial.toLowerCase().includes(filtro.toLowerCase())
        );
    });

    const handleInputChange = (event) => {
        setFiltro(event.target.value);
    };

    return(
        <>
            <Grid container spacing={0} sx={{height:'10vh'}}>
                <Grid item xs={3} sm={3} md={3} lg={2} xl={2} sx={{padding:'10px 30px', height:'100%'}} className="center_item">
                    <Button 
                    variant="contained" 
                    className="btn_system" 
                    onClick={goNuevo}>
                        Nuevo
                    </Button>
                </Grid>
            </Grid>
            <Divider />
            <Divider />
            <Grid container spacing={0} sx={{height:'70vh'}}>
                <Grid item xs={12} sx={{padding:'10px 20px', height:'100%'}} className="center_item">
                    <TablaClientes   key={'table_gen_clies'} sample={sample} filtro={filtro} handleInputChange={handleInputChange} clientesFiltrados={clientesFiltrados}/>
                </Grid>
            </Grid>
        </>
    )
}

export default IndexClientes;