import { persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reducers } from "./reducers";
import { configureStore } from "@reduxjs/toolkit";

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)