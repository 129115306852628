import React, { useContext, useRef, useState } from "react";
import { 
    Button, 
    FormControl, 
    Grid, 
    InputLabel, 
    MenuItem, 
    Select, 
    TextField 
} from "@mui/material";
import { CreateDomicilio } from "../../../../hooks/crearDomicilio";
import { UpdateDomicilio } from "../../../../hooks/updateDomicilio";
import { ThemeContext } from "@emotion/react";
import swal from 'sweetalert2'
import { Cancel, Edit, Save } from "@mui/icons-material";

const FormDomicilio = ({item, setOpen, clie_id, get_data}) => {

    const theme = useContext(ThemeContext);
    const formData = useRef(null);
    const {crearDomicilioMutation} = CreateDomicilio();
    const {updateDomicilioMutation} = UpdateDomicilio();
    const [errorCalle, setErrorCalle] = useState(false);
    const [errorExt, setErrorExt] = useState(false);
    const [errorColonia, setErrorColonia] = useState(false);
    const [errorCp, setErrorCp] = useState(false);
    const [errorEstado, setErrorEstado] = useState(false);
    const [errorPais, setErrorPais] = useState(false);

    const validateForm = () => {
        if(
            formData.current.calle.value === '' ||
            formData.current.colonia.value === '' ||
            formData.current.estado.value === '' ||
            formData.current.exterior.value === '' ||
            formData.current.postal.value === '' ||
            formData.current.pais.value === '' 
        ){
            if(formData.current.calle.value === '') setErrorCalle(true)
            if(formData.current.colonia.value === '') setErrorColonia(true)
            if(formData.current.estado.value === '') setErrorEstado(true)
            if(formData.current.exterior.value === '') setErrorExt(true)
            if(formData.current.postal.value === '') setErrorCp(true)
            if(formData.current.pais.value === '') setErrorPais(true)

            return false;
        }else{
            return true;
        }
    }

    const onCreate = () => {
        if(validateForm()){
            let data = {
                domi_calle: formData.current.calle.value,
                domi_ext: formData.current.exterior.value,
                domi_colonia: formData.current.colonia.value,
                domi_cp: formData.current.postal.value,
                domi_estado: formData.current.estado.value,
                domi_pais: formData.current.pais.value,
                domi_int: formData.current.interior.value,
                domi_municipio: formData.current.municipio.value,
                domi_tipo: formData.current.tipo.value,
                domi_nombre: formData.current.nombre.value,
                domi_descripcion: formData.current.descripcion.value,
                emp_id: clie_id,
                alma_id: null,
            }
            crearDomicilioMutation(data, {
                onSuccess: (data) => {
                    if(data.success){
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Domicilio creado con éxito",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{
                            setTimeout(() => {
                                get_data();
                                onClose()
                            }, 500);
                        });
                    }else{
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Fallo al crear domicilio",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                },
                onError: (error) => {
                    console.log(error)
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Fallo al crear domicilio",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        } 
    }
    const onUpdate = () => {
        if(validateForm()){
            let data = {
                domi_calle: formData.current.calle.value,
                domi_ext: formData.current.exterior.value,
                domi_colonia: formData.current.colonia.value,
                domi_cp: formData.current.postal.value,
                domi_estado: formData.current.estado.value,
                domi_pais: formData.current.pais.value,
                domi_int: formData.current.interior.value,
                domi_municipio: formData.current.municipio.value,
                domi_tipo: formData.current.tipo.value,
                domi_nombre: formData.current.nombre.value,
                domi_descripcion: formData.current.descripcion.value,
                emp_id: clie_id,
                alma_id: null,
            }
            updateDomicilioMutation({id: item?.domi_id, data:data}, {
                onSuccess: (data) => {
                    if(data){
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Domicilio actualizado con éxito",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{
                            setTimeout(() => {
                                get_data();
                                onClose()
                            }, 500);
                        });
                    }else{
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Fallo al actualizar domicilio",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                },
                onError: (error) => {
                    console.log(error)
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Fallo al actualizar domicilio",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        }
    }
    const onClose = () => {
        setOpen();
        formData.current.reset();
    }

    return(
        <form ref={formData} id="form_dom"  style={{width: '100%', padding:'0px 10px'}}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{p:2}} >
                            <TextField
                                id="calle"
                                label="Calle"
                                fullWidth
                                size="small"
                                variant="standard"
                                required
                                defaultValue={item?.domi_calle}
                                onChange={(e)=> {
                                    e.target.value.length > 0 ?  
                                    setErrorCalle(false) :
                                    setErrorCalle(true)
                                }}  
                                error={errorCalle}
                                helperText={errorCalle ? 'Ingrese un valor' : ''}
                                sx={{color: theme.palette.primary.input}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{p:2}} >
                            <TextField
                                    id="colonia"
                                    label="Colonia"
                                    fullWidth
                                    size="small"
                                    variant="standard"
                                    required
                                    defaultValue={item?.domi_colonia}
                                    onChange={(e)=> {
                                        e.target.value.length > 0 ?  
                                        setErrorColonia(false) :
                                        setErrorColonia(true)
                                    }}  
                                    error={errorColonia}
                                    helperText={errorColonia ? 'Ingrese un valor' : ''}
                                    sx={{color: theme.palette.primary.input}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{p:2}} >
                            <TextField
                                    id="municipio"
                                    label="Municipio"
                                    fullWidth
                                    size="small"
                                    variant="standard"
                                    defaultValue={item?.domi_municipio}
                                    sx={{color: theme.palette.primary.input}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{p:2}} >
                            <TextField
                                    id="estado"
                                    label="Estado"
                                    fullWidth
                                    size="small"
                                    variant="standard"
                                    required
                                    defaultValue={item?.domi_estado}
                                    onChange={(e)=> {
                                        e.target.value.length > 0 ?  
                                        setErrorEstado(false) :
                                        setErrorEstado(true)
                                    }}  
                                    error={errorEstado}
                                    helperText={errorEstado ? 'Ingrese un valor' : ''}
                                    sx={{color: theme.palette.primary.input}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{p:2}} >
                            <TextField
                                id="exterior"
                                label="N° Exterior"
                                fullWidth
                                size="small"
                                variant="standard"
                                required
                                defaultValue={item?.domi_ext}
                                onChange={(e)=> {
                                    e.target.value.length > 0 ?  
                                    setErrorExt(false) :
                                    setErrorExt(true)
                                }}  
                                error={errorExt}
                                helperText={errorExt ? 'Ingrese un valor' : ''}
                                sx={{color: theme.palette.primary.input}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{p:2}} >
                            <TextField
                                    id="interior"
                                    label="N° Interior"
                                    fullWidth
                                    size="small"
                                    variant="standard"
                                    defaultValue={item?.domi_int}
                                    sx={{color: theme.palette.primary.input}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{p:2}} >
                            <TextField
                                    id="postal"
                                    label="Código Postal"
                                    fullWidth
                                    size="small"
                                    variant="standard"
                                    required
                                    defaultValue={item?.domi_cp}
                                    onChange={(e)=> {
                                        e.target.value.length > 0 ?  
                                        setErrorCp(false) :
                                        setErrorCp(true)
                                    }}  
                                    error={errorCp}
                                    helperText={errorCp ? 'Ingrese un valor' : ''}
                                    sx={{color: theme.palette.primary.input}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{p:2}} >
                            <TextField
                                    id="pais"
                                    label="País"
                                    fullWidth
                                    size="small"
                                    variant="standard"
                                    required
                                    defaultValue={item?.domi_pais}
                                    onChange={(e)=> {
                                        e.target.value.length > 0 ?  
                                        setErrorPais(false) :
                                        setErrorPais(true)
                                    }}  
                                    error={errorPais}
                                    helperText={errorPais ? 'Ingrese un valor' : ''}
                                    sx={{color: theme.palette.primary.input}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{p:2}} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="tipo"
                                    name="tipo"
                                    variant="standard"
                                    defaultValue={item?.domi_tipo}
                                    label="Tipo"
                                    size="small"
                                >
                                    <MenuItem value={1}>Postal</MenuItem>
                                    <MenuItem value={2}>Fiscal</MenuItem>
                                    <MenuItem value={3}>Envio</MenuItem>
                                    <MenuItem value={4}>Comercial</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{p:2}} >
                            <TextField
                                id="nombre"
                                label="Nombre"
                                fullWidth
                                size="small"
                                variant="standard"
                                defaultValue={item?.domi_nombre}
                                sx={{color: theme.palette.primary.input}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{p:2}} >
                            <TextField
                                id="descripcion"
                                label="Descripción"
                                fullWidth
                                size="small"
                                variant="standard"
                                defaultValue={item?.domi_descripcion}
                                sx={{color: theme.palette.primary.input}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} lg={1} xl={1} sx={{p:2, pr:5}} >
                            <Button 
                            variant="contained" 
                            className="btn_system"
                            onClick={item === null ?
                                        onCreate :
                                        onUpdate
                            }   
                            >
                               {item === null ?
                                        <Save /> :
                                        <Edit />
                                }   
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} lg={1} xl={1} sx={{p:2, pl:5}} >
                            <Button variant="contained" 
                            className="btn_system" 
                            onClick={onClose}
                            >
                                <Cancel/>
                            </Button>
                        </Grid>
                    </Grid>
        </form>
    )
}

export default FormDomicilio;