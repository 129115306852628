import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { Backdrop, Box, Button, Divider, Fade, FormControl, Grid, IconButton, MenuItem, Modal, Select, Skeleton, TextField } from "@mui/material";
import Elevation from "../../../components/Elevation";
import { createUseStyles } from "react-jss";
import { Save } from "@mui/icons-material";
import { BiBuildings } from "react-icons/bi";
import { Spin, Typography } from "antd";
import { ThemeContext } from "@emotion/react";
import { useEmpresasMutation, useGetPdfConstanciaEmpresaInfo } from "../../../hooks/empresas";
import swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { PaperClipOutlined } from "@ant-design/icons";
import { useModal } from "../../../hooks/use/useModal";
import VisorConstanciaFiscal from "./components/VisorConstanciaFiscal";
import FileDropper from "../../../components/FileDropper";

const useStyles = createUseStyles({
    generalesSection: {
        display: "flex",
        flexDirection: "column",
        padding: "10px 20px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "auto",
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
            },
        },
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1)",
        },
        "& #empresa-vector": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            fontSize: 32,
            width: 60,
            height: 60,
            borderRadius: "50%",
            backgroundColor: "#575E3B",
            color: "#fff",
            marginBottom: 5,
        },
        "& .ant-divider-horizontal": {
            margin: "10px 0",
        },
        "& .data-item": {
            display: "flex",
            "& .data-item-label": {
                display: "flex",
                alignItems: "center",
                flex: 0.75,
                color: "#575E3B",
                fontWeight: 500,
            },
            "& .data-item-value": {
                display: "flex",
                alignItems: "center",
                fontWeight: 500,
                flex: 1.25,
                "& .ant-row": {
                    margin: 0,
                },
                "& .ant-form-item": {
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                },
            },
        },
    },
    formItem: {
        "& .ant-form-item-row": {
            width: "100%"
        }
    }
});

const NuevoCliente = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center',
        minWidth: 600,
        maxWidth: 1000,
        height: 500
    };

    const dispatch = useDispatch();
    const styles = useStyles();
    const theme = useContext(ThemeContext);
    const formData = useRef(null);
    const [errorRazon, setErrorRazon] = useState(false)
    const [errorRfc, setErrorRfc] = useState(false)
    const [constanciaInfo, setConstanciaInfo] = useState(null);
    const { mutateEmpresa, resetEmpresaMutation } = useEmpresasMutation();
    const { getConstanciaEmpresaInfo, isGettingConstanciaEmpresaInfo } = useGetPdfConstanciaEmpresaInfo();
    const [cargarConstanciaVisible, showCargarConstancia, closeCargarConstancia] = useModal();
    const navigate = useNavigate();

    const validateData = () => {
        if( formData.current.emp_razonsocial.value === '' ||
            formData.current.emp_rfc.value === ''
        ){
            if(formData.current.emp_razonsocial.value === '') setErrorRazon(true);
            if(formData.current.emp_rfc.value === '') setErrorRfc(true);
            return false
        }else{
            return true
        }
    }

    const handleClickGuardarDatos = () => {
        if(validateData()){
            let data = {
                emp_razonsocial : formData.current.emp_razonsocial.value,
                emp_rfc: formData.current.emp_rfc.value,
                emp_nombrecomercial: formData.current.emp_nombrecomercial.value,
                emp_idcif: formData.current.emp_idcif.value,
                emp_alias: formData.current.emp_alias.value,
                emp_codigo: formData.current.emp_codigo.value,
                emp_sector: formData.current.emp_sector.value,
                emp_antiguedad: formData.current.emp_antiguedad.value,
                emp_estatus: 1,
                emp_origen: parseInt(formData.current.emp_origen.value),
            }
            mutateEmpresa(
                {
                    mutationType: "create",
                    ...data,  
                    emp_tipo: 1,
                },
                {
                    onSuccess: (res) => {
                        if (res.success) {
                            resetEmpresaMutation();
                            swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "Empresa creada con éxito",
                                showConfirmButton: false,
                                timer: 1500
                            })
                            navigate(`/inventarios/clientes/${res.data}`);
                        } else {
                            swal.fire({
                                position: "top-end",
                                icon: "error",
                                title: "Hubo un error al crear",
                                text: "Intenta de nuevo",
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    },
                    onError: () => {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Hubo un error al crear",
                            text: "Intenta de nuevo",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    },
                }
            );
        }
    }

    const handleCloseDatosConstancia = () => {
        closeCargarConstancia();
        setConstanciaInfo(null);
    }

    const handleChangeConstancia = (files) => {
        if (files.length > 0) {
            let fd = new FormData();
            fd.append("files[]", files[0], files[0].fileName);
            getConstanciaEmpresaInfo(fd, {
                onSuccess: res => {
                    if (res.success) {
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: res.mensaje,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        setConstanciaInfo(res.data);
                    } else {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Hubo un error, intente nuevamente",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                },
                onError: () => {
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Hubo un error, intente nuevamente",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        }
    }

    const handleSaveDatosConstancia = () => {
        let data = {
            constancia: constanciaInfo
        }
        mutateEmpresa(
            {
                mutationType: "create",
                ...data,
            },
            {
                onSuccess: (res) => {
                    if (res.success) {
                        resetEmpresaMutation();
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Empresa creada con éxito",
                            showConfirmButton: false,
                            timer: 1500
                        })
                        navigate(`/inventarios/clientes/${res.data}`);
                    } else {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Hubo un error al crear",
                            text: "Intenta de nuevo",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                },
                onError: () => {
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Hubo un error al crear",
                        text: "Intenta de nuevo",
                        showConfirmButton: false,
                        timer: 1500
                    })
                },
            }
        );
    }

    useEffect(() => {
        dispatch(
            HEADER_DATA_UPDATE({nombreData: 'Nuevo cliente'})
        )
    }, []);

    return(
        <Grid container spacing={0}>
                <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={cargarConstanciaVisible}
                onClose={handleCloseDatosConstancia}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                >
                    <Fade in={cargarConstanciaVisible}>
                        <Box sx={style}>
                            <div style={{overflowY:'scroll', width:'100%',}}>
                                <Spin spinning={isGettingConstanciaEmpresaInfo} >
                                    {
                                        constanciaInfo && <VisorConstanciaFiscal constanciaInfo={constanciaInfo} onSaveDatosConstancia={handleSaveDatosConstancia} />
                                    }
                                    {
                                        !constanciaInfo &&
                                        <div style={{width:'100%'}}>
                                            <FileDropper
                                                onChange={handleChangeConstancia}
                                                limit={1}
                                                allowedExtensions={["pdf"]}
                                            />
                                        </div>
                                    }
                                </Spin>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{display:'flex', flexDirection:'column', p:2}}>
                <Elevation  >
                    <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                    <Elevation className={styles.generalesSection}>
                        <div className="section-buttons" style={{marginBottom:-20}}>
                                <IconButton 
                                aria-label="save" 
                                variant="contained"
                                onClick={handleClickGuardarDatos}
                                >
                                    <Save />
                                </IconButton>
                        </div>
                        <div id="empresa-vector">
                            <BiBuildings />
                        </div>
                        <form ref={formData} id="form_dom"  style={{width: '100%'}}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Typography.Title level={3} style={{ margin: 0, width: "100%", textAlign: "center" }}>
                                        <TextField
                                            id="emp_razonsocial"
                                            size="small"
                                            variant="standard"
                                            label="Razón social"
                                            required
                                            onChange={(e)=> {
                                                e.target.value.length > 0 ?  
                                                setErrorRazon(false) :
                                                setErrorRazon(true)
                                            }}  
                                            error={errorRazon}
                                            helperText={errorRazon ? 'Ingrese un valor' : ''}
                                            sx={{color: theme.palette.primary.input}}
                                        />
                                </Typography.Title>
                                <Typography.Title level={4} style={{ margin: 0, color: "#575E3B", width: "100%", textAlign: "center" }}>    
                                    <TextField
                                        id="emp_rfc"
                                        size="small"
                                        variant="standard"
                                        label="RFC"
                                        required
                                        onChange={(e)=> {
                                            e.target.value.length > 0 ?  
                                            setErrorRfc(false) :
                                            setErrorRfc(true)
                                        }}  
                                        error={errorRfc}
                                        helperText={errorRfc ? 'Ingrese un valor' : ''}
                                        sx={{color: theme.palette.primary.input, mt:1}}
                                    />
                                </Typography.Title>
                            </div>
                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <Divider  sx={{mt:2}}/>
                                <div className="data-item" style={{marginTop:10}}>
                                    <div className="data-item-label">Nombre Comercial</div>
                                    <div className="data-item-value" style={{paddingLeft:20}}>
                                        <TextField
                                        id="emp_nombrecomercial"
                                        size="small"
                                        variant="standard"
                                        sx={{color: theme.palette.primary.input, mt:1,ml:1}}
                                        />
                                    </div>
                                </div>
                                <Divider sx={{mt:1, mb:1}}/>
                                <div className="data-item" style={{display:'flex', alignItems:'center'}}>
                                    <div className="data-item-label">idCIF</div>
                                    <div className="data-item-value" style={{paddingLeft:20}}>
                                        <TextField
                                        id="emp_idcif"
                                        size="small"
                                        variant="standard"
                                        sx={{color: theme.palette.primary.input, mt:1, ml:1}}
                                        />
                                    </div>
                                </div>
                                <Divider sx={{mt:1, mb:1}}/>
                                <div className="data-item" >
                                    <div className="data-item-label">Alias</div>
                                    <div className="data-item-value" style={{paddingLeft:20}}>
                                        <TextField
                                        id="emp_alias"
                                        size="small"
                                        variant="standard"
                                        sx={{color: theme.palette.primary.input, mt:1, ml:1}}
                                        />
                                    </div>
                                </div>
                                <Divider sx={{mt:1, mb:1}}/>
                                <div className="data-item" >
                                    <div className="data-item-label">Código</div>
                                    <div className="data-item-value" style={{paddingLeft:20}}>
                                        <TextField
                                        id="emp_codigo"
                                        size="small"
                                        variant="standard"
                                        sx={{color: theme.palette.primary.input, mt:1, ml:1}}
                                        />
                                    </div>
                                </div>
                                <Divider sx={{mt:1, mb:1}}/>
                                <div className="data-item" >
                                    <div className="data-item-label">Sector</div>
                                    <div className="data-item-value" style={{paddingLeft:20}}>
                                        <TextField
                                        id="emp_sector"
                                        size="small"
                                        variant="standard"
                                        sx={{color: theme.palette.primary.input, mt:1, ml:1}}
                                        />
                                    </div>
                                </div>
                                <Divider sx={{mt:1, mb:1}}/>
                                <div className="data-item" >
                                    <div className="data-item-label">Antigüedad</div>
                                    <div className="data-item-value" style={{paddingLeft:20}}>
                                        <TextField
                                        id="emp_antiguedad"
                                        size="small"
                                        variant="standard"
                                        sx={{color: theme.palette.primary.input, mt:1, ml:1}}
                                        />
                                    </div>
                                </div>
                                <Divider sx={{mt:1, mb:1}}/>
                                <div className="data-item" >
                                    <div className="data-item-label">Origen</div>
                                    <div className="data-item-value" style={{paddingLeft:20}}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="emp_origen"
                                                name="emp_origen"
                                                size="small"
                                            >
                                                <MenuItem value={1}>Redes sociales</MenuItem>
                                                <MenuItem value={2}>Página web</MenuItem>
                                                <MenuItem value={3}>Campaña publicitaria (mailing)</MenuItem>
                                                <MenuItem value={4}>Referido</MenuItem>
                                                <MenuItem value={5}>Ningúno</MenuItem>
                                                <MenuItem value={6}>Otro</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Elevation>
                    </div>
                </Elevation>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8} sx={{display:'flex', flexDirection:'column', p:2, alignItems:'center'}}>
                <Button
                    variant="contained"
                    style={{ display: "flex", alignItems: "center", height:30, marginBottom:10, width:'100%'}}
                    onClick={showCargarConstancia}
                >
                    <PaperClipOutlined style={{ marginRight:2 }} />
                    Cargar constancia
                </Button>
                <Typography.Title style={{marginBottom:20, marginTop:20, fontSize:20}}>Complete primero los datos de la empresa para acceder a mas funciones</Typography.Title>
                <Skeleton variant="rounded" width={'100%'} height={300} />
            </Grid>
        </Grid>
    )
}

export default NuevoCliente;