import { useDispatch } from "react-redux";
import { IS_LOADING } from "../../redux/reducers/app.reducer";

export const useLoading = () => {
    const dispatch = useDispatch();

    /**
     * @param {boolean} loading
     * @returns {void}
     */
    const setAppLoading = (loading) => {
        dispatch(IS_LOADING(loading));
    };

    return {
        setAppLoading
    };
}