import { Grid, Typography } from "@mui/material"


export const DatosGenerales = ({data}) => {


  return (
    <Grid container spacing={0} sx={{p: 2, border: "1px solid #D9D9D9", borderRadius: 5, height:'100%', display:'flex', alignContent: 'flex-start', gap:2.5}}>
      <Grid item xs={12} sx={{p:0, display:'flex', alignItems:'flex-start', flexDirection:'column'}}>
        <span>Nombres</span>
        <Typography variant="h6" sx={{fontWeight:500}} gutterBottom component="div">
          {data?.givenName}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{p:0, display:'flex', alignItems:'flex-start', flexDirection:'column'}}>
        <span>Apellidos</span>
        <Typography variant="h6" sx={{fontWeight:500}} gutterBottom component="div">
          {data?.familyName}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{p:0, display:'flex', alignItems:'flex-start', flexDirection:'column'}}>
        <span>Username</span>
        <Typography variant="h6" sx={{fontWeight:500}} gutterBottom component="div">
          {data?.username}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{p:0, display:'flex', alignItems:'flex-start', flexDirection:'column'}}>
        <span>Correo electrónico</span>
        <Typography variant="h6" sx={{fontWeight:500}} gutterBottom component="div">
          {data?.email}
        </Typography>
      </Grid>
    </Grid>
  )
}