import useApi from "./useApiRequest";
import { useQuery,  } from "react-query";

export const useApiRegimenesFiscales = (options) => {
    const api = useApi();
    return useQuery(
        ["regimenes_fiscales"],
        async () => {
            const { data } = await api.get(`/regimenes-fiscales`);
            return data;
        },
        options
    );
};
