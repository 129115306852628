import { ThemeContext } from "@emotion/react";
import React, { useContext, useEffect, useState } from "react";
import { GetAllPartesList } from "../../../../../hooks/getPartesList";
import { GetUnidadesMedida } from "../../../../../hooks/getUnidadesMedida";
import { AccionMovInvBackEnum } from "../../../../../@enums/action_mov_inv_back";
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import validateNumber from "../../../../../helpers/validateInt";
import { TipoOrdenEnum } from "../../../../../@enums/tipo_orden_compra";

const DataMovOrd = ({setOpenNew, form, setMovimientos, setMovSend, movimientos, movSend, item, setItem, isUpdate, idClient, tipo}) => {

    const theme = useContext(ThemeContext);
    const {getAllPartesListMutation} = GetAllPartesList();
    const {getUnidadesMedidaMutation} = GetUnidadesMedida();
    const [partes, setPartes] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [parteSelect, setParteSelect] = useState(item !== null ? {label: item?.parte, value: item?.part_id} : null)
    const [unidad, setUnidad] = useState(item !== null ? {unme_nombre: item?.unidad, unme_id: item?.unme_id} : null)
    const [errCant, setErrCant] = useState(false);
    const [errPart, setErrPart] = useState(false);
    const [errPrecio, setErrPrecio] = useState(false);
    const [errUnidad, setErrUnidad] = useState(false);

    const get_partes = () => {
        getAllPartesListMutation({}, {
            onSuccess: (data) => {
                let parts = data.filter(part => part.emp_id === parseInt(idClient));
                setPartes(parts)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    const get_unidades = () => {
        getUnidadesMedidaMutation({}, {
            onSuccess: (data) => {
                setUnidades(data)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        get_partes();
        get_unidades();
    }, [])

    const validateForm = () => {
        setErrPart(false)
        setErrPrecio(false)
        setErrCant(false);
        if((tipo === TipoOrdenEnum.CERRADA_CANTIDAD && form.current.domo_cantidad.value === '') ||
        ( parteSelect === null) || (form.current.domo_importeunitario.value === '') || (form.current.unme_id.value === '')
        ){
            if(form.current.domo_cantidad.value === '' && tipo === TipoOrdenEnum.CERRADA_CANTIDAD) setErrCant(true);
            if(form.current.domo_importeunitario.value === '') setErrPrecio(true)
            if(parteSelect === null)setErrPart(true);
            if(form.current.unme_id.value === '')setErrUnidad(true);
            return false;
        }else{
            return true;
        }
    }

    const resetFormMov = () => {
        setParteSelect(null);
        form.current.domo_cantidad.value = '';
        form.current.domo_importeunitario.value = '';
        form.current.part_id.value = ''
        form.current.unme_id.value = ''
        setItem(null);
    }

    const save = () => {
        if(validateForm()){
            if(item !== null){
                let index = movimientos.findIndex(objeto => objeto.id === item?.id);
                let mov = [...movimientos];
                let mov_send = [...movSend];
                mov[index]  = {
                    id: item?.id,
                    parte: parteSelect?.label,
                    domo_cantidad: form.current.domo_cantidad.value !== '' ? form.current.domo_cantidad.value : 0,
                    part_id: parteSelect?.value,
                    domo_importeunitario: form.current.domo_importeunitario.value,
                    unidad: unidad?.unme_nombre,
                    unme_id: unidad !== null ? unidad?.unme_id : null

                }
                mov_send[index] = {
                    domo_id: item?.id,
                    domo_cantidad: form.current.domo_cantidad.value !== '' ? parseInt(form.current.domo_cantidad.value) : 0,
                    part_id: parseInt(parteSelect?.value),
                    domo_importeunitario: form.current.domo_importeunitario.value !== '' ? parseInt(form.current.domo_importeunitario.value) : null,
                    unme_id: unidad !== null ? unidad?.unme_id : null, 
                    action: mov_send[index]?.action,
                }
                setMovimientos([...mov]);
                setMovSend([...mov_send]);
                setOpenNew(false);
                resetFormMov();
            }else{
                let dataMov = {
                    id: movSend[movSend?.length-1]?.domo_id + 1 || movimientos?.length + 1,
                    parte: parteSelect?.label,
                    domo_cantidad: form.current.domo_cantidad.value !== '' ? form.current.domo_cantidad.value : 0,
                    part_id: parteSelect?.value,
                    domo_importeunitario: form.current.domo_importeunitario.value,
                    unidad: unidad?.unme_nombre,
                    unme_id: unidad !== null ? unidad?.unme_id : null
                }
                let dataSend; 
                if(isUpdate){
                    dataSend = {
                        domo_id: movSend[movSend?.length-1]?.domo_id + 1 || movimientos?.length + 1,
                        domo_cantidad: form.current.domo_cantidad.value !== '' ? parseInt(form.current.domo_cantidad.value) : 0,
                        part_id: parteSelect?.value,
                        domo_importeunitario: form.current.domo_importeunitario.value !== '' ? parseInt(form.current.domo_importeunitario.value) : null,
                        unme_id: unidad !== null ? unidad?.unme_id : null, 
                        action: AccionMovInvBackEnum.CREAR,
                    }
                }else{
                    dataSend = {
                        domo_cantidad: form.current.domo_cantidad.value !== '' ? parseInt(form.current.domo_cantidad.value) : 0,
                        part_id: parteSelect?.value,
                        domo_importeunitario: form.current.domo_importeunitario.value !== '' ? parseInt(form.current.domo_importeunitario.value) : null,
                        unme_id: unidad !== null ? unidad?.unme_id : null
                    }
                }
                setMovimientos([...movimientos, dataMov]);
                setMovSend([...movSend, dataSend]);
                setOpenNew(false);
                resetFormMov();
            }
            resetFormMov();
        }
    }

    const cancelMov = () => {
        setOpenNew(false);
        resetFormMov();
    }

    useEffect(() => {
        if(tipo !== TipoOrdenEnum.CERRADA_CANTIDAD){
            form.current.domo_cantidad.value = 0;
        }
    }, [tipo])

    return(
        <Grid container spacing={0}>
            <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <Typography id="transition-modal-title" variant="h6" component="h2">
                    Datos Movimiento
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <FormControl fullWidth size="small" error={errPart}>
                    <InputLabel id="demo-simple-select-label">Parte</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="part_id"
                        name="part_id"
                        label="Parte"
                        required
                        defaultValue={item?.part_id}
                        onChange={(e)=>{
                            let part = partes.find(item => item.value === parseInt(e.target.value));
                            setParteSelect(part);
                        }}
                    >   
                        {partes?.map(part => (
                            <MenuItem value={part?.value}>{part?.label} / {part?.codigo} / {part?.codigo_salida}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errPart ? 'Seleccione una parte' : ''}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <TextField
                    id="domo_importeunitario"
                    size="small"
                    label="Precio Unitario"
                    fullWidth
                    required
                    defaultValue={item?.domo_importeunitario}
                    onKeyDown={(e)=> validateNumber(e)}
                    error={errPrecio}
                    helperText = {errPrecio ? 'Ingrese un precio' : ''}
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <TextField
                    id="domo_cantidad"
                    size="small"
                    label="Cantidad"
                    fullWidth
                    required
                    defaultValue={item?.domo_cantidad}
                    disabled = {tipo !== TipoOrdenEnum.CERRADA_CANTIDAD}
                    onKeyDown={(e)=> validateNumber(e)}
                    error={errCant}
                    helperText={errCant ? 'Ingrese un valor' : ''}
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <FormControl fullWidth size="small" error={errUnidad}>
                    <InputLabel id="demo-simple-select-um">Unidad de Medida</InputLabel>
                    <Select
                        labelId="demo-simple-select-um"
                        id="unme_id"
                        name="unme_id"
                        label="Unidad de Medida"
                        required
                        defaultValue={item?.unme_id}
                        onChange={(e)=>{
                            let uni = unidades.find(item => item.unme_id === parseInt(e.target.value));
                            setUnidad(uni);
                            setErrUnidad(false)
                        }}
                    >   
                        {unidades?.map(part => (
                            <MenuItem value={part?.unme_id}>{part?.unme_nombre}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errUnidad ? 'Seleccione una unidad' : ''}</FormHelperText>
                </FormControl>
            </Grid>

            <Grid item xs={0} sm={0} md={0} lg={8} xl={8} sx={{p:1, display:'flex', justifyContent:'center'}}>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <Button variant="outlined" className="btn_system" onClick={cancelMov}>
                    Cancelar
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <Button variant="contained" className="btn_system" onClick={save}>
                    Guardar
                </Button>
            </Grid>
        </Grid>
    )
}

export default DataMovOrd;