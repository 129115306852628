import React from "react";
import { Paper } from "@mui/material";
import Carousel from 'react-material-ui-carousel'
import { useSelector } from "react-redux";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";


const CarouselImg = ({recursos}) => {

    const { apiResources } = useSelector((state) => ({
        apiResources: state.app.apiResources,
    }));

    function Item(image)
    {
        return (
            <Paper>
                <img 
                src={`${apiResources}${image?.item.recu_ruta}`} 
                alt={image?.item.recu_nombre} 
                style={{
                    height:120,
                    width:'100%'
                }}/>
            </Paper>
        )
    }


    return (
        <>
            {recursos?.length > 0 ? (
                <Carousel
                NextIcon={<ArrowForwardIos/>}
                PrevIcon={<ArrowBackIos/>}
                sx={{width:'100%', height: 120}}
                >
                    {
                        recursos?.map( (item, i) => <Item key={i} item={item} /> )
                    }
                </Carousel>
            ): (null)}
        </>
    )
}

export default CarouselImg;