import { Input, FormLabel, Button, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CreateItemFormato } from "../../../hooks/createItemFormato";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import CamposDB from "./data/CamposDB.json";
import BarcodeDB from "./data/BarcodeDB.json";
import { GetCamposExtra } from "../../../hooks/getAllCamposExtra";
import { GetAllEmpresas } from "../../../hooks/getAllEmpresas";
export const EtiquetasNuevoItem = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      feit_tipo: 1,
      feit_orientacion: "false",
    },
  });
  const [camposExtra, setCamposExtra] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [camposFiltrados, setCamposFiltrados] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAllCamposExtra } = GetCamposExtra();
  const { createItemFormato } = CreateItemFormato();
  const { getAllEmpresasMutation } = GetAllEmpresas();
  dispatch(HEADER_DATA_UPDATE({ nombreData: "Nuevo Item" }));
  useEffect(() => {
    getAllCamposExtra("2", {
      onSuccess: (data) => {
        setCamposExtra(data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
    getAllEmpresasMutation("", {
      onSuccess: (data) => {
        // Add to first position the option "Todas"
        data.unshift({ emp_id: 0, emp_rfc: "Todas" });
        setEmpresas(data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }, []);
  useEffect(() => {
    const tipo = watch("feit_tipo");
    const empresa = watch("feit_empresa");
    if (tipo == 6 && empresa != 0) {
      setCamposFiltrados(
        camposExtra.filter((campo) => campo.emp_id == empresa)
      );
    }
    if (empresa == 0) {
      setCamposFiltrados(camposExtra);
    }
  }, [watch("feit_tipo"), watch("feit_empresa")]);

  const onSubmit = (data) => {
    if (data.feit_tipo === 7 || data.feit_tipo === 8 || data.feit_tipo === 9) {
      data.feit_valor = data.feit_valor.join(",");
    }
    delete data.feit_empresa;
    data.foet_id = id;
    data.feit_orientacion = data.feit_orientacion === true ? true : false;
    createItemFormato(data, {
      onSuccess: (data) => {
        navigate("/inventarios/etiquetas/" + id);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const InputNuevoItem = ({
    label,
    placeholder,
    inputName,
    type,
    show = true,
  }) => {
    return (
      <div
        style={{
          display: show === true ? "flex" : "none",
          flexDirection: "column",
          width: "50%",
        }}
      >
        <FormLabel>{label}</FormLabel>
        <Input type={type} placeholder={placeholder} {...register(inputName)} />
      </div>
    );
  };

  const SelectNuevoItem = ({
    label,
    inputName,
    options,
    defaultValue = undefined,
    multiple = false,
  }) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
        <FormLabel component={"legend"}>{label}</FormLabel>
        <Select
          defaultValue={defaultValue}
          multiple={multiple}
          {...register(inputName, { required: true })}
        >
          {options.map((option, index) => (
            <MenuItem key={option.value + index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  function renderValorInput(tipo) {
    let options = [];
    switch (tipo) {
      // Texto y Linea
      case 1:
      case 2:
        return (
          <InputNuevoItem
            label="Valor"
            placeholder="Valor"
            type={"text"}
            inputName={"feit_valor"}
            show={tipo !== 2}
          />
        );
      // CamposDB
      case 3:
        options = CamposDB.map((campo) => ({
          value: campo.value,
          label: campo.label,
        }));
        return (
          <SelectNuevoItem
            label="Valor"
            placeholder="Valor"
            inputName={"feit_valor"}
            options={options}
          />
        );
      // Código de barras
      case 5:
        options = BarcodeDB.map((barcode) => ({
          value: barcode.value,
          label: barcode.label,
        }));
        break;
      // Campo Extra
      case 6:
        options = camposFiltrados.map((campo) => ({
          value: campo.came_nombre,
          label: campo.came_nombre,
        }));
        break;
      // Compuesto
      case 7:
      case 8:
      case 9:
        options = CamposDB.map((campo) => ({
          value: campo.value,
          label: campo.label,
        }));
        return (
          <SelectNuevoItem
            label="Valor"
            placeholder="Valor"
            inputName={"feit_valor"}
            options={options}
            multiple={true}
            defaultValue={[]}
          />
        );
      default:
        break;
    }
    if (tipo === 3 || tipo === 5 || tipo === 6) {
      return (
        <SelectNuevoItem
          label="Valor"
          placeholder="Valor"
          inputName={"feit_valor"}
          options={options}
        />
      );
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        {console.log(errors)}
        <InputNuevoItem
          label="Nombre"
          placeholder="Nombre"
          type={"text"}
          inputName={"feit_nombre"}
        />
        <FormLabel component="legend">Tipo</FormLabel>
        <Select
          defaultValue={1}
          {...register("feit_tipo", { required: true })}
          style={{ width: "50%" }}
        >
          <MenuItem value={1}>Texto</MenuItem>
          <MenuItem value={2}>Linea</MenuItem>
          <MenuItem value={3}>Campo DB</MenuItem>
          <MenuItem value={5}>Código de barras</MenuItem>
          <MenuItem value={6}>Campo Extra</MenuItem>
          <MenuItem value={7}>Campo Compuesto</MenuItem>
          <MenuItem value={8}>Código QR</MenuItem>
          <MenuItem value={9}>Código de barras PDF-417</MenuItem>
        </Select>
        <InputNuevoItem
          label="Pos. x"
          placeholder="Pos. x"
          type={"number"}
          inputName={"feit_x"}
        />
        <InputNuevoItem
          label="Pos. y"
          placeholder="Pos. y"
          type={"number"}
          inputName={"feit_y"}
        />
        <InputNuevoItem
          label="Altura"
          placeholder="Altura"
          type={"number"}
          inputName={"feit_h"}
        />
        <InputNuevoItem
          label="Ancho"
          placeholder="Ancho"
          type={"number"}
          inputName={"feit_w"}
        />
        {watch("feit_tipo") === 6 && (
          <>
            <FormLabel component="legend">Empresa</FormLabel>
            <Select
              defaultValue={0}
              {...register("feit_empresa", { required: true })}
              style={{ width: "50%" }}
            >
              {empresas.map((empresa) => (
                <MenuItem key={empresa.emp_id} value={empresa.emp_id}>
                  {empresa.emp_rfc}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        {renderValorInput(watch("feit_tipo"))}
        <FormLabel component="legend">Orientación</FormLabel>
        <Select
          defaultValue={"false"}
          {...register("feit_orientacion", { required: true })}
          style={{ width: "50%" }}
        >
          <MenuItem key={1} value={"false"}>
            Horizontal
          </MenuItem>
          <MenuItem key={2} value={true}>
            Vertical
          </MenuItem>
        </Select>
        <Button type="submit" variant="contained">
          Agregar
        </Button>
      </div>
    </form>
  );
};
