import { Badge, Group, ManageHistory } from "@mui/icons-material";
import { Box, CssBaseline, styled } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import AppBarComponent from "../components/appbar";
import { ProtectedRoutes } from "./private_routes";
import { UsuariosIndex } from "../views/administracion/usuarios/usuariosIndex";
import { RolesIndex } from "../views/administracion/roles/rolesIndex";
import { User } from "../views/administracion/usuarios/components/user";
import { Role } from "../views/administracion/roles/components/rol";
import { TurnosIndex } from "../views/administracion/turnos/turnosIndex";
import { AccessRoutes } from "./access_route";

export const AdministracionRoutes = () => {
  const menu_options = [
    {
      id: 1,
      name: "Usuarios",
      icon: <Group />,
      redirect: "/administracion/usuarios",
    },
    {
      id: 2,
      name: "Roles",
      icon: <Badge />,
      redirect: "/administracion/roles",
    },
    {
      id: 3,
      name: "Turnos",
      icon: <ManageHistory />,
      redirect: "/administracion/turnos",
    },
  ];

  const location = useLocation();
  let path = location?.pathname.split("/").filter((item) => item !== "");

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  return (
    <>
      {path[0] === "administracion" && (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBarComponent
            key={"app_bar_inventario"}
            menuOptions={menu_options}
          />
          <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
            <DrawerHeader />
            <Routes>
              <Route element={<ProtectedRoutes />}>
                <Route
                  key="users_index"
                  path="/administracion/usuarios"
                  element={
                    <AccessRoutes
                      key={"access_users"}
                      permission={"MRP:Administracion:Usuarios:*"}
                    >
                      <UsuariosIndex key={"usuarios_index"} />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="users_one"
                  path="/administracion/usuarios/:username"
                  element={
                    <AccessRoutes
                      key={"access_user_det"}
                      permission={"MRP:Administracion:Usuarios:Detalle"}
                    >
                      <User key={"usuario_data"} />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="roles_index"
                  path="/administracion/roles"
                  element={
                    <AccessRoutes
                      key={"access_roles"}
                      permission={"MRP:Administracion:Roles:*"}
                    >
                      <RolesIndex key={"roles__index"} />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="role_one"
                  path="/administracion/roles/:roleId/:roleName"
                  element={
                    <AccessRoutes
                      key={"access_role_det"}
                      permission={"MRP:Administracion:Roles:Detalle"}
                    >
                      <Role key={"rol_data"} />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="shift_index"
                  path="/administracion/turnos"
                  element={
                    <AccessRoutes
                      key={"access_turnos"}
                      permission={"MRP:Administracion:Turnos:*"}
                    >
                      <TurnosIndex key={"turnos_index"} />
                    </AccessRoutes>
                  }
                />
              </Route>
            </Routes>
          </Box>
        </Box>
      )}
    </>
  );
};
