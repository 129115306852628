import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    nombreData: null,
};

export const HeaderReducer = createSlice({
    name: "header_data",
    initialState,
    reducers: {
        HEADER_DATA_UPDATE: (state, action) => {
            const {nombreData} = action.payload;
            state.nombreData = nombreData;
        },
    }
})

export const {HEADER_DATA_UPDATE} = HeaderReducer.actions;
export default HeaderReducer.reducer