import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetItemFormato = ()  => {
    const api = useApi();

    const mutate = async (param) => {
        const { data } = await api.get(`/formatos-etiquetas-item/${param}`);
        return data;
    };

    const mutation = useMutation(mutate);

    return {
        getFormatoItem: mutation.mutate,
    };
}
