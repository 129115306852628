import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { CrearDocumento } from "../../../hooks/crearDocumento";
import DataDocument from "./components/dataDocument";
import DataMovimiento from "./components/dataMovimiento";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import swal from 'sweetalert2'
import { GetCamposExtraDoc } from "../../../hooks/getCamposExtraDocs";
import { TipoRecursoCameEnum } from "../../../@enums/campo_extra.enum";
import { TipoDocumentoEnum } from "../../../@enums/tipo_documento";
import { EstatusDocumentoEnum } from "../../../@enums/status_documento.enum";
import { useLoading } from "../../../components/Loading/useLoading";

const NuevaEntrada = () => {
    const printerId = useSelector((state) => state.app.printerId);
    const dispatch = useDispatch();
    const {setAppLoading} = useLoading();
    const formData = useRef(null);
    const {crearDocumentoMutation} = CrearDocumento();
    const {getCamposExtraDocMutation} = GetCamposExtraDoc();
    const [openNew, setOpenNew] = useState(false);
    const [movimientos, setMovimientos] = useState([]);
    const [movSend, setMovSend] = useState([]);
    const [itemSelect, setItemSelect] = useState(null);
    const [clientId, setClientId] = useState(null);
    const [errClient, setErrClient] = useState(false);
    const [inputsDoc, setInputsDoc] = useState([]);
    const [inputsMov, setInputsMov] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(
            HEADER_DATA_UPDATE({nombreData: 'Nueva Entrada'})
        )
    }, []);

    useEffect(() => {
        getCamposExtra();
    }, [clientId]);

    const getCamposExtra = () => {
        getCamposExtraDocMutation(clientId, {
            onSuccess: (data) => {
                let inputs_doc = data.map(item => {
                    if( item?.came_extradata !== null && 
                        parseInt(item?.came_extradata?.recurso) === TipoRecursoCameEnum.DOCUMENTO &&
                        parseInt(item?.came_extradata?.tipoDocumento) === TipoDocumentoEnum.ENTRADA
                    ){
                        return {
                            nombre_campo: item?.came_nombre,
                            tipo: item?.came_tipo,
                            respuesta: item?.came_valor
                        }
                    }
                })
                let inputs_mov = data.map(item => {
                    if( item?.came_extradata !== null && 
                        parseInt(item?.came_extradata?.recurso) === TipoRecursoCameEnum.MOVIMIENTO_DOC &&
                        parseInt(item?.came_extradata?.tipoDocumento) === TipoDocumentoEnum.ENTRADA
                    ){
                        return {
                            nombre_campo: item?.came_nombre,
                            tipo: item?.came_tipo,
                            respuesta: item?.came_valor
                        }
                    }
                })
                inputs_doc = inputs_doc.reduce((acc, value) => value !== undefined ? [...acc, value] : acc, []);
                inputs_mov = inputs_mov.reduce((acc, value) => value !== undefined ? [...acc, value] : acc, []);
                setInputsDoc(inputs_doc);
                setInputsMov(inputs_mov);
                openAdd()
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    const onSave = () => {
        setIsSaving(true)
    }

    const saving_data = (array_send) => {
        setAppLoading(true);
        if(clientId !== null){
            let data = {
                printerId,
                dataDocumento: {
                    docu_fecha: formData.current.docu_fecha.value,
                    docu_folio: formData.current.docu_folio.value === '' ? 
                                null : parseInt(formData.current.docu_folio.value),
                    docu_serie: formData.current.docu_serie.value,
                    emp_id: clientId,
                    docu_extradata: JSON.stringify({campos_extra: inputsDoc}),
                    docu_tipo: TipoDocumentoEnum.ENTRADA,
                    docu_estatus: EstatusDocumentoEnum.SIN_ESTATUS
                },
                arrayDocMov: array_send
            }
            crearDocumentoMutation(data,{
                onSuccess: (data) => {
                    if(data.success){
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Entrada creada con éxito",
                            showConfirmButton: false,
                            timer: 1500
                        })
                        setAppLoading(false);
                        navigate(`/inventarios/entradas`);
                    }else{
                        setAppLoading(false);
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Hubo un error al crear",
                            text: "Intenta de nuevo",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }, 
                onError: (error) => {
                    console.log(error)
                    setAppLoading(false);
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Hubo un error al crear",
                        text: "Intenta de nuevo",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        }
        setIsSaving(false)
    }

    const openAdd = () => {
        if(clientId === null){
            setErrClient(true);
        }else{
            setErrClient(false);
            setOpenNew(true);
        }
    }

    return (
        <Grid container spacing={0}>
            <form ref={formData} id="form_dom"  style={{width: '100%'}}>
                <Grid item xs={12} sx={{p:1}}>
                    <DataDocument   
                    key={'data_doc_new'} 
                    onSave={onSave} 
                    data={null} 
                    isUpdate={false} 
                    errClie={errClient} 
                    setClient={setClientId}
                    idClient={clientId}
                    setMovs={setMovimientos}
                    setMovSend={setMovSend}
                    setCloseAdd={setOpenNew}
                    extras={inputsDoc}
                    setExtras={setInputsDoc}/>
                </Grid>
                <Grid item xs={12} sx={{p:1}}>
                    {
                        openNew && (
                            <DataMovimiento 
                            key={'data_mov'} 
                            setOpenNew={setOpenNew} 
                            form={formData}
                            setMovimientos={setMovimientos}
                            setMovSend={setMovSend}
                            movimientos={movimientos}
                            movSend={movSend}
                            item={itemSelect}
                            setItem={setItemSelect}
                            isUpdate={false}
                            idClient={clientId}
                            setIdClie={setClientId}
                            extras={inputsMov}
                            setExtras={setInputsMov}
                            guarda={isSaving}
                            guardarData={saving_data}
                            setSaving={setIsSaving}/>
                        )
                    }
                </Grid>
            </form> 
        </Grid>
    )
}

export default NuevaEntrada;