import { useSelector } from "react-redux";
import useApi from "./useApiRequest";
import { useMutation } from "react-query";


export const Spliteo = ()  => {
    const api = useApi();
    const { givenName } = useSelector((state) => state.auth.user);

    const mutate = async (params) => {
        params.data.usuario = givenName;
        const { data } = await api.put(`/administracion-inventario/split/etiquetas/movimientos/tipo/${params.tipo}`, params.data, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data;
    };

    const mutation = useMutation(mutate);

    return {
        spliteoMutation: mutation.mutate,
    };
}