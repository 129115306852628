import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const CreateFormato = () => {
  const api = useApi();

  const mutate = async (params) => {
    const { data } = await api.post(`/formatos-etiquetas`, params, {
      dataType: "json",
      hasFormData: false,
      hasCustomUrl: false,
    });
    return data;
  };

  const mutation = useMutation(mutate);

  return {
    crearFormato: mutation.mutate,
  };
};
