import React, { useState } from "react";
import { Modal, Fade, Box, Backdrop, Typography, Grid, Alert, Button, ImageList, ImageListItem, ImageListItemBar, IconButton  } from "@mui/material";
import { AddCircle, Delete, Save } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { CrearRecurso } from "../../../../../hooks/createRecursos";
import { BorrarRecurso } from "../../../../../hooks/delRecurso";
import swal from 'sweetalert2'
import { createSelector } from "reselect";


const selectApiResources = (state) => state.app.apiResources;

const selectResources = createSelector(
    [selectApiResources],
    (apiResources) => ({ apiResources })
);

const ModalImagenes = ({open, setOpen, recursos, id, updateData}) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center',
        maxHeight: 500,
        overflowY: 'auto'
    };

    const { apiResources } = useSelector(selectResources);
    const {crearRecursoMutation} = CrearRecurso();
    const {borrarRecursoMutation} = BorrarRecurso();
    const [showAlert, setShowAlert] = useState(false);
    const [images, setImages] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    

    const handleClose = () => setOpen(false);

    const modalConfirm = (recu_id) => {
        swal.fire({
            title: "¿Esta seguro de elimanar este archivo?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar"
            
        }).then((result) => {
            if (result.isConfirmed) {
                borrarRecursoMutation(recu_id,{
                    onSuccess: (data) => {
                        if(data.success){
                            swal.fire({
                                title: "Eliminado",
                                text: "El archivo se elimino correctamente",
                                icon: "success"
                            }).then(()=>{
                                setTimeout(() => {
                                    updateData()
                                }, 1800);
                            });
                        }else{
                            swal.fire({
                                position: "center",
                                icon: "error",
                                title: "Hubo un problema al cargar, intentalo de nuevo",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(()=>{
                                setTimeout(() => {
                                    updateData()
                                }, 1800);
                            });
                        }
                    },
                    onError: (error) => {
                        swal.fire({
                            position: "center",
                            icon: "error",
                            title: "Hubo un problema al borrar, intentalo de nuevo",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{
                            setTimeout(() => {
                                updateData()
                            }, 1800);
                        });
                    }
                })
            }
            
        });
    }
    const deleteLocal = (id) => {
        let imagesArray = images;
        let selected = selectedFiles;
        imagesArray.splice(id, 1);
        selected.splice(id, 1);
        setImages([...imagesArray]);
        setSelectedFiles([...selected])
    }

    const handleFileChange = (event) => {
        event.preventDefault();
        let acceptedFiles = [];
        let newFiles = [...selectedFiles];
        let files = Array.from(event.target.files);
        for (const file of files) {
            if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
                acceptedFiles.push(file);
                newFiles.push(file);
                setShowAlert(false)
            }else{
                setShowAlert(true)
            }
        }
        setSelectedFiles([...newFiles]);
        // Convertir archivos a imágenes y mostrarlas
        // eslint-disable-next-line no-unused-vars, array-callback-return
        acceptedFiles.map((file) => {
            let data = images;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async () => {
              await setImages([...data, reader.result]);
            };
        });
    };
    const getFileName = (file) => {
        return file?.name?.split('.')[0];
    };
    const imageList = images.map((image, index) => (
            <ImageListItem key={`img_${index}`} sx={{height:'100%',   maxHeight:120}} > 
                <img    src={image} 
                        alt="Imagen subida" 
                        width='100%' 
                        height='100%' 
                        style={{borderRadius:5, maxHeight:95}} 
                        loading="lazy"/>
                    <ImageListItemBar
                    title={getFileName(selectedFiles[index])}
                    actionIcon={
                    <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        onClick={()=> deleteLocal(index)}
                    >
                        <Delete />
                    </IconButton>
                    }
                />
            </ImageListItem>
    ));
    const imageListExist = recursos.map((image, index) => (
            <ImageListItem key={`img_${index}`} sx={{height:'100%',   maxHeight:120}} > 
                <img    src={`${apiResources}${image?.recu_ruta}`} 
                        alt="Imagen subida" 
                        width='100%' 
                        height='100%' 
                        style={{borderRadius:5, maxHeight:95}} 
                        loading="lazy"/>
                    <ImageListItemBar
                    title={image?.recu_nombre}
                    actionIcon={
                    <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        onClick={()=>modalConfirm(image?.recu_id)}
                    >
                        <Delete />
                    </IconButton>
                    }
                />
            </ImageListItem>
    ));


    const sendNewFiles = () => {
        if(selectedFiles.length>0){
            let data = {
                part_id: id,
                recu_tipo: 1,
            }
            crearRecursoMutation({extraData: data, files: selectedFiles}, {
                onSuccess: (data) => {
                    handleClose()
                    if(data.success){
                        swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Imagenes cargadas con éxito",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{
                            setTimeout(() => {
                                updateData()
                            }, 1800);
                        });
                    }else{
                        swal.fire({
                            position: "center",
                            icon: "error",
                            title: "Hubo un problema al cargar, intentalo de nuevo",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{
                            setTimeout(() => {
                                updateData();
                            }, 1800);
                        });
                    }
                    setSelectedFiles([])
                    setImages([])
                },
                onError: (error) => {
                    console.log(error);
                    handleClose()
                    swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Hubo un problema al cargar, intentalo de nuevo",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(()=>{
                        setTimeout(() => {
                            updateData();
                        }, 1800);
                    });
                    setSelectedFiles([])
                    setImages([])
                }
            })
        }
    }

    return(
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
            backdrop: {
                timeout: 500,
            },
        }}
        sx={{zIndex:2}}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sx={{p:0, mb:2, pr:1, width:'100%'}} className="center_item">
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Imagenes Parte
                            </Typography>
                            <Button 
                                variant="contained" 
                                sx={{
                                    right:20, 
                                    position:'fixed',
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center',
                                    pt:1
                                }}
                                startIcon={<Save sx={{mt:-0.7}}/>}
                                onClick={sendNewFiles}>
                                    Guardar
                            </Button>
                        </Grid>
                        {showAlert && (
                            <Grid item xs={12} sx={{p:2}} className="center_item">
                                <Alert severity="error">
                                    Solo se admiten archivos JPG, JPEG y PNG.
                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{p:0}} >
                          <div>
                            <Button variant="outlined" component="label" tabIndex={-1} htmlFor="contained-button-file"
                            sx={{
                            display:'flex', 
                            flexDirection:'column',
                            height: 120,
                            width:'90%',
                            }}>
                              <input type="file" id="contained-button-file" accept="image/jpeg,image/png,image/jpg"  style={{ display: 'none' }} onChange={handleFileChange}/>
                              <AddCircle />
                              <Typography>PNG, JPG, JPEG</Typography>      
                            </Button>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{p:0}} className="center_item">
                                <ImageList sx={{ width: '100%'}} cols={3}>
                                    {imageList}
                                    {imageListExist}
                                </ImageList>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}

export default ModalImagenes;