import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const AssignRolUser = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/roles/assign/user`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
      assignRolUserMutation: mutation.mutate,
    };
}

export const RemoveRolUser = ()  => {
  const api = useApi();

  const mutate = async (params) => {
      const data  = await api.del(`/roles/remove/${params.role}/user/${params.user}`, {
          dataType: "json",
          hasFormData: false,
          hasCustomUrl: false,
      });
      return data.data;
  };

  const mutation = useMutation(mutate);

  return {
    removeRolUserMutation: mutation.mutate,
  };
}