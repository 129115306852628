import React, { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "@emotion/react";
import { Delete } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Chip,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { VerifyMov } from "../../../../../hooks/verifyMov";
import { ActualizarMovimiento } from "../../../../../hooks/updateMovimiento";
import { EstatusMovimientoEnum } from "../../../../../@enums/status_mov_inv";
import swal from "sweetalert2";
import { useLoading } from "../../../../../components/Loading/useLoading";

const ModalCarga = ({
  open,
  setOpen,
  isCharge,
  almacen,
  getMovimiento,
  getCargados,
}) => {
  const style = {
    position: "absolute",
    top: "35%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 500,
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    display: "flex",
    justifyContent: "center",
  };
  const formData = useRef(null);
  const inputRef = useRef(null);
  const {setAppLoading} = useLoading();
  const theme = useContext(ThemeContext);
  const { verifyMovMutation } = VerifyMov();
  const { actualizarMovimientoMutation } = ActualizarMovimiento();
  const [paquetes, setPaquetes] = useState([]);

  useEffect(() => {
    if (open) {
        setTimeout(() => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
        }, 200); // Esperar un corto tiempo para asegurarse de que el modal esté completamente renderizado
    }
  }, [open]);


  const handleClose = () => {
    setOpen(false);
    setPaquetes([]);
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      setAppLoading(true)
      event.preventDefault();
      if (paquetes.some((item) => item.tag === event.target.value)) {
        setAppLoading(false)
        swal.fire({
          position: "center",
          icon: "warning",
          title: "El contenedor ya ha sido agregado",
          width: 600,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            container: "cont_alt",
          },
        });
      } else {
        let body = {
          moin_estatus: isCharge ? EstatusMovimientoEnum.ACTIVO : EstatusMovimientoEnum.CARGADO,
          alma_entrada: almacen,
          movimientos_inventario_etiquetas: {
            etiquetas: {
              etiq_estatus:true,
              etiq_codigo: event.target.value,
              etiq_padreid: null,
              etiq_ismaster: false
            }
          }
        };
        verifyMovMutation({where: body, validate_master: true, where_master: {}}, {
          onSuccess: (data) => {
            setAppLoading(false)
            if (data.success !== false) {
              setPaquetes([
                ...paquetes,
                {
                  ...data.data,
                  data: {
                    moin_estatus: isCharge
                      ? EstatusMovimientoEnum.CARGADO
                      : EstatusMovimientoEnum.ACTIVO,
                  },
                },
              ]);
              formData.current.etiqueta.value = "";
            } else {
              swal.fire({
                position: "center",
                icon: "error",
                title:
                  "El contenedor no existe o no cumple con las condiciones necesarias",
                width: 600,
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                  container: "cont_alt",
                },
              });
            }
          },
          onError: (error) => {
            console.log(error);
            setAppLoading(false)
            swal.fire({
              position: "center",
              icon: "error",
              title:
                "El contenedor no existe o no cumple con las condiciones necesarias",
              width: 600,
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                container: "cont_alt",
              },
            });
          },
        });
      }
      inputRef.current.focus();
    }
  };

  const deleteItem = (index) => {
    let paq = paquetes;
    paq.splice(index, 1);
    setPaquetes([...paq]);
  }

  const sendUpdate = () => {
    setAppLoading(true)
    actualizarMovimientoMutation(paquetes, {
      onSuccess: (data) => {
        if(data.success){
          getMovimiento(almacen);
          getCargados(almacen);
          handleClose();
        }
        setAppLoading(false)
      },
      onError: (error) => {
        console.log(error);
        setAppLoading(false)
      },
    });
    getMovimiento(almacen);
    getCargados(almacen);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style} className="modal_carga">
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sx={{ p: 0, mb: 1, pr: 1 }}
              className="center_item"
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Modulo de {isCharge ? "Carga" : "Descarga"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              sx={{
                p: 1,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
                flexDirection: "column",
              }}
            >
              <form ref={formData} id="form_dom" style={{ width: "100%" }}>
                <TextField
                  id="etiqueta"
                  size="small"
                  label="Etiqueta"
                  fullWidth
                  inputRef={inputRef}
                  onKeyDown={handleEnterPress}
                  sx={{ color: theme.palette.primary.input, width: "100%" }}
                />
              </form>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              sx={{
                p: 1,
                gap: 2,
                display: "flex",
                alignItems: "flex-start",
                border: "1px solid #D9D9D9",
                borderRadius: 5,
              }}
            >
              <Grid
                container
                spacing={0}
                sx={{
                  p: 1,
                  textAlign: "start",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {paquetes?.map((paq, index) => (
                  <Grid
                    item
                    xs={6}
                    sx={{
                      p: 1,
                      textAlign: "start",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Chip
                      label={
                        <div
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              marginRight: 7,
                              fontSize: 12,
                              fontWeight: 400,
                              color: "black",
                            }}
                          >
                            {paq?.cantidad}
                          </span>
                          <span
                            style={{
                              marginLeft: 7,
                              border: "1px solid #575E3B",
                              borderRadius: 4,
                              fontSize: 11,
                              padding: 3,
                              color: "#575E3B",
                            }}
                          >
                            {paq?.tag}
                          </span>
                        </div>
                      }
                      variant="outlined"
                      deleteIcon={
                        <Delete sx={{ marginLeft: "5px !important", width:18 }} />
                      }
                      onDelete={()=>deleteItem(index)}
                      sx={{
                        height: 40,
                        borderRadius: 2,
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        backgroundColor: "transparent",
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ p: 1, mt: 2 }} className="center_item">
              <Button
                variant="contained"
                className="btn_system"
                sx={{ fontSize: 12, height: 35 }}
                onClick={sendUpdate}
                disableElevation
              >
                {isCharge ? "Cargar" : "Descargar"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalCarga;
