import React, { useContext, useEffect, useRef, useState } from "react";
import { Typography } from "antd";
import { DateTime } from "luxon";
import { BiBuildings } from "react-icons/bi";
import { createUseStyles } from "react-jss";
import { useApiCorporativos, useEmpresasMutation } from "../../../../hooks/empresas";
import Elevation from "../../../../components/Elevation";
import { Button, Divider, FormControl, IconButton, MenuItem, Select, TextField } from "@mui/material";
import { ThemeContext } from "@emotion/react";
import { Edit, Save } from "@mui/icons-material";
import swal from 'sweetalert2'
import { useLoading } from "../../../../components/Loading/useLoading";

const useStyles = createUseStyles({
    generalesSection: {
        display: "flex",
        flexDirection: "column",
        padding: "10px 20px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "auto",
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
            },
        },
        "& .section-buttons": {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            transform: "scale(1)",
        },
        "& #empresa-vector": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            fontSize: 32,
            width: 60,
            height: 60,
            borderRadius: "50%",
            backgroundColor: "#575E3B",
            color: "#fff",
            marginBottom: 5,
        },
        "& .ant-divider-horizontal": {
            margin: "10px 0",
        },
        "& .data-item": {
            display: "flex",
            "& .data-item-label": {
                display: "flex",
                alignItems: "center",
                flex: 0.75,
                color: "#575E3B",
                fontWeight: 500,
            },
            "& .data-item-value": {
                display: "flex",
                alignItems: "center",
                fontWeight: 500,
                flex: 1.25,
                "& .ant-row": {
                    margin: 0,
                },
                "& .ant-form-item": {
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                },
            },
        },
    },
    formItem: {
        "& .ant-form-item-row": {
            width: "100%"
        }
    }
});

const EmpresaCard = ({ empresa, refetch }) => {

    const styles = useStyles();
    const theme = useContext(ThemeContext);
    const {setAppLoading} = useLoading();
    const [isEditing, setIsEditing] = useState(false);
    const formData = useRef(null);
    const [empresas, setEmpresas] = useState([]);
    const { data: tEmpresas } = useApiCorporativos();
    const { mutateEmpresa, isLoadingEmpresaMutation, resetEmpresaMutation } = useEmpresasMutation();
    const [errorRazon, setErrorRazon] = useState(false)
    const [errorRfc, setErrorRfc] = useState(false)


    const getTipoOrigen = () => {
        switch (empresa?.emp_origen) {
            case 1:
                return 'Redes sociales';
            case 2:
                return 'Página web';
            case 3:
                return 'Campaña publicitaria (mailing)';
            case 4:
                return 'Referido';
            case 5:
                return 'Ningúno';
            case 6:
                return 'Otro';
            default:
                return "Ningúno";
        }
    };

    const validateData = () => {
        if( formData.current.emp_razonsocial.value === '' ||
            formData.current.emp_rfc.value === ''
        ){
            if(formData.current.emp_razonsocial.value === '') setErrorRazon(true);
            if(formData.current.emp_rfc.value === '') setErrorRfc(true);
            return false
        }else{
            return true
        }
    }

    const handleClickEditar = () => {
        setIsEditing(true);
    };

    const handleClickGuardarDatos = () => {
        if(validateData()){
            setAppLoading(true)
            let data = {
                emp_razonsocial : formData.current.emp_razonsocial.value,
                emp_rfc: formData.current.emp_rfc.value,
                emp_nombrecomercial: formData.current.emp_nombrecomercial.value,
                emp_idcif: formData.current.emp_idcif.value,
                emp_alias: formData.current.emp_alias.value,
                emp_codigo: formData.current.emp_codigo.value,
                emp_sector: formData.current.emp_sector.value,
                emp_antiguedad: formData.current.emp_antiguedad.value,
                emp_estatus: parseInt(formData.current.emp_estatus.value),
                emp_origen: parseInt(formData.current.emp_origen.value),
                emp_identificadorgalnik: formData.current.emp_identificadorgalnik.value,
            }

            mutateEmpresa(
                { ...data, emp_id: empresa?.emp_id, mutationType: "update" },
                {
                    onSuccess: (res) => {
                        if (res?.success) {
                            resetEmpresaMutation();
                            setIsEditing(false);
                            refetch()
                            setAppLoading(false)
                            swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "Datos actualizados con éxito",
                                showConfirmButton: false,
                                timer: 1500
                            })
                        } else {
                            setAppLoading(false)
                            swal.fire({
                                position: "top-end",
                                icon: "error",
                                title: "Hubo un error al actualizar",
                                text: "Intenta de nuevo",
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    },
                    onError: () => {
                        setAppLoading(false)
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Hubo un error al actualizar",
                            text: "Intenta de nuevo",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    },
                }
            );
        }
    };

    useEffect(() => {
        if (tEmpresas?.length > 0)
            setEmpresas(tEmpresas.filter((emp) => emp.emp_id !== empresa?.emp_id));

    }, [tEmpresas, empresa]);

    return (
        <Elevation className={styles.generalesSection}>
            <div className="section-buttons">
                {isEditing ? (
                    <IconButton 
                    aria-label="save" 
                    variant="contained"
                    onClick={handleClickGuardarDatos}
                    >
                        <Save />
                    </IconButton>
                ) : (
                        <IconButton 
                        aria-label="save" 
                        variant="contained"
                        onClick={handleClickEditar}
                        >
                            <Edit />
                        </IconButton>
                )}
            </div>
            <div id="empresa-vector">
                <BiBuildings />
            </div>
            <form ref={formData} id="form_dom"  style={{width: '100%'}}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Typography.Title level={3} style={{ margin: 0, width: "100%", textAlign: "center" }}>
                        {!isEditing ? (
                            empresa?.emp_razonsocial
                        ) : (
                            <TextField
                                id="emp_razonsocial"
                                size="small"
                                variant="standard"
                                required
                                defaultValue={empresa?.emp_razonsocial}
                                onChange={(e)=> {
                                    e.target.value.length > 0 ?  
                                    setErrorRazon(false) :
                                    setErrorRazon(true)
                                }}  
                                error={errorRazon}
                                helperText={errorRazon ? 'Ingrese un valor' : ''}
                                sx={{color: theme.palette.primary.input}}
                            />
                        )}
                    </Typography.Title>
                    <Typography.Title level={4} style={{ margin: 0, color: "#575E3B", width: "100%", textAlign: "center" }}>    
                        {!isEditing ? (
                            empresa?.emp_rfc
                        ) : (
                            <TextField
                                id="emp_rfc"
                                size="small"
                                variant="standard"
                                required
                                defaultValue={empresa?.emp_rfc}
                                onChange={(e)=> {
                                    e.target.value.length > 0 ?  
                                    setErrorRfc(false) :
                                    setErrorRfc(true)
                                }}  
                                error={errorRfc}
                                helperText={errorRfc ? 'Ingrese un valor' : ''}
                                sx={{color: theme.palette.primary.input, mt:1}}
                            />
                        )}
                    </Typography.Title>
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <Divider  sx={{mt:2}}/>
                    <div className="data-item" style={{marginTop:10}}>
                        <div className="data-item-label">Nombre Comercial</div>
                        <div className="data-item-value" style={{paddingLeft:20}}>
                            {!isEditing ? (
                                empresa?.emp_nombrecomercial
                            ) : (
                                <TextField
                                id="emp_nombrecomercial"
                                size="small"
                                variant="standard"
                                defaultValue={empresa?.emp_nombrecomercial}
                                sx={{color: theme.palette.primary.input, mt:1,ml:1}}
                                />
                            )}
                        </div>
                    </div>
                    <Divider sx={{mt:1, mb:1}}/>
                    <div className="data-item" style={{display:'flex', alignItems:'center'}}>
                        <div className="data-item-label">idCIF</div>
                        <div className="data-item-value" style={{paddingLeft:20}}>
                            {!isEditing ? (
                                empresa?.emp_idcif
                            ) : (
                                <TextField
                                id="emp_idcif"
                                size="small"
                                variant="standard"
                                defaultValue={empresa?.emp_idcif}
                                sx={{color: theme.palette.primary.input, mt:1, ml:1}}
                                />
                            )}
                        </div>
                    </div>
                    <Divider sx={{mt:1, mb:1}}/>
                    <div className="data-item" >
                        <div className="data-item-label">Alias</div>
                        <div className="data-item-value" style={{paddingLeft:20}}>
                            {!isEditing ? (
                                empresa?.emp_alias
                            ) : (
                                <TextField
                                id="emp_alias"
                                size="small"
                                variant="standard"
                                defaultValue={empresa?.emp_alias}
                                sx={{color: theme.palette.primary.input, mt:1, ml:1}}
                                />
                            )}
                        </div>
                    </div>
                    <Divider sx={{mt:1, mb:1}}/>
                    <div className="data-item" >
                        <div className="data-item-label">Código</div>
                        <div className="data-item-value" style={{paddingLeft:20}}>
                            {!isEditing ? (
                                empresa?.emp_codigo
                            ) : (
                                <TextField
                                id="emp_codigo"
                                size="small"
                                variant="standard"
                                defaultValue={empresa?.emp_codigo}
                                sx={{color: theme.palette.primary.input, mt:1, ml:1}}
                                />
                            )}
                        </div>
                    </div>
                    <Divider sx={{mt:1, mb:1}}/>
                    <div className="data-item" >
                        <div className="data-item-label">Fecha de registro</div>
                        <div className="data-item-value" style={{paddingLeft:20}}>
                            { empresa?.emp_fecharegistro ? 
                                DateTime.fromISO(empresa.emp_fecharegistro).toFormat("dd/MM/yyyy"):
                                null
                            }
                        </div>
                    </div>
                    <Divider sx={{mt:1, mb:1}}/>
                    <div className="data-item" >
                        <div className="data-item-label">Sector</div>
                        <div className="data-item-value" style={{paddingLeft:20}}>
                            {!isEditing ? (
                                empresa?.emp_sector
                            ) : (
                                <TextField
                                id="emp_sector"
                                size="small"
                                variant="standard"
                                defaultValue={empresa?.emp_sector}
                                sx={{color: theme.palette.primary.input, mt:1, ml:1}}
                                />
                            )}
                        </div>
                    </div>
                    <Divider sx={{mt:1, mb:1}}/>
                    <div className="data-item" >
                        <div className="data-item-label">Antigüedad</div>
                        <div className="data-item-value" style={{paddingLeft:20}}>
                            {!isEditing ? (
                                empresa?.emp_antiguedad
                            ) : (
                                <TextField
                                id="emp_antiguedad"
                                size="small"
                                variant="standard"
                                defaultValue={empresa?.emp_antiguedad}
                                sx={{color: theme.palette.primary.input, mt:1, ml:1}}
                                />
                            )}
                        </div>
                    </div>
                    <Divider sx={{mt:1, mb:1}}/>
                    <div className="data-item" >
                        <div className="data-item-label">Identificador Galnik</div>
                        <div className="data-item-value" style={{paddingLeft:20}}>
                            {!isEditing ? (
                                empresa?.emp_identificadorgalnik
                            ) : (
                                <TextField
                                id="emp_identificadorgalnik"
                                size="small"
                                variant="standard"
                                inputProps={{ maxLength: 30 }}
                                defaultValue={empresa?.emp_identificadorgalnik}
                                sx={{color: theme.palette.primary.input, mt:1, ml:1}}
                                />
                            )}
                        </div>
                    </div>
                    <Divider sx={{mt:1, mb:1}}/>
                    <div className="data-item" >
                        <div className="data-item-label">Estatus</div>
                        <div className="data-item-value" style={{paddingLeft:20}}>
                            {!isEditing ? (
                                empresa?.emp_estatus ? (
                                    "Activo"
                                ) : (
                                    "Inactivo"
                                )
                            ) : (
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="emp_estatus"
                                        name="emp_estatus"
                                        defaultValue={empresa?.emp_estatus}
                                        size="small"
                                    >
                                        <MenuItem value={1}>Activo</MenuItem>
                                        <MenuItem value={0}>Inactivo</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        </div>
                    </div>
                    <Divider sx={{mt:1, mb:1}}/>
                    <div className="data-item" >
                        <div className="data-item-label">Origen</div>
                        <div className="data-item-value" style={{paddingLeft:20}}>
                            {!isEditing ? (
                                getTipoOrigen()
                            ) : (
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="emp_origen"
                                        name="emp_origen"
                                        defaultValue={empresa?.emp_origen}
                                        size="small"
                                    >
                                        <MenuItem value={1}>Redes sociales</MenuItem>
                                        <MenuItem value={2}>Página web</MenuItem>
                                        <MenuItem value={3}>Campaña publicitaria (mailing)</MenuItem>
                                        <MenuItem value={4}>Referido</MenuItem>
                                        <MenuItem value={5}>Ningúno</MenuItem>
                                        <MenuItem value={6}>Otro</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        </div>
                    </div>
                    {isEditing ? (
                        <div style={{display:'flex', justifyContent: "end", marginTop: 16, width:'100%' }}>
                            <Button  variant="contained" onClick={handleClickGuardarDatos}>
                                Guardar
                            </Button>
                        </div>
                    ) : null}
                </div>
            </form>
        </Elevation>
    );

}

export default EmpresaCard;