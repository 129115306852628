import React, { useContext, useRef, useState } from "react";
import { FormControl, Grid, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Edit, Save } from "@mui/icons-material";
import Elevation from "../../../components/Elevation";
import validateEmail from "../../../helpers/validateEmail";
import { UpdateContacto } from "../../../hooks/updateCliente";
import { GeneroPersonaEnum } from "../../../@enums/genero_persona.enum";
import { DateTime } from "luxon";
import { createUseStyles } from "react-jss";
import { ThemeContext } from "@emotion/react";
import swal from 'sweetalert2'

const useStyles = createUseStyles({
    generalesSection: {
        display: "flex",
        flexDirection: "column",
        padding: "10px 20px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "auto",
        "&:hover": {
            "& .section-buttons": {
                transform: "scale(1)",
                opacity: 1,
            },
        },
    },
    dataitem: {
        display: "flex",
        "& .dataitem-label": {
            display: "flex",
            alignItems: "center",
            flex: 0.75,
            color: "#575E3B",
            fontWeight: 500,
        },
        "& .dataitem-value": {
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            flex: 1.25,
        },
    },
});

const CardContacto = ({contacto, refetch}) => {

    const {updateContactoMutation} = UpdateContacto();
    const theme = useContext(ThemeContext);
    const styles = useStyles();
    const [isEditing, setIsEditing] = useState(false);
    const [errName, setErrName] = useState(false);
    const [errPat, setErrPat] = useState(false);
    const [errMat, setErrMat] = useState(false);
    const [errMail, setErrMail] = useState(false);
    const formCont = useRef(null);

    const validateForm = () => {
        if(formCont.current.cont_nombres.value === '' || 
        formCont.current.cont_aparterno.value === '' ||
        formCont.current.cont_amaterno.value === '' ||
        formCont.current.cont_mail.value === '' ||
        !validateEmail(formCont.current.cont_mail.value)
        ){
            if(formCont.current.cont_nombres.value === '')setErrName(true);
            if(formCont.current.cont_aparterno.value === '')setErrPat(true);
            if(formCont.current.cont_amaterno.value === '')setErrMat(true);
            if(formCont.current.cont_mail.value === '')setErrMail(true);

            return false;
        }else{
            return true;
        }
    }

    const handleClickGuardarDatos = () => {
        if(validateForm()){
            let data ={
                cont_nombres: formCont.current.cont_nombres.value,
                cont_aparterno: formCont.current.cont_aparterno.value,
                cont_amaterno: formCont.current.cont_amaterno.value,
                cont_mail: formCont.current.cont_mail.value,
                cont_activo: parseInt(formCont.current.cont_activo.value),
                cont_clave: formCont.current.cont_clave.value,
                cont_genero: parseInt(formCont.current.cont_genero.value),
                cont_departamento: formCont.current.cont_departamento.value,
            }
            updateContactoMutation({id:contacto?.cont_id, data: data},{
                onSuccess: (data) => {
                    swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Contacto actualizado con éxito",
                        showConfirmButton: false,
                        timer: 800
                    })
                    refetch();
                    setIsEditing(false)
                },
                onError: (error) => {
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Fallo al actualizar contacto",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        }
    }

    const handleClickEditar = () => {
        setIsEditing(true);
    }

    return(
        <Elevation style={{ width: "100%", padding: "10px 20px", overflowY: "auto"}}>
            <Grid container spacing={0}>
                <form ref={formCont} id="form_dom"  style={{width: '100%'}}>
                    <Grid item xs={12} sx={{display:'flex', justifyContent:'flex-end', p:0}}>
                        {isEditing ? (
                            <IconButton 
                            aria-label="save" 
                            variant="contained"
                            size="small"
                            onClick={handleClickGuardarDatos}
                            >
                                <Save  sx={{fontSize:16}}/>
                            </IconButton>
                        ) : (
                                <IconButton 
                                aria-label="save" 
                                variant="contained"
                                size="small"
                                onClick={handleClickEditar}
                                >
                                    <Edit sx={{fontSize:16}}/>
                                </IconButton>
                        )}
                    </Grid>
                    <Grid item xs={12} sx={{display:'flex', flexDirection:'column', p:1}}>
                        <Typography style={{ margin: 0, width: "100%", textAlign: "start" }} variant="h6" component="h2">
                                {!isEditing ? (
                                    `${contacto?.cont_nombres?.toUpperCase()} ${contacto?.cont_aparterno?.toUpperCase()} ${contacto?.cont_amaterno?.toUpperCase()}`
                                ) : (
                                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start', gap:10}}>
                                        <TextField
                                        id="cont_nombres"
                                        size="small"
                                        variant="standard"
                                        label="Nombre(s)"
                                        required
                                        defaultValue={contacto?.cont_nombres}
                                        onChange={(e)=> {
                                            e.target.value.length > 0 ?  
                                            setErrName(false) :
                                            setErrName(true)
                                        }}  
                                        error={errName}
                                        helperText={errName ? 'Ingrese un valor' : ''}
                                        sx={{color: theme.palette.primary.input, width:130}}
                                        />
                                        <TextField
                                            id="cont_aparterno"
                                            size="small"
                                            variant="standard"
                                            label="Apellido paterno"
                                            required
                                            defaultValue={contacto?.cont_aparterno}
                                            onChange={(e)=> {
                                                e.target.value.length > 0 ?  
                                                setErrPat(false) :
                                                setErrPat(true)
                                            }}  
                                            error={errPat}
                                            helperText={errPat ? 'Ingrese un valor' : ''}
                                            sx={{color: theme.palette.primary.input, width:130}}
                                        />
                                        <TextField
                                            id="cont_amaterno"
                                            size="small"
                                            variant="standard"
                                            label="Apellido materno"
                                            required
                                            defaultValue={contacto?.cont_amaterno}
                                            onChange={(e)=> {
                                                e.target.value.length > 0 ?  
                                                setErrMat(false) :
                                                setErrMat(true)
                                            }}  
                                            error={errMat}
                                            helperText={errMat ? 'Ingrese un valor' : ''}
                                            sx={{color: theme.palette.primary.input, width:130}}
                                        />
                                    </div>
                                )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{display:'flex', flexDirection:'column', p:1}}>
                        <Typography style={{ margin: 0, width: "100%", textAlign: "start" }} variant="h8" component="h9">
                            Datos generales
                        </Typography>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={12} lg={5} xl={5} sx={{display:'flex', flexDirection:'column', p:1, width: "100%"}}>
                                <div className={styles.dataitem } style={{marginTop:10}}>
                                    <div className="dataitem-label">Correo:</div>
                                    <div className="dataitem-value" style={{paddingLeft:5}}>
                                        {!isEditing ? (
                                            contacto?.cont_mail
                                        ) : (
                                            <TextField
                                            id="cont_mail"
                                            size="small"
                                            variant="standard"
                                            defaultValue={contacto?.cont_mail}
                                            onChange={(e)=> {
                                                e.target.value.length > 0 && validateEmail(e.target.value)?  
                                                setErrMail(false) :
                                                setErrMail(true)
                                            }}  
                                            error={errMail}
                                            helperText={errMail ? 'Ingrese un correo válido' : ''}
                                            sx={{color: theme.palette.primary.input, mt:1,ml:1}}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className={styles.dataitem } style={{marginTop:10}}>
                                    <div className="dataitem-label">Clave:</div>
                                    <div className="dataitem-value" style={{paddingLeft:5}}>
                                        {!isEditing ? (
                                            contacto?.cont_clave
                                        ) : (
                                            <TextField
                                            id="cont_clave"
                                            size="small"
                                            variant="standard"
                                            defaultValue={contacto?.cont_clave}
                                            sx={{color: theme.palette.primary.input, mt:1,ml:1}}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Grid>
                            <Grid itemxs={12} sm={12} md={12} lg={4} xl={4} sx={{display:'flex', flexDirection:'column', p:1, width: "100%"}}>
                                <div className={styles.dataitem } style={{marginTop:10}}>
                                    <div className="dataitem-label">Fecha registro:</div>
                                    <div className="dataitem-value" style={{paddingLeft:5}}>
                                        { contacto?.cont_fecharegistro ? 
                                            DateTime.fromISO(contacto?.cont_fecharegistro).toFormat("dd/MM/yyyy"):
                                            null
                                        }
                                    </div>
                                </div>
                                <div className={styles.dataitem } style={{marginTop:10}}>
                                    <div className="dataitem-label">Departamento:</div>
                                    <div className="dataitem-value" style={{paddingLeft:5}}>
                                        {!isEditing ? (
                                            contacto?.cont_departamento
                                        ) : (
                                            <TextField
                                            id="cont_departamento"
                                            size="small"
                                            variant="standard"
                                            defaultValue={contacto?.cont_departamento}
                                            sx={{color: theme.palette.primary.input, mt:1,ml:1}}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Grid>
                            <Grid itemxs={12} sm={12} md={12} lg={3} xl={3} sx={{display:'flex', flexDirection:'column', p:1, width: "100%"}}>
                                <div className={styles.dataitem } style={{marginTop:10}}>
                                    <div className="dataitem-label">Genero:</div>
                                    <div className="dataitem-value" style={{paddingLeft:5}}>
                                        {!isEditing ? (
                                            <>
                                                {contacto?.cont_genero !== null ? (
                                                    <>
                                                        {contacto?.cont_genero === GeneroPersonaEnum.FEMENINO ? (
                                                            "Femenino"
                                                        ) : (
                                                            "Masculino"
                                                        )}
                                                    </>
                                                ): ''}
                                            </>
                                        ) : (
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="cont_genero"
                                                    name="cont_genero"
                                                    defaultValue={contacto?.cont_genero}
                                                    size="small"
                                                >
                                                    <MenuItem value={1}>Femenino</MenuItem>
                                                    <MenuItem value={2}>Masculino</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    </div>
                                </div>
                                <div className={styles.dataitem } style={{marginTop:10}}>
                                    <div className="dataitem-label">Estatus:</div>
                                    <div className="dataitem-value" style={{paddingLeft:5}}>
                                        {!isEditing ? (
                                            contacto?.cont_activo ? (
                                                "Activo"
                                            ) : (
                                                "Inactivo"
                                            )
                                        ) : (
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="cont_activo"
                                                    name="cont_activo"
                                                    defaultValue={contacto?.cont_activo ? '1' : '0'}
                                                    size="small"
                                                >
                                                    <MenuItem value={1}>Activo</MenuItem>
                                                    <MenuItem value={0}>Inactivo</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Elevation>
    )
}

export default CardContacto;