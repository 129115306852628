export default class CanvasRenderer {
  constructor(canvas) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d");
    // this.ctx.save();
  }

  renderText(text, x, y, font, size) {
    this.ctx.font = `${size}px ${font}`;
    this.ctx.fillText(text, x, y);
  }

  renderLine(x1, y1, x2, y2, width) {
    this.ctx.beginPath();
    this.ctx.moveTo(x1, y1);
    this.ctx.lineTo(x2, y2);
    this.ctx.lineWidth = width;
    this.ctx.stroke();
  }

  renderItem(item, data) {
    if (data.foet_orientation === "R") {
      // this.ctx.save()
      // this.ctx.translate(this.canvas.width, 0);
      // this.ctx.rotate(Math.PI / 2);
    }
    switch (item.feit_tipo) {
      case 1:
      case 3:
      case 6:
      case 7:
        this.renderTextItem(item, data);
        break;
      case 2:
        this.renderLineItem(item);
        break;
      case 5:
        this.renderBarcodeItem(item, data);
        break;
      case 8:
        this.renderQRCodeItem(item, data);
        break;
      default:
        // Handle other cases or do nothing
        break;
    }
  }

  renderTextItem(item, data) {
    // Revisar rotacion de la etiqueta
    const prevRotation = data.foet_orientation === "R";
    let fontSize = item.feit_w < 10 ? 10 : item.feit_w; // Ensure minimum font size
    const text =
      item.feit_tipo === 3 ? `[${item.feit_valor}]` : item.feit_valor;
    this.ctx.font = `${fontSize}px ZPL-font`;

    if (item.feit_orientacion === true) {
      // Renderizar texto rotado en un plano "N"
      this.ctx.save();
      this.ctx.translate(item.feit_x, item.feit_y);
      if (!prevRotation) this.ctx.rotate(Math.PI / 2);
      if (prevRotation) {
        this.ctx.rotate(Math.PI / 2);
        this.ctx.rotate(Math.PI / 2);
      }
      this.ctx.fillText(text, 0, 0);
      this.ctx.restore();
    } else if (prevRotation === true) {
      this.ctx.save();
      this.ctx.rotate(Math.PI / 2.0);
      this.ctx.fillText(text, item.feit_y * 0.9, -item.feit_x - fontSize / 2);
      this.ctx.restore();
    } else if (prevRotation === false) {
      this.ctx.fillText(text, item.feit_x * 0.8, item.feit_y * 0.9);
    }
  }

  renderLineItem(item) {
    const x2 =
      item.feit_orientacion === false ? item.feit_x + item.feit_w : item.feit_x;
    const y2 =
      item.feit_orientacion === true ? item.feit_y + item.feit_h : item.feit_y;
    const width = item.feit_orientacion === false ? item.feit_h : item.feit_w;
    this.renderLine(item.feit_x, item.feit_y, x2, y2, width);
  }

  renderBarcodeItem(item, data) {
    const bc = new Image();
    bc.src = "/etiquetas-canvas/barcode-example.png";
    bc.onload = () => {
      this.ctx.save();
      if (data.foet_orientation === "R") {
        this.ctx.rotate(Math.PI / 2.0);
      }
      this.ctx.drawImage(
        bc,
        item.feit_y,
        -(item.feit_x + 10),
        item.feit_valor.length * 20,
        item.feit_h
      );
      this.ctx.restore();
    };
  }

  renderBarcodePdf(item, data) {
    const bc = new Image();
    bc.src = "/etiquetas-canvas/pdf-17.png";
    bc.onload = () => {
      this.ctx.save();
      if (data.foet_orientation === "R") {
        this.ctx.rotate(Math.PI / 2.0);
      }
      this.ctx.drawImage(
        bc,
        item.feit_y,
        -(item.feit_x + 10),
        item.feit_valor.length * 20,
        item.feit_h
      );
      this.ctx.restore();
    };
  }

  renderQRCodeItem(item, data) {
    const qr = new Image();
    qr.src = "/etiquetas-canvas/qr-example.png";
    qr.onload = () => {
      this.ctx.save();
      if (data.foet_orientation === "R") {
        this.ctx.rotate(Math.PI / 2.0);
      }
      this.ctx.drawImage(
        qr,
        item.feit_y + 1,
        -(item.feit_x + item.feit_w * 50),
        item.feit_w * 50,
        item.feit_w * 50
      );
      this.ctx.restore();
    };
  }
}
