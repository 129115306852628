import React, { useContext, useState } from "react";
import { Grid, TextField} from "@mui/material";
import { HexColorPicker } from "react-colorful";
import { ThemeContext } from "@emotion/react";

const FormDataAlmacen = ({data, color, setColor}) => {

    const theme = useContext(ThemeContext);    
    const [notas, setNotas] = useState(data?.alma_observaciones)


    return(
        <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:1, pl:2, pr:2, alignItems:'flex-start'}} className="center_item">
                <TextField
                        id="notes"
                        label="Notas"
                        fullWidth
                        size="small"
                        multiline
                        rows={4}
                        value={notas}
                        onChange={(e)=> {  
                            setNotas(e.target.value) 
                        }}
                        sx={{color: theme.palette.primary.input}}
                    />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:1, pl:2, pr:2, alignItems:'flex-start'}} className="center_item">
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{p:1, pl:2, pr:2, alignItems:'flex-start'}} className="center_item">
                        <HexColorPicker color={color} onChange={setColor} style={{width:300, height:100}} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}


export default FormDataAlmacen;