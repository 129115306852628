import React, { useContext, useEffect, useState } from "react";
import { Button, Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import HeaderProducts from "./components/header";
import InfoLateral from "./components/lateral_info";
import GeneralesProducto from "./components/generales";
import RutasProducto from "./components/rutas/rutas";
import AyudasVisualesProductos from "./components/ayudas_visuales";
import CertificadosProductos from "./components/certificados";
import ModalUnidad from "./components/modales/modalUnidad";
import ModalContenedor from "./components/modales/modalContenedores";
import ModalRuta from "./components/modales/modalRuta";
import swal from "sweetalert2";
import { GetParte } from "../../../hooks/getParte";
import { useLocation, useNavigate } from "react-router-dom";
import { UpdateParte } from "../../../hooks/updateParte";
import { ThemeContext } from "@emotion/react";
import { useDispatch } from "react-redux";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import ModalCrearEncuesta from "./components/modales/modalCrearEncuesta";
import { useLoading } from "../../../components/Loading/useLoading";

const Productos = () => {
	const theme = useContext(ThemeContext);
	const navigate = useNavigate();
	const {setAppLoading} = useLoading();

	const { getParteMutation } = GetParte();
	const { updateParteMutation } = UpdateParte();
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const [seccionId, setSeccionId] = useState(1);
	const [openUnidad, setOpenUnidad] = useState(false);
	const [openContenedor, setOpenContenedor] = useState(false);
	const [openRutas, setOpenRutas] = useState(false);
	const [openCertificado, setOpenCertificado] = useState(false);
	const [contenedor, setContenedor] = useState([]);
	const [revisiones, setRevisiones] = useState([]);
	const [unidades, setUnidades] = useState([]);
	const [categoriaSelect, setCategoriaSelect] = useState([]);
	const [dataParte, setDataParte] = useState(null);
	let id_parte = pathname.split("/")[3];

	const get_product = () => {
		setAppLoading(true);
		getParteMutation(id_parte, {
			onSuccess: (data) => {
				setDataParte(data);
				dispatch(HEADER_DATA_UPDATE({ nombreData: data.part_nombre }));
				let partes_cat = data?.partes_categorias.map((pc) => {
					return {
						label: pc?.categorias?.cate_nombre,
						value: pc?.cate_id,
					};
				});
				setCategoriaSelect(partes_cat);
				setAppLoading(false);
			},
			onError: (error) => {
				swal
					.fire({
						position: "top-end",
						icon: "error",
						title: "Producto no encontrado",
						showConfirmButton: false,
						timer: 1500,
					})
					.then(() => {
						setTimeout(() => {
							navigate(`/inventarios/partes`);
						}, 1600);
					});
			},
		});
	};

	const btn_seccion = [
		{
			id: 1,
			name: "Generales",
			component: (
				<GeneralesProducto
					key={"generales"}
					revisiones={revisiones}
					setRevisiones={setRevisiones}
					setContenedor={setContenedor}
					setOpen={setOpenContenedor}
					dataPart={dataParte}
					open={openContenedor}
				/>
			),
		},
		{
			id: 2,
			name: "Rutas",
			component: (
				<RutasProducto
					key={"rutas"}
					openModal={setOpenRutas}
					id_parte={id_parte}
					isOpen={openRutas}
				/>
			),
		},
		{
			id: 3,
			name: "Ayudas visuales",
			component: (
				<AyudasVisualesProductos
					key={"ayudasv"}
					files={dataParte?.recursos}
					id_part={id_parte}
					updateData={get_product}
				/>
			),
		},
		{
			id: 4,
			name: "Certificados",
			component: (
				<CertificadosProductos
					key={"certificados"}
					setOpenCrear={setOpenCertificado}
					part_id={id_parte}
					open={openCertificado}
				/>
			),
		},
		{
			id: 5,
			name: "Documentos",
		},
	];

	const changeSection = (id) => {
		setSeccionId(id);
	};

	useEffect(() => {
		get_product();
	}, []);

	const handleSubmit = (event) => {
		event.preventDefault();
		if(event.target?.nombre_producto?.value !== undefined){
			setAppLoading(true);

			//Filtrar revisiones
			revisiones.forEach((objeto) => {
				delete objeto?.pare_id;
			});
			let revisiones_nuevas = revisiones.filter((rev) => rev.nuevo === true);
			revisiones_nuevas.forEach((objeto) => {
				delete objeto?.nuevo;
			});
	
			//Crear data
			let data = {
				part_nombre: event.target?.nombre_producto?.value,
				part_estatus: +event.target?.status?.value,
				part_codigoentrada: event.target?.input_code?.value,
				part_codigosalida: event.target?.output_code?.value,
				part_codigoalterno: event.target?.altern_code?.value,
				part_codigocontpaq: event.target?.contpaq_code?.value,
				part_peso:
					event.target.weigth?.value === "" ? null : +event.target?.weigth?.value,
				part_area:
					event.target.area?.value === "" ? null : +event.target?.area?.value,
				part_observaciones: event.target?.notes?.value,
				part_barcode: event.target?.barcode?.value,
				emp_id: +event.target?.cliente?.value,
				unidad_medida_id: unidades,
				contenedor_id: contenedor,
				revisiones: revisiones_nuevas,
				cat_selected: categoriaSelect,
			};
			console.log('data', data)
			updateParteMutation(
				{ id: id_parte, data: data },
				{
					onSuccess: (data) => {
						if (data) {
							swal
								.fire({
									position: "top-end",
									icon: "success",
									title: "Se completo la actualización",
									showConfirmButton: false,
									timer: 1500,
								})
								.then(() => {
									setTimeout(() => {
										setAppLoading(false);
										navigate(`/inventarios/partes/${id_parte}`);
									}, 1600);
								});
						} else {
							swal
								.fire({
									position: "top-end",
									icon: "error",
									title: "No se pudo actualizar",
									text: "Intente nuevamente",
									showConfirmButton: false,
									timer: 1500,
								})
								.then(() => {
									setTimeout(() => {
										navigate(`/inventarios/partes/${id_parte}`);
									}, 1600);
								});
						}
					},
					onError: (error) => {
						console.log(error);
						swal
							.fire({
								position: "top-end",
								icon: "error",
								title: "No se pudo actualizar",
								text: "Intente nuevamente",
								showConfirmButton: false,
								timer: 1500,
							})
							.then(() => {
								setTimeout(() => {
									setAppLoading(false);
									navigate(`/inventarios/partes/${id_parte}`);
								}, 1600);
							});
					},
				}
			);
		}
	};

	return (
		<>
			{dataParte !== null && (
				<>
					<Box component="form" autoComplete="off" onSubmit={handleSubmit}>
						<HeaderProducts key={"header_part"} data={dataParte}/>
						<Divider />
						<Grid container spacing={0}>
							<Grid
								item
								xs={12}
								sm={12}
								md={3}
								lg={3}
								xl={3}
								sx={{ p: 2 }}
								className="center_item"
							>
								<InfoLateral
									key={"info_lateral"}
									setOpen={setOpenUnidad}
									dataPart={dataParte}
									unidadesValue={unidades}
									setUnidadesValues={setUnidades}
									setOpenUnidad={openUnidad}
									updateData={get_product}
									categoriaSelect={categoriaSelect}
									setCategoriaSelect={setCategoriaSelect}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{ p: 2 }}>
								<Grid container spacing={0}>
									<Grid
										item
										xs={12}
										sx={{ p: 0, borderBottom: "1px solid #C9C9C9" }}
										className="center_item"
									>
										<Grid container spacing={0}>
											{btn_seccion.map((btn) => (
												<Grid
													key={btn.name}
													item
													xs={6}
													sm={6}
													md={2}
													lg={2}
													xl={2}
													sx={{ p: 1 }}
													className="center_item"
												>
													<Button
														variant="contained"
														className="btn_square"
														sx={
															seccionId === btn.id
																? {
																		color: theme.palette.primary.contrastText,
																		bg: theme.palette.primary.main,
																		fontSize: 12,
																  }
																: {
																		color: theme.palette.primary.title,
																		bgcolor: theme.palette.secondary.btn,
																		fontSize: 12,
																  }
														}
														onClick={() => changeSection(btn.id)}
														disableElevation
													>
														{btn.name}
													</Button>
												</Grid>
											))}
										</Grid>
									</Grid>
									<Grid item xs={12} sx={{ p: 1 }} className="center_item">
										{btn_seccion[seccionId - 1]?.component}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
					<ModalUnidad
						key={"modal_unidad"}
						open={openUnidad}
						setOpen={setOpenUnidad}
					/>
					<ModalContenedor
						key={"modal_cont"}
						open={openContenedor}
						setOpen={setOpenContenedor}
					/>
					<ModalCrearEncuesta
						key="modal_crear_encuesta"
						open={openCertificado}
						setOpen={setOpenCertificado}
						id_parte={id_parte}
					/>
					<ModalRuta
						key={"modal_ruta"}
						open={openRutas}
						setOpen={setOpenRutas}
						part_id={id_parte}
						consecutivo={
							dataParte?.rutas_partes.length > 0
								? dataParte?.rutas_partes[dataParte?.rutas_partes.length - 1]
										?.rupr_consecutivo + 1
								: 1
						}
						ruta={dataParte?.ruta[0]?.ruta_id}
						getData={get_product}
					/>
				</>
			)}
		</>
	);
};

export default Productos;
