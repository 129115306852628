import { ThemeContext } from "@emotion/react";
import React, { useContext, useEffect, useState } from "react";
import CamposDB from "../data/CamposDB.json";
import BarcodeDB from "../data/BarcodeDB.json";
import { ActualizarItemFormato } from "../../../../hooks/updateItemFormato";
import { Edit, Delete } from "@mui/icons-material";
import {
  TableCell,
  TableRow,
  TableBody,
  Select,
  TableHead,
  Table,
  Grid,
  Button,
  MenuItem,
  Input,
} from "@mui/material";

import { DeleteItemFormato } from "../../../../hooks/deleteItemFormato";
import { GetCamposExtra } from "../../../../hooks/getAllCamposExtra";
import { useForm } from "react-hook-form";
import { GetAllEmpresas } from "../../../../hooks/getAllEmpresas";

export const TablaItems = ({ data, setData }) => {
  const { actualizarFormatoItem } = ActualizarItemFormato();
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [camposExtra, setCamposExtra] = useState(null);
  const [empresas, setEmpresas] = useState(null);
  const [selectedEmpresa, setSelectedEmpresa] = useState(0);
  const { getAllCamposExtra } = GetCamposExtra();
  const { getAllEmpresasMutation } = GetAllEmpresas();
  const { deleteItemFormato } = DeleteItemFormato();
  const theme = useContext(ThemeContext);
  const { watch, register, handleSubmit, setValue, reset } = useForm();
  useEffect(() => {
    if (selectedRowData) {
      if (watch("feit_tipo") !== selectedRowData.feit_tipo) {
        setValue("feit_nombre", selectedRowData?.feit_nombre);
      }
    }
  }, [selectedRowData]);
  useEffect(() => {
    getAllCamposExtra("", {
      onSuccess: (data) => {
        setCamposExtra(data);
      },
      onError: (error) => {
        console.log("Error al cargar campos extra", error);
      },
    });
    getAllEmpresasMutation("", {
      onSuccess: (data) => {
        data.unshift({ emp_id: 0, emp_rfc: "Todas" });
        setEmpresas(data);
      },
      onError: (error) => {
        alert("Error al cargar los datos");
      },
    });
  }, []);

  const columns = [
    {
      width: 50,
      label: "Nombre",
      dataKey: "feit_nombre",
    },
    {
      width: 20,
      label: "Tipo",
      dataKey: "feit_tipo",
    },
    {
      width: 20,
      label: "Valor",
      dataKey: "feit_valor",
    },
    {
      width: 20,
      label: "Pos. x",
      dataKey: "feit_x",
    },
    {
      width: 20,
      label: "Pos. y",
      dataKey: "feit_y",
    },
    {
      width: 20,
      label: "Altura",
      dataKey: "feit_h",
    },
    {
      width: 20,
      label: "Ancho",
      dataKey: "feit_w",
    },
    {
      width: 20,
      label: "Orientación",
      dataKey: "feit_orientacion",
    },
  ];
  function onSubmit(data) {
    if (data?.feit_tipo == 7 || data?.feit_tipo == 8 || data?.feit_tipo == 9) {
      console.log(typeof data.feit_valor);
      if (typeof data.feit_valor === "object") {
        data.feit_valor = data.feit_valor.join(",");
      }
    }
    if (data.feit_orientacion == "false") {
      data.feit_orientacion = false;
    }
    if (!data.feit_valor) {
      data.feit_valor = "";
    }
    actualizarFormatoItem(data, {
      onSuccess: (data) => {
        setData((prevData) => {
          const prevRows = prevData.items;
          const newRows = prevRows.map((row) => {
            if (row.feit_id === data.feit_id) {
              return data;
            }
            return row;
          });
          const newData = {
            ...prevData,
            items: newRows,
          };
          return newData;
        });
        setSelectedRow(null);
      },
      onError: (error) => {
        console.log("Error al actualizar item", error);
      },
    });
  }

  function renderRowContent(row, column) {
    switch (column.dataKey) {
      case "feit_tipo":
        switch (row.feit_tipo) {
          case 1:
            return "Texto";
          case 2:
            return "Linea";
          case 3:
            return "Campo DB";
          case 4:
            return "Imagen";
          case 5:
            return "Código de barras";
          case 6:
            return "Campo Extra";
          case 7:
            return "Campo compuesto";
          case 8:
            return "Campo QR";
          case 9:
            return "Código de barras PDF-417"
          default:
            return "Tipo inválido";
        }
      case "feit_orientacion":
        switch (row.feit_orientacion) {
          case false:
            return "Horizontal";
          case true:
            return "Vertical";
          default:
            return "Orientación inválida";
        }
      default:
        return (
          <p
            style={{
              textOverflow: "ellipsis",
              maxWidth: 120,
              overflow: "hidden",
            }}
          >
            {row[column.dataKey]}
          </p>
        );
    }
  }
  function renderEditableRowContent(row, column) {
    register("feit_id", { value: row.feit_id });
    if (selectedRow === row.feit_id) {
      switch (column.dataKey) {
        case "feit_tipo":
          return (
            <Select
              {...register("feit_tipo")}
              defaultValue={row[column.dataKey]}
            >
              <MenuItem value={1}>Texto</MenuItem>
              <MenuItem value={2}>Linea</MenuItem>
              <MenuItem value={3}>Campo DB</MenuItem>
              <MenuItem value={5}>Código de barras</MenuItem>
              <MenuItem value={6}>Campo Extra</MenuItem>
              <MenuItem value={7}>Campo Compuesto</MenuItem>
              <MenuItem value={8}>Código QR</MenuItem>
              <MenuItem value={9}>Código de barras PDF-417</MenuItem>
            </Select>
          );
        case "feit_orientacion":
          return (
            <Select
              {...register("feit_orientacion")}
              defaultValue={row[column.dataKey]}
            >
              <MenuItem value={false}>Horizontal</MenuItem>
              <MenuItem value={true}>Vertical</MenuItem>
            </Select>
          );
        case "feit_valor":
          const value = row[column.dataKey];
          const tipo = row["feit_tipo"];
          switch (tipo) {
            case 1:
              return <Input {...register("feit_valor")} defaultValue={value} />;
            case 2:
              return "No aplica";
            case 3:
              return (
                <Select {...register("feit_valor")} defaultValue={value}>
                  {CamposDB.map((campo) => (
                    <MenuItem value={campo.value}>{campo.label}</MenuItem>
                  ))}
                </Select>
              );
            case 5:
              return (
                <Select {...register("feit_valor")} defaultValue={value}>
                  {BarcodeDB.map((barcode) => (
                    <MenuItem value={barcode.value}>{barcode.label}</MenuItem>
                  ))}
                </Select>
              );
            case 6:
              return (
                <>
                  <Select
                    defaultValue={0}
                    onChange={(e) => {
                      setSelectedEmpresa(e.target.value);
                    }}
                  >
                    {empresas.map((empresa) => (
                      <MenuItem value={empresa.emp_id}>
                        {empresa.emp_rfc}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select {...register("feit_valor")} defaultValue={value}>
                    {camposExtra
                      .filter((campo) => {
                        if (selectedEmpresa == 0 || selectedEmpresa == null)
                          return true;
                        return campo.emp_id === selectedEmpresa;
                      })
                      .map((campo) => (
                        <MenuItem value={campo.came_nombre}>
                          {campo.came_nombre}
                        </MenuItem>
                      ))}
                  </Select>
                </>
              );
            case 7:
            case 8:
            case 9:
              return (
                <Select
                  multiple={true}
                  style={{ maxWidth: 120 }}
                  {...register("feit_valor")}
                  defaultValue={value.split(",")}
                >
                  {CamposDB.map((campo) => (
                    <MenuItem value={campo.value}>{campo.label}</MenuItem>
                  ))}
                </Select>
              );
          }
        case "feit_nombre":
          return (
            <Input
              {...register("feit_nombre")}
              defaultValue={row[column.dataKey]}
            />
          );
        case "feit_x":
          return (
            <Input {...register("feit_x")} defaultValue={row[column.dataKey]} />
          );
        case "feit_y":
          return (
            <Input {...register("feit_y")} defaultValue={row[column.dataKey]} />
          );
        case "feit_h":
          return (
            <Input {...register("feit_h")} defaultValue={row[column.dataKey]} />
          );
        case "feit_w":
          return (
            <Input {...register("feit_w")} defaultValue={row[column.dataKey]} />
          );
        default:
          return "INVALIDO";
      }
    }
  }

  function handleEditButton(row) {
    reset();
    setSelectedRow(row.feit_id);
    setSelectedRowData(row);
  }

  function handleDeleteButton(id) {
    deleteItemFormato(id, {
      onSuccess: () => {
        const newRows = data.items.filter((row) => row.feit_id !== id);
        setData((prevData) => {
          const newData = {
            ...prevData,
            items: newRows,
          };
          return newData;
        });
      },
      onError: () => {
        console.log("Error al eliminar item");
      },
    });
  }
  return (
    <Grid
      item
      xs={selectedRow !== null ? 10 : 8}
      style={{ marginLeft: "10px" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 50 }}>Acciones</TableCell>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                style={{
                  width: column.width,
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.items?.map((row, index) => (
            <TableRow
              key={index}
              style={{
                backgroundColor:
                  selectedRow === row.feit_id ? "#f0f0f0" : "white",
              }}
            >
              {selectedRow !== row.feit_id && (
                <TableCell>
                  <Edit
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditButton(row)}
                  />
                  <Delete
                    color="error"
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                    onClick={() => handleDeleteButton(row.feit_id)}
                  />
                </TableCell>
              )}
              {selectedRow === row.feit_id && (
                <TableCell>
                  <Button onClick={() => setSelectedRow(null)}>Cancelar</Button>
                </TableCell>
              )}
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  style={{ maxWidth: 120, textOverflow: "ellipsis" }}
                >
                  {selectedRow !== row.feit_id && renderRowContent(row, column)}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {selectedRow == row.feit_id &&
                      renderEditableRowContent(row, column)}
                  </form>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};
