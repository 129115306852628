import { ThemeContext } from "@emotion/react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Button, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import React, { useContext, useState } from "react";
import { ReporteIndividual } from "../../../../hooks/reportes";


export const TablaReportesNoAlmacen = ({sample, where}) => {

  const theme = useContext(ThemeContext);

  const VerSerial = ({data}) => {
    const seriales = data;

    const Title = () => {

      return (
        <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', flexDirection:'column'}}>
          {seriales.map(item => (
            <span>{item}</span>
          ))}
        </div>
      )
    }

    return(
      <Tooltip title={<Title />} placement="top" arrow>
        <Button variant="text">Ver Seriales</Button>
      </Tooltip>
    )
  }


  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const {reporteIndividualMutation} = ReporteIndividual();
    const [movimientos, setMovimientos] = useState([]);

    const get_movimientos = (part_id) => {
      let body = {
        almacen: null,
        status: where?.status,
        part_id,
        fecha_inicio: where?.fecha_inicio,
        fecha_fin: where?.fecha_fin
      }
      reporteIndividualMutation(body,{
        onSuccess: (data) => {
          if(data?.success){
            setMovimientos(data?.data)
          }
        },
        onError: (error) => {
          console.log(error)
        }
      })
    }

    const open_subtabla = (id_parte) => {
      if(open){
        setOpen(!open);
        setMovimientos([]);
      }else{
        get_movimientos(id_parte)
        setOpen(!open);
      }
    }

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell sx={{p:1}}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => open_subtabla(row?.part_id)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
          >
            {row.codigo}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
          >
            {row.nombre}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
          >
            {row.existencia_total}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
          >
            {row.cliente}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, marginBottom:10 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Desgloce de contenedores
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Serial</TableCell>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Etiqueta</TableCell>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Etiqueta master</TableCell>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Cantidad</TableCell>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Fecha reporteo</TableCell>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Fecha impresión</TableCell>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Usuario impresión</TableCell>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {movimientos?.map((mov) => (
                        <TableRow key={mov?.moin_id}>
                          <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.moin_serial.length === 1 ? mov?.moin_serial[0]: <VerSerial  key={`serial_${mov?.moin_id}`} data={mov?.moin_serial}/>}</TableCell>
                          <TableCell align="center" sx={{pl: 1.8, pr:1.8, pt:1, pb:1}}>{mov?.etiq_codigo}</TableCell>
                          <TableCell align="center" sx={{pl: 1.8, pr:1.8, pt:1, pb:1}}>{mov?.etiq_master}</TableCell>
                          <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.moin_cantidad}</TableCell>
                          <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.moin_fechareportado ? mov?.moin_fechareportado : null}</TableCell>
                          <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.etiq_fechaimpresion ? mov?.etiq_fechaimpresion : null}</TableCell>
                          <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.user}</TableCell>
                          <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.status}</TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }


  return(
    <Paper style={{ height: 350, width: "100%", marginBottom:10 }} sx={{ boxShadow: "none" }}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead
            sx={{
              display: "table-head",
              backgroundColor: theme.palette.primary.main,
              fontWeight: 600,
            }}
          >
            <TableRow>
              <TableCell />
              <TableCell align="center" sx={{ color: "white" }}>
                Código
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Nombre
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Existencia Total
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Cliente
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sample?.data?.map((row) => (
              <Row key={row?.part_id} row={row}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )

}