import useApi from "./useApiRequest";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
export const CrearDocumento = () => {
  const api = useApi();
  const { givenName } = useSelector((state) => state.auth.user);
  const mutate = async (params) => {
    params.usuario = givenName; 
    const { data } = await api.post(
      `/administracion-inventario/crear-orden`,
      params,
      {
        dataType: "json",
        hasFormData: false,
        hasCustomUrl: false,
      }
    );
    return data;
  };

  const mutation = useMutation(mutate);

  return {
    crearDocumentoMutation: mutation.mutate,
  };
};
