import React, { useContext, useEffect, useState } from "react";
import { Button, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { GetMonedasList } from "../../../../../hooks/getMonedas";
import { EstatusDocumentoEnum } from "../../../../../@enums/status_documento.enum";
import validateNumber from "../../../../../helpers/validateInt";
import { ThemeContext } from "@emotion/react";
import swal from 'sweetalert2'
import moment from "moment";
import { Close } from "@mui/icons-material";
import { TipoOrdenEnum } from "../../../../../@enums/tipo_orden_compra";

const DataOrden = ({saveOrden, data, changeStatus, isUpdate, onCancel, onClose, errMoneda, errTipo, setErrTipo, setTipo, tipo}) => {

    const theme = useContext(ThemeContext);
    const [fecha, setFecha] = useState('');
    const [fechaVig, setFechaVig] = useState('');
    const {getMonedasListMutation} = GetMonedasList();
    const [monedas, setMonedas] = useState([]);
    const [folioCliente, setFolioCliente] = useState(null);
    const [errFolioCliente, setErrFolioCliente] = useState(false);

    useEffect(() => {
        if(data !== null){
            let date = moment(data?.docu_fecha).format('yyyy-MM-DD')
            setFecha(date)
            if(data?.docu_fechavencimiento)setFechaVig(moment(data?.docu_fechavencimiento).format('yyyy-MM-DD'))
        }else{
            let date = moment().format('yyyy-MM-DD')
            setFecha(date)
        }
    }, [data]);

    const get_monedas = () => {
        getMonedasListMutation({},{
            onSuccess: (data) => {
                setMonedas(data);
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        get_monedas();
    }, []);

    const go_to_save = () => {
        if(folioCliente === null){
            setErrFolioCliente(true);
        }else{
            setErrFolioCliente(false);
            saveOrden();
        }
    }



    return(
        <Grid container spacing={0}>
            <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <div style={{display: 'flex', flexDirection:'row', alignItems:'center'}}>
                    <IconButton
                        aria-label="open drawer"
                        onClick={onClose}
                        edge="start"
                        sx={{
                            color:'#5c5c5c'
                        }}
                    >
                        <Close />
                    </IconButton>
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        Datos Documento
                    </Typography>
                </div>
                {
                    (data?.docu_estatus === EstatusDocumentoEnum.CANCELADO) ?
                    (
                        <Typography id="transition-modal-title" variant="h6" component="h2" sx={{mr:2}}>
                            Orden Cancelada
                        </Typography>
                    ):
                    (data?.docu_estatus === EstatusDocumentoEnum.CERRADO) ? 
                    (
                        <Typography id="transition-modal-title" variant="h6" component="h2" sx={{mr:2}}>
                            Orden Cerrada
                        </Typography>
                    ):
                    (
                        <div>
                            {
                                isUpdate && 
                                <>
                                    <Button variant="contained" className="btn_system" onClick={()=>changeStatus(EstatusDocumentoEnum.CANCELADO)} sx={{width:100, mr:1}}>
                                        Cancelar
                                    </Button>
                                    <Button variant="contained" className="btn_system" onClick={()=>changeStatus(EstatusDocumentoEnum.CERRADO)} sx={{width:100, mr:1}}>
                                        Cerrar
                                    </Button>
                                </>
                            }
                            <Button variant="contained" className="btn_system" onClick={go_to_save} sx={{width:120, }}>
                                Guardar
                            </Button>
                        </div>
                    )
                }
                
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <TextField
                    id="docu_folio"
                    size="small"
                    label="Folio"
                    fullWidth
                    disabled={isUpdate}
                    defaultValue={
                        data !== null ?
                        data?.docu_folio : null
                    }
                    onKeyDown={(e)=> validateNumber(e)}
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <TextField
                    id="referencia"
                    size="small"
                    label="Folio cliente"
                    fullWidth
                    disabled={isUpdate}
                    defaultValue={
                        data !== null ?
                        data?.referencia : null
                    }
                    onChange={(e)=> {
                        if(e.target.value === ''){
                            setFolioCliente(null)
                        }else{
                            setFolioCliente(e.target.value)
                        }
                    }}
                    required
                    error={errFolioCliente}
                    sx={{color: theme.palette.primary.input}}
                    helperText={errFolioCliente ? 'Ingrese un valor' : ''}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <FormControl fullWidth size="small" error={errMoneda}>
                    <InputLabel id="demo-simple-select-stat">Moneda</InputLabel>
                    <Select
                        labelId="demo-simple-select-stat"
                        id="mone_id"
                        name="mone_id"
                        label="Moneda"
                        required
                        defaultValue={data?.mone_id}
                        disabled={isUpdate}
                    >   
                        
                        {monedas.map(item => (
                            <MenuItem value={item?.mone_id}>{`${item?.mone_nombre.toUpperCase()} (${item?.mone_codigo})`}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errMoneda ? 'Seleccione una moneda' : ''}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <TextField
                    id="credito"
                    size="small"
                    label="Tipo Crédito"
                    fullWidth
                    disabled={isUpdate}
                    defaultValue={
                        data !== null ?
                        data?.credito : null
                    }
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <FormControl fullWidth size="small" error={errTipo}>
                    <InputLabel id="demo-simple-select-tipo">Tipo</InputLabel>
                    <Select
                        labelId="demo-simple-select-tipo"
                        id="tipo"
                        name="tipo"
                        label="Tipo"
                        required
                        defaultValue={data?.tipo}
                        disabled={isUpdate}
                        onChange={(e)=>{
                            setTipo(parseInt(e.target.value))
                            setErrTipo(false);
                            if(e.target.value === 0){
                                setFechaVig('')
                            }
                        }}
                    >   
                        
                        <MenuItem value={0}>Abierta</MenuItem>
                        <MenuItem value={1}>Cerrada (Vigencia)</MenuItem>
                        <MenuItem value={2}>Cerrada (Cantidad)</MenuItem>
                    </Select>
                    <FormHelperText>{errTipo ? 'Seleccione una moneda' : ''}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <TextField
                    id='docu_fecha'
                    type="date"
                    fullWidth
                    size="small"
                    value={fecha}
                    name="docu_fecha"
                    label = "Fecha"
                    disabled={isUpdate}
                    sx={{color: theme.palette.primary.input}}
                    inputProps={{
                        style: {color: fecha ? 'inherit' : 'transparent'}
                    }}
                    onChange={(e)=>setFecha(e.target.value)}
                    className="input-date"
                />
            </Grid>
            {tipo === TipoOrdenEnum.CERRADA_VIGENCIA &&
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                    <TextField
                        id='docu_fechavencimiento'
                        type="date"
                        fullWidth
                        size="small"
                        value={fechaVig}
                        name="docu_fechavencimiento"
                        label = {(isUpdate && fechaVig !== '') || (!isUpdate) ? "Fecha Vigencia" : ''}
                        disabled={isUpdate}
                        sx={{color: theme.palette.primary.input}}
                        inputProps={{
                            style: {color: fechaVig !== '' ? 'inherit' : 'transparent'}
                        }}
                        error = {tipo === 1 && fechaVig === '' ? true : false }
                        helperText = {tipo === 1 && fechaVig === '' ? 'Ingrese una vigencia' : '' }
                        onChange={(e)=>setFechaVig(e.target.value)}
                        className="input-date"
                    />
                </Grid>
            }

        </Grid>
    )
}

export default DataOrden;