import React, { useContext, useState } from "react";
import { Modal, Fade, Box, Backdrop, Typography, Grid, TextField, Button} from "@mui/material";
import { CrearContenedor } from "../../../../../hooks/crearContenedor";
import { ThemeContext } from "@emotion/react";
import swal from 'sweetalert2'


const ModalContenedor = ({open, setOpen}) => {

    const style = {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center'
    };

    const theme = useContext(ThemeContext)

    const {crearContenedorMutation} = CrearContenedor();
    const [errorName, setErrorName] = useState(false);
    const [errorCod, setErrorCod] = useState(false);
    const [errorDes, setErrorDes] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault()
        if(
            event.target.cont_nombre.value !== '' &&
            event.target.cont_volumencarga.value !== '' &&
            event.target.cont_codigo.value !== ''
        ){
            let data = {
                cont_nombre: event.target.cont_nombre.value,
                cont_volumencarga: event.target.cont_volumencarga.value,
                cont_codigo: event.target.cont_codigo.value
            }
            crearContenedorMutation(data, {
                onSuccess: (data) => {
                    handleClose()
                },
                onError: (error) => {
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Fallo al crear contenedor",
                        showConfirmButton: false,
                        timer: 1500
                    })
                    console.log(error)
                }
            })
        }else{
            if( event.target.cont_nombre.value === '') setErrorName(true)
            if( event.target.cont_volumencarga.value === '') setErrorCod(true)
            if( event.target.cont_codigo.value === '') setErrorDes(true)
        }
    };

    const handleClose = () => setOpen(false);




    return(
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
            backdrop: {
                timeout: 500,
            },
        }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <form onSubmit={onSubmit}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sx={{p:0, mb:2, pr:1}} className="center_item">
                                <Typography id="transition-modal-title" variant="h6" component="h2">
                                    Nuevo Contenedor o Rack
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{p:0, mb:3, pr:1}} >
                                <TextField
                                    id="cont_nombre"
                                    label="Nombre"
                                    name="cont_nombre"
                                    size="small"
                                    fullWidth
                                    onChange={(e)=> {
                                        e.target.value.length > 0 ?  
                                        setErrorName(false) :
                                        setErrorName(true)
                                    }}  
                                    error={errorName}
                                    helperText={errorName ? 'Ingrese un valor' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:0, mb:1, pr:1}} >
                                <TextField
                                    id="cont_volumencarga"
                                    label="Volumen de carga"
                                    name="cont_volumencarga"
                                    fullWidth
                                    size="small"
                                    sx={{color: theme.palette.primary.input}}
                                    onChange={(e)=> {
                                        e.target.value.length > 0 ?  
                                        setErrorCod(false) :
                                        setErrorCod(true)
                                    }}  
                                    error={errorCod}
                                    helperText={errorCod ? 'Ingrese un valor' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:0, mb:1, pr:1}} >
                                <TextField
                                    id="cont_codigo"
                                    label="Codigo de contenedor"
                                    name="cont_codigo"
                                    fullWidth
                                    size="small"
                                    sx={{color: theme.palette.primary.input}}
                                    onChange={(e)=> {
                                        e.target.value.length > 0 ?  
                                        setErrorDes(false) :
                                        setErrorDes(true)
                                    }}  
                                    error={errorDes}
                                    helperText={errorDes ? 'Ingrese un valor' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{p:0, mb:1, pr:1, mt:2}} >
                                <Button variant="contained" 
                                        className="btn_system" 
                                        type="submit"
                                >
                                    Crear Contenedor
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Fade>
        </Modal>
    )
}

export default ModalContenedor;