import { ThemeContext } from "@emotion/react";
import React, { useContext, useEffect } from "react";
import { AccionMovInvBackEnum } from "../../../../../@enums/action_mov_inv_back";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { TableVirtuoso } from "react-virtuoso";
import { TipoOrdenEnum } from "../../../../../@enums/tipo_orden_compra";

const TablaMovimientosOrden = ({sample, setMovimientos, setMovSend, movimientos, movSend, openData, isUpdate, isCancel, setTipo, tipo}) => {

    const theme = useContext(ThemeContext);

    const rows = sample;

    const columns = [
        {
            width: 40,
            label: 'Parte',
            dataKey: 'parte',
        },
        {
            width: 50,
            label: 'Precio Unitario',
            dataKey: 'domo_importeunitario',
        },
        {
            width: 20,
            label: 'Cantidad',
            dataKey: 'domo_cantidad',
        },
        {
            width: 40,
            label: 'Unidad de Medida',
            dataKey: 'unidad',
        },
        {
            width: 20,
            label: 'Acciones',
            dataKey: 'acciones',
        },
    ];

    const deleteMov = (id) => {
        let mov_row = movimientos;
        let mov_dat = movSend;
        let index_mov = mov_row.findIndex(item => item.id === parseInt(id));
        let index_mov_send = mov_dat.findIndex(item => item.domo_id === parseInt(id));

        if(index_mov !== null && index_mov !== undefined){
            if(isUpdate){
                if(mov_dat[index_mov_send].action === AccionMovInvBackEnum.ACTUALIZAR){
                    mov_dat[index_mov_send].action = AccionMovInvBackEnum.BORRAR
                }else{
                    mov_dat.splice(index_mov_send, 1)
                }
            }else{
                mov_dat.splice(index_mov, 1)
            }

            mov_row.splice(index_mov, 1)
            
            setMovimientos([...mov_row]);
            setMovSend([...mov_dat])
        }

    }

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    function fixedHeaderContent() {
        return (
            <TableRow>
                {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align="center"
                    style={{ width: column.width }}
                    sx={{
                        display: 'table-cell',
                    backgroundColor: theme.palette.primary.main,
                    color:'white',
                    fontWeight:600
                    }}
                >
                    {column.label}
                </TableCell>
                ))}
            </TableRow>
        );
    }

    function rowContent(_index, row) {
        return (
            <React.Fragment>
                {columns.map((column) => (
                    <TableCell
                    key={column.dataKey}
                    align="center"
                    sx={{
                        display: 'table-cell !important',
                        fontWeight:500,
                        color: theme.palette.primary.input,
                        background: 'white'
                    }}
                    onClick={()=>{
                        if(column.dataKey !== 'acciones' && !isCancel){
                            openData(row['id'])
                        }
                    }}
                    >
                        {column.dataKey === 'acciones' ? (
                            <>
                                <IconButton 
                                variant="contained" 
                                onClick={()=>deleteMov(row['id'])}
                                disabled={isCancel}
                                sx={{mr:1, p:1, width:'100% !important', minWidth:'20px', height:30}}  
                                >
                                    <Delete />
                                </IconButton>
                            </>
                        ): (
                            (
                                <>
                                    {
                                        row[column.dataKey] === null 
                                        ? '' : row[column.dataKey]}
                                </>
                            )
                        )}
                    </TableCell>
                ))}
            </React.Fragment>
        );
    }

    return(
        <Paper style={{ height: 400, width: '100%' }} sx={{boxShadow:'none'}}>
        <TableVirtuoso
            data={rows}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
            style={{
                boxShadow:'none',
                border:'1px solid #f2f3f3'
            }}
        />
    </Paper>
    )
}

export default TablaMovimientosOrden;