import React, { useContext, useEffect, useState } from "react";
import { 
    Table, 
    TableContainer, 
    IconButton, 
    Grid, 
    Button, 
    FormControl, 
    InputLabel, 
    Select,
    MenuItem, 
    OutlinedInput, 
    Box, 
    Chip 
} from "@mui/material";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetSubalmacenes } from "../../../../hooks/getSubalmacenes";
import { CreateSubAlmacenes } from "../../../../hooks/createSubAlmacenes";
import { DeleteSubAlmacen } from "../../../../hooks/deleteSubalmacenes";
import { TableVirtuoso } from "react-virtuoso";
import { ThemeContext } from "@emotion/react";
import swal from 'sweetalert2'

const SubAlmacenes = ({id, subList, getList}) => {

    const theme = useContext(ThemeContext);
    const {getSubalmacenesMutation} = GetSubalmacenes();
    const {createSubAlmacenesMutation} = CreateSubAlmacenes();
    const {deleteSubAlmacenMutation} = DeleteSubAlmacen();
    const [rows, setRows] = useState([])
    const [subs, setSubs] = useState([])
    const columns = [
        {
            width: 50,
            label: 'Nombre',
            dataKey: 'alma_nombre',
        },
        {
            width: 50,
            label: 'Tipo',
            dataKey: 'alma_tipo',
        },
        {
            width: 30,
            label: 'Estatus',
            dataKey: 'alma_estatus',
        },
        {
            width: 10,
            label: 'Acciones',
            dataKey: 'acciones',
        },
    ];
    const tipos_almacen = [
        {
            id: 1,
            label: "Almacen distribuido"
        },
        {
            id: 2,
            label: " Centro de Fulfillment"
        },
        {
            id: 3,
            label: "Almacén de Cross-Docking"
        },
        {
            id: 4,
            label: "Almacén de Materias Primas"
        },
        {
            id: 5,
            label: "Almacén de Productos Terminados"
        },
        {
            id: 6,
            label: " Almacén de Refrigeración/Congelación"
        },
        {
            id: 7,
            label: "Centro de Consolidación"
        },
        {
            id: 8,
            label: "Almacén de Tránsito o Aduanas"
        },
        {
            id: 9,
            label: "Almacén de Reserva"
        },
        {
            id: 10,
            label: " Centro de Devolución y Procesamiento de Reclamos"
        },
        {
            id: 11,
            label: "Almacén Automatizado"
        },
        {
            id: 12,
            label: "Almacén de Valor Añadido"
        },
    ]

    const get_subalmacenes = () => {
        getSubalmacenesMutation(id, {
            onSuccess: (data) => {
                setRows(data)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        get_subalmacenes();
    }, []);

    useEffect(() => {
        const intervalData = setInterval(async ()=>{
            await get_subalmacenes();
        }, 5000);
        return () => clearInterval(intervalData);
    }, []);
    
    const onSave = () => {
        let data = subs.map(sub => sub.id);
        createSubAlmacenesMutation({id: id, data: { ids_almacenes: data }},{
            onSuccess: (data) => {
                swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Se agregaron los subalmacenes correctamente",
                    showConfirmButton: false,
                    timer: 1500
                }).then(()=>{
                    get_subalmacenes();
                    getList();
                    setSubs([])
                });
            },
            onError: (error) => {
                swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Hubo un error al actualizar, intente nuevamente",
                    showConfirmButton: false,
                    timer: 1500
                }).then(()=>{
                    get_subalmacenes();
                    getList();
                    setSubs([])
                });
            }
        })
    }

    const onDelete = (id) => {
        swal.fire({
            title: "¿Esta seguro de elimanar este sub-almacen?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteSubAlmacenMutation(id, {
                    onSuccess: (data) => {
                        if(data){
                            swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "Se completo la actualización",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(()=>{
                                setTimeout(() => {
                                    get_subalmacenes();
                                    getList();
                                }, 1600);
                            });
                        }else{
                            swal.fire({
                                position: "top-end",
                                icon: "error",
                                title: "No se pudo actualizar",
                                text: "Intente nuevamente",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(()=>{
                                setTimeout(() => {
                                    get_subalmacenes();
                                    getList();
                                }, 1600);
                            });
                        }
                    },
                    onError: (error) => {
                        console.log(error)
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "No se pudo actualizar",
                            text: "Intente nuevamente",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{
                            setTimeout(() => {
                                get_subalmacenes();
                                getList();
                            }, 1600);
                        });
                    }
                })
            }
        });
    }

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    function fixedHeaderContent() {
        return (
            <TableRow>
                {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align={'center'}
                    style={{ width: column.width }}
                    sx={{
                        display: 'table-cell',
                    backgroundColor: theme.palette.primary.main,
                    color:'white',
                    fontWeight:600
                    }}
                >
                    {column.label}
                </TableCell>
                ))}
            </TableRow>
        );
    }

    function rowContent(_index, row) {
        return (
            <React.Fragment>
                {columns.map((column) => (
                    <TableCell
                    key={column.dataKey}
                    align={'center'}
                    sx={{
                        display: 'table-cell !important',
                        fontWeight:500,
                        color: theme.palette.primary.input,
                        background: 'white'
                    }}
                    >
                        {column.dataKey === 'alma_estatus' ? (
                            <>
                                {(row[column.dataKey] === 1)? (
                                    'Activo'
                                ): ('Inactivo')}
                            </>
                        ):column.dataKey === 'alma_tipo' ? (
                            <>
                                {tipos_almacen
                                .find(alm => alm.id === row[column.dataKey])
                                ?.label}
                            </>
                        ): column.dataKey === 'acciones' ? (
                            <div style={{padding:2}}>
                                <IconButton 
                                variant="contained" 
                                sx={{mr:1, p:1, width:'100% !important', minWidth:'20px', height:30}}  
                                onClick={() => onDelete(row.alma_id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        ):(
                            (
                                <>
                                    {
                                        row[column.dataKey] === null 
                                        ? '' : row[column.dataKey]}
                                </>
                            )
                        )}
                    </TableCell>
                ))}
            </React.Fragment>
        );
    }

    return(
        <Grid container spacing={0}>
            <Grid item xs={10} sx={{p:2, alignItems:'flex-start', pt:3}} className="center_item">
                <FormControl size="small" fullWidth>
                    <InputLabel id="select_sub" style={{position:'absolute'}}>Agregar</InputLabel>
                    <Select
                        labelId="select_sub"
                        id="select_sub_id"
                        name="select_sub_nam"
                        value={subs}
                        label="Agregar"
                        multiple
                        onChange={(e)=> {  
                            setSubs(e.target.value) 
                        }}
                        input={<OutlinedInput id="select-multiple-chip" label="Agregar" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value.id} label={value.label} />
                                ))}
                            </Box>
                        )}
                    >
                        {subList?.map(alm => (
                            <MenuItem value={alm}>{alm.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2} sx={{p:2, alignItems:'flex-start', pt:3}} className="center_item">
                <Button variant="contained" className="btn_system" onClick={onSave}>
                    Guardar
                </Button>
            </Grid>
            <Grid item xs={12} sx={{p:2, alignItems:'flex-start', pt:3}} className="center_item">
                <Paper style={{ height: 400, width: '100%' }} sx={{boxShadow:'none'}}>
                    <TableVirtuoso
                        data={rows}
                        components={VirtuosoTableComponents}
                        fixedHeaderContent={fixedHeaderContent}
                        itemContent={rowContent}
                        style={{
                            boxShadow:'none',
                            border:'1px solid #f2f3f3'
                        }}
                    />
                </Paper>
            </Grid>
        </Grid>
        
    )
}

export default SubAlmacenes;