function array_first_seen(array) {
  const seen = {};
  let data = array

  data?.forEach(obj => {
    if (!seen.hasOwnProperty(obj.part_id)) {
        seen[obj.part_id] = true; // Marcar como vista
        obj.acciones = true; // Establecer acciones en true
    } else {
        obj.acciones = false; // Establecer acciones en false
    }
  });

  return data
}

export default array_first_seen;