export const  TiposAlmacenEnum  = Object.freeze({
  CENTRO_TRABAJO: 1,
  BODEGA: 2,
  PLANTA: 3,
  ALMACEN: 4
})
export const  AreaAlmacenEnum  = Object.freeze({
  PRODUCCION: 1,
  LOGISTICA: 2,
  CALIDAD: 3,
  CUARENTENA: 4,
})