import React, { useContext, useEffect, useState } from "react";
import { Autocomplete, Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { GetAllClientesList } from "../../../../hooks/getListClientes";
import { EstatusDocumentoEnum } from "../../../../@enums/status_documento.enum";
import validateNumber from "../../../../helpers/validateInt";
import { ThemeContext } from "@emotion/react";
import swal from 'sweetalert2'
import moment from "moment";
import { TiposCamposExtraEnum } from "../../../../@enums/tipo_campo_extra";
import { useLoading } from "../../../../components/Loading/useLoading";

const DataDocument = ({onSave, data, isUpdate, onCancel, setClient, errClie, idClient, setMovs, setMovSend, setCloseAdd, extras, setExtras, inUse}) => {

    const theme = useContext(ThemeContext);
    const {setAppLoading} = useLoading();
    const [fecha, setFecha] = useState('');
    const {getAllClientesListMutation} = GetAllClientesList();
    const [clientes, setClientes] = useState([]);
    const [clienteSelect, setClienteSelect] = useState(null)
    useEffect(() => {
        if(data !== null){
            let date = moment(data?.docu_fecha).format('yyyy-MM-DD')
            setFecha(date)
        }else{
            let date = moment().format('yyyy-MM-DD')
            setFecha(date)
        }
    }, [data]);

    const get_clientes = () => {
        getAllClientesListMutation({},{
            onSuccess: (data) => {
                data.sort((a, b) => {
                  return a.label.localeCompare(b.label);
                })
                setClientes(data)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        get_clientes()
    }, []);

    const changeClient = (id) => {

        if(idClient !== null){
            swal.fire({
                title: "¿Esta seguro que desea cambiar de empresa?",
                text: "Perderá todos los movimientos registrados",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, cambiar"
            }).then((result) => {
                if (result.isConfirmed) {
                    setMovs([]);
                    setMovSend([]);
                    setCloseAdd(false);
                    if(id !== null){
                      setClient(parseInt(id?.value));
                      setClienteSelect(id)
                    }else{
                      setClient(null)
                      setClienteSelect(null)
                    }
                }
            });
        }else{
          if(id !== null){
            setAppLoading(true);
            setClient(parseInt(id?.value));
            setClienteSelect(id);
          }else{
            setClient(null);
            setClienteSelect(null)
          }
        }
        
    }

    const answerExtras = (index, value) => {
        let camp_ext = extras;
        camp_ext[index].respuesta = value;
        setExtras([...camp_ext])
    }

    const handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    return(
        <Grid container spacing={0}>
            <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                
                <Typography id="transition-modal-title" variant="h6" component="h2">
                    Datos Documento
                </Typography>
                {
                    (data?.docu_estatus === EstatusDocumentoEnum.CANCELADO) ?
                    (
                        <Typography id="transition-modal-title" variant="h6" component="h2" sx={{mr:2}}>
                            Entrada Cancelada
                        </Typography>
                    ):
                    (
                        <div>
                            {
                                isUpdate && 
                                <Button variant="contained" className="btn_system" disabled={inUse} onClick={onCancel} sx={{width:180, mr:1}}>
                                    Cancelar Entrada
                                </Button>
                            }
                            {!isUpdate && <Button variant="contained" className="btn_system" onClick={onSave} sx={{width:150, ml:1}}>
                                Guardar
                            </Button>}
                        </div>
                    )
                }
                
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <FormControl fullWidth size="small" error={errClie}>
                    {isUpdate ? 
                    (
                      <>
                        <Select
                            labelId="demo-simple-select-stat"
                            id="emp_id"
                            name="emp_id"
                            label="Cliente"
                            required
                            defaultValue={data?.emp_id}
                            disabled={isUpdate}
                            onChange={(e)=>changeClient(e.target.value)}
                        >   
                            {clientes.map(item => (
                                <MenuItem value={item?.value}>{item?.label}</MenuItem>
                            ))}
                        </Select>
                      </>
                    )
                    :(
                      <>
                        <Autocomplete
                        fullWidth
                        id="emp_id"
                        getOptionLabel={(opcion) => opcion.label}
                        filterSelectedOptions
                        options={clientes}
                        disabled={isUpdate}
                        onKeyDown={handleKeyUp}
                        value={clienteSelect}
                        onChange={(e, newValue)=>{
                            if(newValue === null){
                              changeClient(null)
                            }else{
                              changeClient(newValue)
                            }
                        }}
                        renderOption={(props, option)=> (
                            <Box {...props} key={option?.value}>{option.label}</Box>
                        )}
                        renderInput={(params) => 
                            <TextField {...params} label="Cliente" size="small" fullWidth  required/>
                        }
                        />
                      </>
                    )
                  }
                    <FormHelperText>{errClie ? 'Seleccione un cliente' : ''}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <TextField
                    id='docu_fecha'
                    type="date"
                    fullWidth
                    size="small"
                    value={fecha}
                    name="docu_fecha"
                    hiddenLabel
                    placeholder=""
                    disabled={isUpdate}
                    sx={{color: theme.palette.primary.input}}
                    onChange={(e)=>setFecha(e.target.value)}
                    className="input-date"
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <TextField
                    id="docu_folio"
                    size="small"
                    label="Folio"
                    fullWidth
                    disabled={isUpdate}
                    defaultValue={
                        data !== null ?
                        data?.docu_folio : null
                    }
                    onKeyDown={(e)=> validateNumber(e)}
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <TextField
                    id="docu_serie"
                    size="small"
                    label="Serie"
                    inputProps={{ maxLength: 10 }}
                    disabled={isUpdate}
                    defaultValue={
                        data !== null ? 
                        data?.docu_serie : null
                    }
                    fullWidth
                    sx={{color: theme.palette.primary.input}}
                />
            </Grid>
           

            {
                extras?.length > 0 && 
                <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        Información Adicional
                    </Typography>
                </Grid>
            }
            {extras?.map((pregunta, index) => (
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                    {
                        pregunta?.tipo === TiposCamposExtraEnum.BOLEANO && (
                            <FormControl fullWidth size="small">
                                <InputLabel id={`bool_${pregunta?.nombre_campo}`}>{pregunta?.nombre_campo}</InputLabel>
                                <Select
                                    labelId={`bool_${pregunta?.nombre_campo}`}
                                    id="recurso"
                                    name="recurso"
                                    label={pregunta?.nombre_campo}
                                    defaultValue={pregunta?.respuesta}
                                    disabled={isUpdate}
                                    onChange={(e)=>answerExtras(index, e.target.value)}
                                >
                                    <MenuItem value="Si">Sí</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </Select>
                            </FormControl>
                        )
                    }
                    {
                        pregunta?.tipo === TiposCamposExtraEnum.FLOTANTE && (
                            <TextField
                                id={`float_${pregunta?.nombre_campo}`}
                                label={pregunta?.nombre_campo}
                                size="small"
                                fullWidth
                                defaultValue={
                                    pregunta?.respuesta !== null ? 
                                    parseInt(pregunta?.respuesta) : null
                                }
                                disabled={isUpdate}
                                onKeyDown={(e)=> validateNumber(e)}
                                onKeyUp={(e)=>answerExtras(index, parseInt(e.target.value))}
                                sx={{color: theme.palette.primary.input}}
                            />
                        )
                    }
                    {
                        pregunta?.tipo === TiposCamposExtraEnum.TEXTO && (
                            <TextField
                                id={`float_${pregunta?.nombre_campo}`}
                                label={pregunta?.nombre_campo}
                                defaultValue={pregunta?.respuesta}
                                onChange={(e)=>answerExtras(index, e.target.value)}
                                sx={{color: theme.palette.primary.input}}
                                disabled={isUpdate}
                                size="small"
                                fullWidth
                            />
                        )
                    }
                </Grid> 
            ))}
        </Grid>
    )
}

export default DataDocument;