import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetOrdenes = ()  => {
    const api = useApi();

    const mutate = async (param) => {
        const data  = await api.get(`/documentos/ordenes/${param}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getOrdenesMutation: mutation.mutate,
    };
}

export const GetOrdenesNew = ()  => {
    const api = useApi();

    const mutate = async (param) => {
        const data  = await api.post(`/administracion-inventario`, param, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getOrdenesNewMutation: mutation.mutate,
    };
}