import { useState } from "react"
import { Button, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material"


export const RequestFormTrazabilidad = ({search}) => {

  const [data, setData] = useState(null);
  const [type, setType] = useState(1);


  const handleChange = (event) => {
    setType(parseInt(event.target.value));
  };

  const sumbmitFiltro = () => {
    let body = {
      codigo: data,
      tipo: type
    }
    search(body)
  }

  return(
    <Grid container spacing={0} sx={{mt:2}}>
      <Grid item xs={6} sm={6} md={3} lg={3} xl={3} className="center_item">
        <TextField
          id="search_map"
          label="Ingrese etiqueta o serial"
          name="search_map"
          size="small"
          fullWidth
          value={data}
          onChange={
            (e) => setData(e.target.value)
          }
        />
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={2} xl={2} className="center_item">
      <RadioGroup
        row
        aria-labelledby="serial/tag"
        name="serial/tag"
        value={type}
        onChange={handleChange}
      >
        <FormControlLabel value={1} control={<Radio />} label="Etiqueta" />
        <FormControlLabel value={2} control={<Radio />} label="Serial" />
      </RadioGroup>
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={2} xl={2} className="center_item">
        <Button 
        variant="contained" 
        className="btn_system" 
        onClick={sumbmitFiltro}
      >
        Buscar
      </Button>
      </Grid>
    </Grid>
  )
}