import { useSelector } from "react-redux";
import { PropagateLoader } from "react-spinners";
import theme from "../../helpers/theme";
export const Loading = () => {
	const loading = useSelector((state) => state.app.loading);

	if (!loading) {
		return null;
	}

	return (
		<div
			className="loading"
			style={{
				position: "absolute",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
				height: "100%",
				backgroundColor: "rgba(255, 255, 255, 0.75)",
				zIndex: 9999,
			}}
		>
			<PropagateLoader 
                // style={{position: "absolute", marginLeft: -40}}
                speedMultiplier={1.1}
                size={25}
                color={theme.palette.primary.main} 
            />
			{/* <PropagateLoader 
                style={{position: "absolute", transform: "rotate(90deg)"}}
                speedMultiplier={1.1}
                size={20}
                color={theme.palette.primary.main} 
            /> */}
		</div>
	);
};
