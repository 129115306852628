import { Backdrop, Box, Button, Fade, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Modal, Select, Typography } from "@mui/material"
import { EstatusDocumentoEnum } from "../../../../@enums/status_documento.enum"
import { ArrowBack } from "@mui/icons-material"
import moment from "moment"
import { TablaMovimientos } from "./tabla_movimientos"
import { EnviarOrden } from "../../../../hooks/enviarOrden"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLoading } from "../../../../components/Loading/useLoading"
import { GetDomiciliosByCompany } from "../../../../hooks/getDomicilios"
import { TiposDomicilioEnum } from "../../../../@enums/tipo_domicilio.enum"
import useApi from "../../../../hooks/useApiRequest"
import { useSnackbar } from "notistack"
import { ReabrirSalida } from "../../../../hooks/reabrirSalida"
import { createSelector } from "reselect"

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 500,
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  display: "flex",
  justifyContent: "center",
};

const selectPermission = (state) => state.auth.permissions;
const selectPermissions = createSelector(
  [selectPermission],
  (permissions) => ({ permissions })
);


export const Salida = ({orden, setOrden}) => {
	const { enqueueSnackbar } = useSnackbar();
  const {setAppLoading} = useLoading();
  const {enviarOrdenMutation} = EnviarOrden();
  const {reabrirSalidaMutation} = ReabrirSalida();
  const {getDomiciliosByCompanyMutation} = GetDomiciliosByCompany();
  const [packingListUrl, setPackinListUrl] = useState(null);
  const [shipperUrl, setShipperUrl] = useState(null);
  const [certificatedListUrl, setCertificatedListUrl] = useState([]);
  const apiResources = useSelector((state) => state.app.apiResources);
  const { permissions } = useSelector(selectPermissions);
  const [openRes, setOpenRes] = useState(false);
  const [domicilios, setDomicilios] = useState([]);
  const [domicilio, setDomicilio] = useState(orden?.domi_id);
  const [errDom, setErrDom] = useState(false)
  const api = useApi();

  const enviarOrden = () => {
    if(domicilio === null) {
      setErrDom(true)
    }else{
      setErrDom(false)
      setAppLoading(true)
      enviarOrdenMutation({data: orden?.docu_id, domicilio: domicilio}, {
        onSuccess: (data) => {
          setOrden(null);
          setAppLoading(false)
        },
        onError: (error) => {
          setAppLoading(false)
          console.log(error)
        }
      })
    }
  }

  const get_domicilios = () => {
    getDomiciliosByCompanyMutation(orden.emp_id, {
      onSuccess: (data) => {
        let domicilios_formato = data.map(dom => {
          let tipo =  dom?.domi_tipo === TiposDomicilioEnum.COMERCIAL ? 'Comercial' :
                      dom?.domi_tipo === TiposDomicilioEnum.ENVIO ? 'Envio' :
                      dom?.domi_tipo === TiposDomicilioEnum.FISCAL ? 'Fiscal' : 'Postal';
          return {
            id: dom?.domi_id,
            label: `${tipo} - ${dom?.domi_colonia} ${dom?.domi_cp} ${dom?.domi_municipio}, ${dom?.domi_estado}, ${dom?.domi_pais}`
          }
        })
        setDomicilios(domicilios_formato)
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }

  const reabrirSalida = () => {
    setAppLoading(true);
    reabrirSalidaMutation(orden?.docu_id, {
      onSuccess: (data) => {
        if(data.success){
          enqueueSnackbar("Orden reactivada", {
						autoHideDuration: 2000,
						variant: "success",
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
					});
          setOrden(null);
        }else{
          enqueueSnackbar("Hay una orden activa de la empresa actualmente", {
						autoHideDuration: 2000,
						variant: "error",
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
					});
        }
        setAppLoading(false)
      },
      onError: (error) => {
        setAppLoading(false)
        console.log(error)
      }
    })
  }

  useEffect(() => {
    if(orden?.docu_estatus === EstatusDocumentoEnum.CERRADO && orden?.recursos?.length > 0){
      let packingListResource  = orden?.recursos?.find((rec) => rec.recu_nombre.includes('packing-list'));
      let shipperPackingResource  = orden?.recursos?.find((rec) => rec.recu_nombre.includes('shipper'));
      let certificatedResource  = orden?.recursos?.filter((rec) => rec.recu_nombre.includes('certificate'));
      setPackinListUrl(`${apiResources}${packingListResource?.recu_ruta}`)
      setShipperUrl(`${apiResources}${shipperPackingResource?.recu_ruta}`)
      setCertificatedListUrl(certificatedResource.map((cert) => `${apiResources}${cert?.recu_ruta}`))
    }
    if(orden?.emp_id){
      get_domicilios()
    }
  }, [orden])

  const closeRes = () => {
    setOpenRes(false)
  }
  

  const downloadCertificates = async () => {
    setAppLoading(true)
      try {
        const response = await api.get(`/salidas-inventario/${orden.docu_id}/certificates`, {
          responseType: 'blob', // Indicamos que esperamos un archivo binario (blob)
        }); 
        // Obtener el encabezado Content-Disposition
        const contentDisposition = response.headers['content-disposition'];

        // Extraer el nombre del archivo del encabezado
        const fileName = contentDisposition
          ? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
          : 'certificates.zip'; // Valor por defecto si no se encuentra el nombre del archivo
    
        // Crear un URL para el archivo descargado
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        // Crear un enlace para descargar el archivo
        const link = document.createElement('a');
        link.href = url;
    
        // Asignar un nombre al archivo descargado
        link.setAttribute('download', fileName);
    
        // Añadir el enlace al documento y hacer clic en él para descargar el archivo
        document.body.appendChild(link);
        link.click();
    
        // Limpiar el DOM
        document.body.removeChild(link);
        setAppLoading(false)
        enqueueSnackbar('Certificados descargados', {variant: 'success', preventDuplicate: true, autoHideDuration: 2000, anchorOrigin: { vertical: 'top', horizontal: 'center'}});
      } catch (error) {
        setAppLoading(false)
        console.error('Error descargando el archivo:', error);
        enqueueSnackbar('Error al descargar los certificados', {variant: 'error', preventDuplicate: true, autoHideDuration: 2000, anchorOrigin: { vertical: 'top', horizontal: 'center'}});
      }
  }


  return (
    <Grid container spacing={0} sx={{p:1}} >
      
      <Grid  item xs={6} sx={{p:0, justifyContent: 'flex-start', height:60}} className="center_item">
        <IconButton aria-label="delete" color="primary" onClick={()=>setOrden(null)}>
          <ArrowBack />
        </IconButton>
      </Grid>
      <Grid  item xs={4} sx={{p:0, justifyContent: 'flex-start', height:60}} className="center_item">
        {
          orden?.docu_estatus === EstatusDocumentoEnum.SIN_ESTATUS &&
            <FormControl size="small" fullWidth error={errDom}>
              <InputLabel id="select_padre">Domicilio</InputLabel>
              <Select
                  labelId="select_padre"
                  id="id_domicilio"
                  name="id_domicilio"
                  value={domicilio}
                  label="Domicilio"
                  onChange={(e)=> {  
                      setErrDom(false)
                      setDomicilio(e.target.value) 
                  }}
              >
                  {domicilios?.map(dom => (
                      <MenuItem value={dom.id}>{dom.label}</MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ml:0}}>{errDom ? 'Seleccione un domicilio de salida': ''}</FormHelperText>
            </FormControl>
        }
        
      </Grid>
      <Grid  item xs={2} sx={{p:1, justifyContent: 'flex-end', height:60}} className="center_item">
        {
          orden?.docu_estatus === EstatusDocumentoEnum.SIN_ESTATUS &&
            <Button variant="contained" className="btn_system" onClick={enviarOrden} sx={{width:'100%', ml:1}}>
              Enviar
            </Button>
          
        }
        {
          orden?.docu_estatus !== EstatusDocumentoEnum.SIN_ESTATUS &&
          permissions?.some(objeto => objeto.permissionKey === 'MRP:Administracion:Salidas:Reabrir') &&
            <Button variant="contained" className="btn_system" onClick={reabrirSalida} sx={{width:'100%', ml:1}}>
              Reabrir
            </Button>
          
        }
      </Grid>
      <Grid  item xs={6} sx={{p:1, pl:2, justifyContent: 'flex-start', flexDirection:'row', mt:-1.5}} className="center_item">
        <Typography id="transition-modal-title" variant="h6" component="h2">
          {`Orden de salida n° ${orden?.docu_folio}`}
        </Typography>
      </Grid>
      <Grid  item xs={6} sx={{p:1, pl:2, justifyContent: 'flex-end', mt:-1.5}} className="center_item">
        <Typography id="transition-modal-title" variant="h6" component="h2">
          {moment(orden?.docu_fecharegistro).format('yyyy-MM-DD')}
        </Typography>
      </Grid>
      <Grid  item xs={6} sx={{p:1, pl:2, justifyContent: 'flex-start', mt:-1}} className="center_item">
        <Typography id="transition-modal-title" variant="h6" component="h2">
          {orden?.empresas?.emp_razonsocial}
        </Typography>
      </Grid>
      
      <Grid  item xs={6} sx={{p:1, pl:2, justifyContent: 'flex-end', mt:-1}} className="center_item">
        <Typography id="transition-modal-title" variant="h6" component="h2">
          {orden?.docu_estatus === EstatusDocumentoEnum.CERRADO ? 'Enviada' : 'Activa'}
        </Typography>
      </Grid>
      {
        orden?.docu_estatus === EstatusDocumentoEnum.CERRADO &&
        <Grid item xs={12} sx={{p:1, pl:2, justifyContent: 'flex-start', mt:-1, gap: 3}} className="center_item">
          <Button href={shipperUrl} target="_blank" variant="contained" className="btn_system" sx={{width: "auto", backgroundColor: "#ff6723", borderColor: "#ff6723" }}>
            🟠 Shipper ⬇️
          </Button>
          <Button href={packingListUrl} target="_blank" variant="contained" className="btn_system" sx={{width: "auto", backgroundColor: "#0074ba", borderColor: "#0074ba" }}>
            🔵 Packing list ⬇️
          </Button>
          <Button 
            variant="contained" 
            className="btn_system" 
            sx={{width: "auto", backgroundColor: "#8d65c5", borderColor: "#8d65c5" }} 
            onClick={downloadCertificates}
          >
            🟣 Certificados ⬇️
          </Button>
        </Grid>
      }
      <Grid  item xs={12} sx={{p:1, pl:2, justifyContent: 'flex-end'}} className="center_item">
        <TablaMovimientos key={'tabla_movs'} id={orden?.docu_id} empresa={orden?.emp_id} status={orden?.docu_estatus}/>
      </Grid>

      <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openRes}
      onClose={closeRes}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openRes}>
        <Box sx={style} className="modal_carga">
          <Grid container spacing={0} sx={{width: '100%'}}>
            <Grid
              item
              xs={12}
              sx={{ p: 0, mb: 1, pr: 1 }}
              className="center_item"
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Certificados
              </Typography>
            </Grid>
            {certificatedListUrl.map(cert => (
              <Grid
              item
              xs={12}
              sx={{ p: 0, mb: 1 }}
              >
                <Button variant="text" className="btn_system" sx={{width: "100%", height:"25px" }} href={cert} target="_blank">
                  {cert?.split('/')[3]}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Fade>
    </Modal>

    </Grid>
  )
}