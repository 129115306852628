import React, { useContext, useEffect, useState } from "react";
import { PreguntasEnum } from "../../../../../../@enums/tipo_pregunta.enum";
import { 
    Checkbox,
    Divider,
    FormControl, 
    FormControlLabel, 
    FormGroup, 
    FormLabel, 
    Grid, 
    Radio, 
    RadioGroup, 
    TextField, 
    Typography 
} from "@mui/material";
import { ThemeContext } from "@emotion/react";


const PreguntaEncuesta = ({id, pregunta, descripcion, tipoRespuesta, opciones, multiple, handleChangeAnswer, predeterminado, formdata, standar, resp, cantidad, objs, rangos, disabled }) => {

    const theme = useContext(ThemeContext)
        // opiones parseadas 
    const [opt, setOpt] = useState([]);
    const [dataDefault, setDataDefault] = useState(null);
    const [defaultRadio, setDefaultRadio]  = useState(null);
    const [muestra, setMuestra]  = useState(0);
    const [mediciones, setMediciones]  = useState(0);
    const [inputs, setInputs] = useState(0);

    useEffect(() => {
        if (opciones) {
            try {
                setOpt(JSON.parse(opciones));
            } catch (error) {
                console.error(
                    "No se pudo generar las opciones de la pregunta ya que no tiene un formato json correcto"
                )
            }
        }
    }, [opciones])

    useEffect(() => {
        if (predeterminado) {
            try {
                let data_pred = JSON.parse(predeterminado)
                setDataDefault(data_pred);
                setMediciones(parseInt(data_pred[2]?.value))
                setMuestra(parseInt(data_pred[3]?.value))
                if(tipoRespuesta === PreguntasEnum.OPCIONES && !multiple){
                    setDefaultRadio(JSON.parse(predeterminado).toString())
                }
            } catch (error) {
                console.error(
                    "No se pudo generar las opciones de la pregunta ya que no tiene un formato json correcto"
                )
            }
        }
    }, [predeterminado])

    useEffect(()=>{
        getTotalInputsEst(cantidad, objs)
    }, [cantidad])

    const getTotalInputsEst = (cant, objEst) => {
        let jsonObject = [];
        if(objEst !== undefined){
            jsonObject = JSON.parse(objEst, (key, value) => {
                if (value === "Infinity") {
                    return Infinity;
                }
                return value;
            });
        }
        for (let objeto of jsonObject) {
            let [min, max] = objeto?.range;
            if (cant >= min && cant <= max) {
                // if(resp === null || resp === undefined || resp.length === 0){
                //     let arr = new Array(objeto?.num_fields)
                //     arr.fill(null)
                //     handleChangeAnswer(id,arr, 2)
                // }
                setInputs(objeto?.num_fields)
                return objeto?.num_fields;
            }
        }
        return 0;
    }

    const validateRange = (name, value, id, index, e) => {
        let val = parseFloat(value).toFixed(2);
        e.target.value = val;
        if(val >= parseFloat(rangos[0].value) && val <= parseFloat(rangos[1].value)){
            formdata.current[name].value = val
            handleChangeAnswer(id, val, PreguntasEnum.MEDICIONES, false, index)
        }else if(value === ''){
            formdata.current[name].value = value;
            handleChangeAnswer(id, null, PreguntasEnum.MEDICIONES, false, index)
        }else{
            formdata.current[name].value = parseFloat(rangos[0].value).toFixed(2);
            handleChangeAnswer(id, parseFloat(rangos[0].value).toFixed(2), PreguntasEnum.MEDICIONES, false, index)
        }
        
    }

    const validateSinEstandar = (name, value, id, index,e) => {
        let val = parseFloat(value).toFixed(2);
        e.target.value = val;
        if(value === ''){
            formdata.current[name].value = value;
            handleChangeAnswer(id, null, PreguntasEnum.MEDICIONES, false, index)
        }else{
            formdata.current[name].value = val
            handleChangeAnswer(id, val, PreguntasEnum.MEDICIONES, false, index)
        }
    }

    return(
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{p:2, mb:1,display:'flex', justifyContent: 'center', flexDirection:'column'}} >
                    <Typography id="transition-modal-title" variant="h10" component="h3" sx={{color: theme.palette.primary.input, fontSize:16}}>
                        {pregunta}
                    </Typography>
                    {
                        descripcion && 
                        <Typography 
                        id="transition-modal-title" 
                        variant="h11" component="h4" 
                        sx={{color: theme.palette.primary.input, fontSize:12}}>
                            {descripcion}
                        </Typography>
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{p:2, mb:1, display:'flex', justifyContent: 'center', flexDirection:'column'}} >
                    {
                        tipoRespuesta === PreguntasEnum.TEXTO && //Tipo texto
                        <TextField
                            id={`${id}`}
                            name={`${id}`}
                            size="small"
                            fullWidth
                            multiline
                            minRows={3}
                            required
                            disabled={disabled}
                            defaultValue={resp === '' ? null : resp}
                            onChange={(e) => handleChangeAnswer(id, e.target.value, PreguntasEnum.TEXTO)}
                            sx={{color: theme.palette.primary.input}}
                        />
                    }
                    {
                        tipoRespuesta === PreguntasEnum.NUMERICO && //Tipo número
                        <TextField
                            id={`${id}_numero`}
                            name={`${id}_numero`}
                            size="small"
                            type="number"
                            fullWidth
                            required
                            disabled={disabled}
                            defaultValue={
                              resp === '' || isNaN(resp) ? 
                              null :
                              parseFloat(JSON.parse(resp)).toFixed(2)
                            }
                            onChange={(e) => handleChangeAnswer(id, e.target.value, PreguntasEnum.NUMERICO)}
                            onBlur={(e) => 
                                formdata.current[`${id}_numero`].value = parseFloat(e.target?.value).toFixed(2)
                            }
                            sx={{color: theme.palette.primary.input}}
                        />
                    }
                    {
                        tipoRespuesta === PreguntasEnum.RANGO && //Tipo rango
                        <Grid container spacing={0}>
                            <Grid item xs={6} sx={{p:0, pr:1, textAlign:'center'}}>
                                <TextField
                                    id={`${id}_rango_min`}
                                    name={`${id}_rango_min`}
                                    size="small"
                                    type="number"
                                    fullWidth
                                    required
                                    disabled={disabled}
                                    defaultValue={parseFloat(resp[0].value)}
                                    onChange={(e) => handleChangeAnswer(id, e.target.value, PreguntasEnum.RANGO, false, 0)}
                                    sx={{color: theme.palette.primary.input}}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{p:0, pl:1, textAlign:'center'}}>
                                <TextField
                                    id={`${id}_rango_max`}
                                    name={`${id}_rango_max`}
                                    size="small"
                                    type="number"
                                    fullWidth
                                    required
                                    disabled={disabled}
                                    defaultValue={parseFloat((resp[1].value))}
                                    onChange={(e) => handleChangeAnswer(id, e.target.value, PreguntasEnum.RANGO, false, 1)}
                                    sx={{color: theme.palette.primary.input}}
                                />
                            </Grid>
                        </Grid>
                    }
                    {
                        tipoRespuesta === PreguntasEnum.OPCIONES && multiple === 0 && //tipo opciones radio
                        <FormControl component="fieldset" variant="standard" id={id} required>
                            <FormLabel id={id}></FormLabel>
                            <RadioGroup
                                aria-labelledby={`${id}_radio_options`}
                                name={`${id}_radio_options_radio`}
                                defaultValue = {resp}
                                disabled={disabled}
                                onChange={(e) => 
                                    handleChangeAnswer(id, parseInt(e.target.value), PreguntasEnum.OPCIONES)
                                }
                            >
                                {
                                    opt?.map((opcion, iOpcion) => 
                                        <>
                                        <FormControlLabel 
                                            key={`${pregunta?.cues_id}-${iOpcion}`} 
                                            value={opcion?.value} 
                                            control={<Radio />} 
                                            label={opcion.label} 
                                        />
                                        </>
                                    )
                                }
                            </RadioGroup>
                        </FormControl>
                    }
                    {
                        tipoRespuesta === PreguntasEnum.OPCIONES && multiple === 1 && //tipo opciones multiple
                        <FormControl sx={{ m: 0 }} component="fieldset" variant="standard" required name={id} id={id} disabled={disabled}>
                            <FormLabel component="legend"></FormLabel>
                            <FormGroup id={id} itemID={id} >
                                {
                                    opt?.map((opcion) => 
                                        <FormControlLabel
                                        control={
                                            <Checkbox 
                                            defaultChecked={resp?.includes(opcion?.value)}
                                            onChange={(e) => handleChangeAnswer(id, opcion?.value, PreguntasEnum.OPCIONES, true)}
                                            />
                                        }
                                        label={opcion.label}
                                        />
                                    )
                                }
                            </FormGroup>
                        </FormControl>
                    }
                    {
                        tipoRespuesta === PreguntasEnum.MEDICIONES && 
                        <>
                            <div style={{width: '100%', display:'flex', flexDirection: 'row', alignItems:'center', justifyContent:' space-around'}} >
															<Typography id="transition-modal-title" variant="h10" component="h3" sx={{color: theme.palette.primary.input, fontSize:14}}>Minimo:</Typography> 
															{!disabled &&
															<span style={{fontSize:14}}>{Array.isArray(dataDefault) ? parseFloat(dataDefault?.find(d => d?.label === "min")?.value) : null}</span>}
															{disabled &&
															<span style={{fontSize:14}}>{Array.isArray(opciones) ? parseFloat(opciones?.find(d => d?.label === "min")?.value) : null}</span>}
															<Typography id="transition-modal-title" variant="h10" component="h3" sx={{color: theme.palette.primary.input, fontSize:14}}>Máximo:</Typography> 
															{!disabled &&
															<span style={{fontSize:14}}>{Array.isArray(dataDefault) ? parseFloat(dataDefault?.find(d => d?.label === "max")?.value) : null}</span>}
															{disabled &&
															<span style={{fontSize:14}}>{Array.isArray(opciones) ? parseFloat(opciones?.find(d => d?.label === "max")?.value) : null}</span>}
														</div>
                            <div style={{width: '100%', display:'flex', justifyContent:'center'}} >
                                <Typography id="transition-modal-title" variant="h10" component="h3" sx={{color: '#000000', fontSize:14}}>
                                    {standar}
                                </Typography> 
                            </div>

                            {cantidad > 0 &&
                            <Grid container spacing={0}>
                                {Array.from({ length: inputs }).map((_, index) => (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:1, mb:1, display:'flex', justifyContent: 'center', flexDirection:'column'}} >
                                            <TextField
                                            id={`${index}_estd`}
                                            name={`${index}_estd`}
                                            size="small"
                                            type="number"
                                            fullWidth
                                            required
                                            disabled={disabled}
                                            defaultValue={resp !== undefined ? parseFloat((resp[index]))?.toFixed(2) : 25.00}
                                            onBlur={(e) => 
                                                validateRange(`${index}_estd`, e.target.value, id, index, e)
                                            }
                                            sx={{color: theme.palette.primary.input}}
                                            />
                                    </Grid>
                                        
                                ))}
                            </Grid>
                            }
                        </>
                    }
                    {
                        tipoRespuesta === PreguntasEnum.MEDICIONES_SIN_ESTANDAR && 
                        <>
                            <div style={{width: '100%', display:'flex', justifyContent:'center'}} >
                                <Typography id="transition-modal-title" variant="h10" component="h3" sx={{color: '#000000', fontSize:14}}>
                                    Valores de referencia
                                </Typography> 
                            </div>
                            <div style={{width: '100%', display:'flex', flexDirection: 'row', alignItems:'center', justifyContent:' space-around'}} >
                                <Typography id="transition-modal-title" variant="h10" component="h3" sx={{color: theme.palette.primary.input, fontSize:14}}>Minimo:</Typography> 
                                <span style={{fontSize:14}}>{Array.isArray(dataDefault) ? parseFloat(dataDefault?.find(d => d?.label === "min")?.value) : null}</span>
                                <Typography id="transition-modal-title" variant="h10" component="h3" sx={{color: theme.palette.primary.input, fontSize:14}}>Máximo:</Typography> 
                                <span style={{fontSize:14}}>{Array.isArray(dataDefault) ? parseFloat(dataDefault?.find(d => d?.label === "max")?.value) : null}</span>
                            </div>
                            <div style={{width: '100%', display:'flex', flexDirection: 'row', alignItems:'center', justifyContent:' space-around'}} >
                                <Typography id="transition-modal-title" variant="h10" component="h3" sx={{color: theme.palette.primary.input, fontSize:14}}>Mediciones:</Typography> 
                                <span style={{fontSize:14}}>{Array.isArray(dataDefault) ? parseFloat(dataDefault?.find(d => d?.label === "mediciones")?.value) : null}</span>
                                <Typography id="transition-modal-title" variant="h10" component="h3" sx={{color: theme.palette.primary.input, fontSize:14}}>Muestra:</Typography> 
                                <span style={{fontSize:14}}>{Array.isArray(dataDefault) ? parseFloat(dataDefault?.find(d => d?.label === "muestra")?.value) : null}</span>
                            </div>
                            <Divider  sx={{mb:1}}/>
                            { ((muestra > 0 && mediciones > 0) || (resp.length > 0)) &&
                            <Grid container spacing={0}>
                                {Array.from({ length: muestra > 0 && mediciones > 0 ? mediciones * muestra : resp.length }).map((_, index) => (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{p:1, mb:1, display:'flex', justifyContent: 'center', flexDirection:'column'}} >
                                            <TextField
                                            id={`${index}_sinmuestra`}
                                            name={`${index}_sinmuestra`}
                                            size="small"
                                            type="number"
                                            fullWidth
                                            required
                                            disabled={disabled}
                                            defaultValue={resp !== undefined ? parseFloat((resp[index]))?.toFixed(2) : 0.00}
                                            onBlur={(e) => {
                                                const min = Array.isArray(dataDefault) ? parseFloat(dataDefault?.find(d => d?.label === "min")?.value) : null
                                                const max = Array.isArray(dataDefault) ? parseFloat(dataDefault?.find(d => d?.label === "max")?.value) : null
                                                const value = parseFloat(e.target.value);
                                                if (value < min) {
                                                    e.target.value = min;
                                                }else if(value > max){
                                                    e.target.value = max;
                                                }
                                                validateSinEstandar(`${index}_sinmuestra`, e.target.value, id, index, e)
                                            }}
                                            sx={{color: theme.palette.primary.input}}
                                            />
                                    </Grid>
                                ))}
                            </Grid>
                            }
                        </>
                    }
                </Grid>
            </Grid>
            <Divider sx={{mt:2, mb: 3}}/>
        </>
    )
}

export default PreguntaEncuesta;