import React from "react";
import { Backdrop, Box, Fade, Grid, Modal, Typography } from "@mui/material";
import { AreaAlmacenEnum } from "../../../../@enums/tipo_almacen";


const ModalAlmacenes = ({open, setOpen, setSeccionId, almacenes, setAyudas, setOpenAyudas, setName, setCanReport}) => {

    const handleClose = () => {
        setOpen(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 1000,
        minWidth: 600,
        height: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        pt: 3,
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'scroll'
    };

    const chanceAlmacen = (id, name, area) => {
        setSeccionId(id);
        handleClose();
        setAyudas([]);
        setOpenAyudas(false);
        setName(name?.toUpperCase());
        setCanReport(
            area === AreaAlmacenEnum.LOGISTICA 
            ? false : true
        )
    }

    return (
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
            backdrop: {
                timeout: 500,
            },
        }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} className="center_item">
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Seleccione un almacen
                            </Typography>
                        </Grid>
                        {almacenes.map(alm => (
                            <Grid item xs={4} className="center_item" sx={{p:1.5}}>
                                <div className="card_alm" 
                                style={{background:`${alm?.color}30`, padding: '0px 10px', textAlign:'center'}}
                                onClick={()=>chanceAlmacen(alm?.id, alm?.label, alm?.area)}
                                >
                                    <span 
                                    className="text_card_alm" 
                                    style={{color:alm?.color}}>
                                        {alm?.label.toUpperCase()}
                                    </span>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}

export default ModalAlmacenes;