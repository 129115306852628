import { useSelector } from "react-redux";
import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const CrearOrden = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/documentos/orden`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        crearOrdenMutation: mutation.mutate,
    };
}

export const CrearOrdenNew = ()  => {
    const api = useApi();
    const { givenName } = useSelector((state) => state.auth.user);

    const mutate = async (params) => {
        params.usuario = givenName;

        const data  = await api.post(`/administracion-inventario/crear-orden`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        crearOrdenNewMutation: mutation.mutate,
    };
}