import React, { useContext, useRef, useState } from "react";
import { ThemeContext } from "@emotion/react";
import { useModal } from "../../../../hooks/use/useModal";
import { useNavigate, useParams } from "react-router-dom";
import { Backdrop, Box, Button, Fade, Grid, IconButton, Modal, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import { AddCircle } from "@mui/icons-material";
import { CreateContacto } from "../../../../hooks/crearContacto";
import validateEmail from "../../../../helpers/validateEmail";
import swal from 'sweetalert2'

const style_mod = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center'
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box >
                {children}
            </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


const EmpresasContactos = ({ sample, contactos_empresa, refecth }) => {

    const theme = useContext(ThemeContext);
    const {crearContactoMutation} = CreateContacto();
    const [visible, showModal, closeModal] = useModal();
    const [actTab, setActTab] = useState(0);
    const [errName, setErrName] = useState(false);
    const [errPat, setErrPat] = useState(false);
    const [errMat, setErrMat] = useState(false);
    const [errMail, setErrMail] = useState(false);
    const formNewCont = useRef(null);
    const navigate = useNavigate();
    let { id } = useParams();

    const rows = sample;

    const columns = [
        {
            width: 50,
            label: 'Nombres',
            dataKey: 'cont_nombres',
        },
        {
            width: 30,
            label: 'Apellido paterno',
            dataKey: 'cont_aparterno',
        },
        {
            width: 30,
            label: 'Apellido materno',
            dataKey: 'cont_amaterno',
        },
        {
            width: 50,
            label: 'Correo',
            dataKey: 'cont_mail',
        },
        {
            width: 20,
            label: 'Principal',
            dataKey: 'principal',
        },
    ];

    const redirect = (id) => {
        navigate(`/inventarios/contactos/${id}`);
    }

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    function fixedHeaderContent() {
        return (
            <TableRow sx={{height:'10px !important'}}>
                {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align="center"
                    style={{ width: column.width, height:'10px !important' }}
                    sx={{
                        display: 'table-cell',
                        backgroundColor: theme.palette.primary.main,
                        color:'white',
                        fontWeight:600,
                        height:'10px !important',
                        padding: '2px 15px'
                    }}
                >
                    {column.label}
                </TableCell>
                ))}
            </TableRow>
        );
    }

    function rowContent(_index, row) {
        return (
            <>
                <React.Fragment>
                    {columns.map((column) => (
                        <TableCell
                        key={column.dataKey}
                        align="center"
                        sx={{
                            display: 'table-cell !important',
                            fontWeight:500,
                            color: theme.palette.primary.input,
                            background: 'white',
                            verticalAlign: 'center',
                            padding:'8px 15px'
                        }}
                        onClick={() => redirect(row['cont_id'])}
                        >
                            {column.dataKey === 'principal' ? (
                                <>
                                    {contactos_empresa?.find(ce => ce?.cont_id === row['cont_id'])?.use_principal ?
                                    "Si": "No"}
                                </>
                            ): (
                                (
                                    <>
                                        {
                                            row[column.dataKey] === null 
                                            ? '' : row[column.dataKey]}
                                    </>
                                )
                            )}
                        </TableCell>
                    ))}
                </React.Fragment>
            </>
        );
    }

    const handleChange = (event, newValue) => {
        setActTab(newValue);
    };

    const validateForm = () => {
        if(formNewCont.current.cont_nombres.value === '' || 
        formNewCont.current.cont_aparterno.value === '' ||
        formNewCont.current.cont_amaterno.value === '' ||
        formNewCont.current.cont_mail.value === '' ||
        !validateEmail(formNewCont.current.cont_mail.value)
        ){
            if(formNewCont.current.cont_nombres.value === '')setErrName(true);
            if(formNewCont.current.cont_aparterno.value === '')setErrPat(true);
            if(formNewCont.current.cont_amaterno.value === '')setErrMat(true);
            if(formNewCont.current.cont_mail.value === '')setErrMail(true);

            return false;
        }else{
            return true;
        }
    }

    const handleGuardarHorario = () => {
        if(validateForm()){
            let data ={
                cont_nombres: formNewCont.current.cont_nombres.value,
                cont_aparterno: formNewCont.current.cont_aparterno.value,
                cont_amaterno: formNewCont.current.cont_amaterno.value,
                cont_mail: formNewCont.current.cont_mail.value,
                cont_activo: 1,
            }
            crearContactoMutation({emp_id: id, data: data}, {
                onSuccess: (data) => {
                    closeModal()
                    swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Contacto creado con éxito",
                        showConfirmButton: false,
                        timer: 800
                    })
                    refecth()
                },
                onError: (error) => {
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Hubo un problema al crear",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        }
    }

    return(
        <div style={{display:'flex', flexDirection:'column', width:'100%', textAlign:'center', padding:'0px 10px', marginBottom:8}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', paddingTop:5}}>
                <Typography style={{ fontSize: 18, fontWeight: "bold" }}>Contactos</Typography>
                <IconButton size="small" onClick={showModal} sx={{right:0, position:'absolute', marginRight:2}}>
                    <AddCircle sx={{ fontSize: 25 }}/>
                </IconButton>
            </div>
            <Paper style={{ height: 200, width: '100%', marginTop:0 }} sx={{boxShadow:'none'}}>
                <TableVirtuoso
                    data={rows}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={fixedHeaderContent}
                    itemContent={rowContent}
                    style={{
                        boxShadow:'none',
                        border:'1px solid #f2f3f3',
                        width: '100%'
                    }}
                />
            </Paper>
            <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={visible}
            onClose={closeModal}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
            >
                <Fade in={visible}>
                    <Box sx={style_mod}>
                        <Box sx={{width: '100%'}}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={actTab} onChange={handleChange} centered aria-label="basic tabs example">
                                    <Tab label="Contacto existente" {...a11yProps(0)}/>
                                    <Tab label="Contacto nuevo" {...a11yProps(1)}/>
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={actTab} index={0}>
                                
                            </CustomTabPanel>
                            <CustomTabPanel value={actTab} index={1}>
                                <form ref={formNewCont} style={{width:'100%'}}>
                                    <Grid container spacing={0} sx={{mt:3}}>
                                        <Grid item xs={12} sx={{padding:'0px 10px', width:'100%'}} className="center_item">
                                            <TextField
                                                id="cont_nombres"
                                                size="small"
                                                variant="standard"
                                                label="Nombre(s)"
                                                required
                                                fullWidth
                                                onChange={(e)=> {
                                                    e.target.value.length > 0 ?  
                                                    setErrName(false) :
                                                    setErrName(true)
                                                }}  
                                                error={errName}
                                                helperText={errName ? 'Ingrese un valor' : ''}
                                                sx={{color: theme.palette.primary.input}}
                                                />
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={12} lg={6} xl={6} sx={{padding:'0px 10px', height:'100%', mt:2}} className="center_item">
                                            <TextField
                                                id="cont_aparterno"
                                                size="small"
                                                variant="standard"
                                                label="Apellido paterno"
                                                required
                                                fullWidth
                                                onChange={(e)=> {
                                                    e.target.value.length > 0 ?  
                                                    setErrPat(false) :
                                                    setErrPat(true)
                                                }}  
                                                error={errPat}
                                                helperText={errPat ? 'Ingrese un valor' : ''}
                                                sx={{color: theme.palette.primary.input}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={12} lg={6} xl={6} sx={{padding:'0px 10px', height:'100%', mt:2}} className="center_item">
                                            <TextField
                                            id="cont_amaterno"
                                            size="small"
                                            variant="standard"
                                            label="Apellido materno"
                                            required
                                            fullWidth
                                            onChange={(e)=> {
                                                e.target.value.length > 0 ?  
                                                setErrMat(false) :
                                                setErrMat(true)
                                            }}  
                                            error={errMat}
                                            helperText={errMat ? 'Ingrese un valor' : ''}
                                            sx={{color: theme.palette.primary.input}}
                                        />
                                        </Grid>
                                        <Grid item xs={12} sx={{padding:'0px 10px', height:'100%', mt:2}} className="center_item">
                                            <TextField
                                            id="cont_mail"
                                            size="small"
                                            variant="standard"
                                            label="correo electrónico"
                                            fullWidth
                                            required
                                            onChange={(e)=> {
                                                e.target.value.length > 0 && validateEmail(e.target.value)?  
                                                setErrMail(false) :
                                                setErrMail(true)
                                            }}  
                                            error={errMail}
                                            helperText={errMail ? 'Ingrese un correo válido' : ''}
                                            sx={{color: theme.palette.primary.input, mt:1,ml:1}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{padding:'0px 10px', height:'100%', mt:3}} className="center_item">
                                            <Button variant="contained" 
                                                className="btn_system" 
                                                onClick={handleGuardarHorario}
                                                >
                                                Crear
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CustomTabPanel>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default EmpresasContactos;