import { ThemeContext } from "@emotion/react";
import { useContext, useState } from "react";
import { CreateRol } from "../../../../hooks/crearRol";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import swal from "sweetalert2";

export const NuevoRol = ({ open, handleClose }) => {
  const style = {
    position: "absolute",
    top: "35%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
  };

  const theme = useContext(ThemeContext);
  const { createRolMutation } = CreateRol();
  const [errorRol, setErrorRol] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    if (event.target.roleName.value !== "") {
      createRolMutation(
        { roleName: event.target.roleName.value },
        {
          onSuccess: (data) => {
            swal
              .fire({
                position: "top-end",
                icon: "success",
                title: "Rol creado con éxito",
                showConfirmButton: false,
                timer: 1500,
              })
              .then(() => {
                handleClose();
              });
          },
          onError: (error) => {
            swal.fire({
              position: "top-end",
              icon: "error",
              title: "Hubo un error",
              text: "Intenta de nuevo",
              showConfirmButton: false,
              timer: 1500,
            });
            handleClose();
          },
        }
      );
    } else {
      setErrorRol(true);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <form onSubmit={onSubmit}>
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                sx={{ p: 0, mb: 2, pr: 1 }}
                className="center_item"
              >
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Nuevo Rol
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ p: 0, mb: 3, pr: 1 }}>
                <TextField
                  id="roleName"
                  label="Nombre Rol"
                  name="roleName"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    e.target.value.length > 0
                      ? setErrorRol(false)
                      : setErrorRol(true);
                  }}
                  sx={{ color: theme.palette.primary.input }}
                  error={errorRol}
                  helperText={errorRol ? "Ingrese un valor" : ""}
                />
              </Grid>
              <Grid item xs={12} sx={{ p: 0, mb: 1, pr: 1, mt: 2 }}>
                <Button
                  variant="contained"
                  className="btn_system"
                  type="submit"
                >
                  Crear Rol
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};
