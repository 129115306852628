import React, { useEffect, useState } from "react";
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import { GetCertificadosList } from "../../../../../hooks/getListCertificados";
import { GetAllFormatos } from "../../../../../hooks/getAllFormatos";
import { useLoading } from "../../../../../components/Loading/useLoading";

const FormSecundary = ({selected, step, updateRuta, index, updateCodigo}) => {

    const {setAppLoading} = useLoading();
    const {getCertificadosListMutation} = GetCertificadosList();
    const {getAllFormatosMutation} = GetAllFormatos();
    const [data, setData] = useState(step.rupr_codigocambio)
    const [certificados, setCertificados] = useState(null);
    const [formatos, setFormatos] = useState(null);

    const get_certificados = () => {
      // setAppLoading(true)
      getCertificadosListMutation(step?.part_id, {
          onSuccess: (data) => {
              setCertificados(data)
              // setAppLoading(false)
          },
          onError: (error) => {
              console.log(error)
          }
      })
    }

    const get_formatos = () => {
      // setAppLoading(true)
      getAllFormatosMutation("",{
          onSuccess: (data) => {
            setFormatos(data);
            // setAppLoading(false);
          },
          onError: (error) => {
            console.log(error);
            // setAppLoading(false);
          }
      })
    }

    useEffect(() => {
        get_certificados();
        get_formatos();
    }, []);

    return(
        <Grid container spacing={0} sx={{mt:1}}>  
            {
              selected?.tag && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{p:1, width:'100%', mb:1}} >
                  <Grid container spacing={0}>
                      <Grid item xs={12} sx={{p:0, mt:0, width:'100%', mb:1}} >
                          <span style={{fontWeight:500}}>Formato de etiqueta</span>
                      </Grid>
                      <Grid item xs={12} sx={{p:0, width:'100%'}} >
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-f-label">Seleccione la etiqueta</InputLabel>
                            <Select
                                labelId="demo-simple-select-f-label"
                                id="formato"
                                name="formato"
                                value={step?.foet_id}
                                label="Seleccione la etiqueta"
                                onChange={
                                    (e)=> updateRuta(
                                        step?.rupr_id, 
                                        'foet_id', 
                                        e.target.value                                    )
                                }
                            >   
                                {formatos?.map(formato => (
                                    <MenuItem value={formato?.foet_id}>{formato?.foet_nombre}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                      </Grid>
                  </Grid>
                </Grid>
                  
              )
            }
            {
              selected?.tag_reporteoid && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{p:1, width:'100%', mb:1}} >
                  <Grid container spacing={0}>
                      <Grid item xs={12} sx={{p:0, mt:0, width:'100%', mb:1}} >
                          <span style={{fontWeight:500}}>Formato de etiqueta de traspaso</span>
                      </Grid>
                      <Grid item xs={12} sx={{p:0, width:'100%'}} >
                      <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-f-label">Seleccione la etiqueta</InputLabel>
                          <Select
                              labelId="demo-simple-select-f-label"
                              id="formato"
                              name="formato"
                              value={step?.foet_reporteoid}
                              label="Seleccione la etiqueta"
                              onChange={
                                  (e)=> updateRuta(
                                      step?.rupr_id, 
                                      'foet_reporteoid', 
                                      e.target.value                                    )
                              }
                          >   
                              {formatos?.map(formato => (
                                  <MenuItem value={formato?.foet_id}>{formato?.foet_nombre}</MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                      </Grid>
                  </Grid>
                </Grid>
                  
              )
            }
            {
              selected?.certificate && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{p:1, width:'100%', mb:1}} >
                  <Grid container spacing={0}>
                    <Grid item xs={12} sx={{p:0, mt:0, width:'100%', mb:1}} >
                        <span style={{fontWeight:500}}>Certificado</span>
                    </Grid>
                    <Grid item xs={12} sx={{p:0, mb:1, width:'100%'}} >
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">Seleccione el certificado</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="certificate"
                                name="certificate"
                                value={step?.cues_id}
                                label="Seleccione el certificado"
                                onChange={
                                    (e)=> updateRuta(
                                        step?.rupr_id, 
                                        'cues_id', 
                                        e.target.value                                    )
                                }
                            >   
                                {certificados?.map(cert => (
                                    <MenuItem value={cert?.id}>{cert?.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                  
              )
            }
            {
              selected?.certificate_reporteoid && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{p:1, width:'100%', mb:1}} >
                  <Grid container spacing={0}>
                    <Grid item xs={12} sx={{p:0, mt:0, width:'100%', mb:1}} >
                        <span style={{fontWeight:500}}>Certificado de traspaso</span>
                    </Grid>
                    <Grid item xs={12} sx={{p:0, mb:1, width:'100%'}} >
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">Seleccione el certificado</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="certificate_reporteoid"
                                name="certificate_reporteoid"
                                value={step?.cues_reporteoid}
                                label="Seleccione el certificado"
                                onChange={
                                    (e)=> updateRuta(
                                        step?.rupr_id, 
                                        'cues_reporteoid', 
                                        e.target.value                                    )
                                }
                            >   
                                {certificados?.map(cert => (
                                    <MenuItem value={cert?.id}>{cert?.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                  
              )
            }
            {
              selected?.code && (
                <Grid item xs={12} sx={{p:0, width:'100%', mb:1}} >
                  <Grid container spacing={0}>
                    <Grid item xs={12} sx={{p:0, mt:2, width:'100%'}} >
                        <span style={{fontWeight:500}}>Seleccione los códigos</span>
                    </Grid>
                    <FormControl component="fieldset" sx={{width:'100%', display:'flex', flexDirection:'row'}}>
                        <RadioGroup
                            row
                            name="row-radio-buttons-group"
                            sx={{width:'100%', justifyContent:'space-around'}}
                            onChange={(e) => updateCodigo(index, e.target.value)}
                            defaultValue={data}
                        >
                            <FormControlLabel value="1-0-0" control={<Radio />} label="Entrada" />
                            <FormControlLabel value="0-1-0" control={<Radio />} label="Salida" />
                            <FormControlLabel value="0-0-1" control={<Radio />} label="Alterno" />
                        </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                  
              )
            }
        </Grid>
    )
}

export default FormSecundary;