import { Grid } from "@mui/material"
import { RequestFormTrazabilidad } from "./components/requestForm"
import { GetMapaTrazabilidad } from "../../../hooks/getMapsTrazabilidad";
import { MapaTrazabilidad } from "./components/mapa";
import { useEdgesState, useNodesState } from "@xyflow/react";
import { useLoading } from "../../../components/Loading/useLoading";

const multlineNodeStyle = {
  color: "#000",
  border: "1px solid #000",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: "10px 20px",
  background: "#fff",
  borderRadius: 5,
  fontSize: 12,
  transition: "all 0.3s",
  width: "450px"
};
const mainNodeStyle = {
  color: "#fff",
  border: "1px solid #575E3B",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: "10px 20px",
  background: "#575E3B",
  borderRadius: 5,
  fontSize: 11,
  transition: "all 0.3s",
};


export const TrazabilidadIndex = () => {

  const {setAppLoading} = useLoading();
  const {getMapaTrazabilidadMutation} = GetMapaTrazabilidad();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);


  const InfoNodo = ({data, main = false}) => {


    let tags_unicas;
    if(data?.etiquetas){
      tags_unicas = [...new Set(data?.etiquetas)];
    } 

    return(
      <div  key={`info_${data?.id}`} style={{display: 'flex', flexDirection: 'column'}}>
        {
          data?.etiqueta && (
            <span>{data?.etiqueta}</span>
          )
        }
        <span style={{color: data?.etiquetas || !main ? '#575E3B': 'auto' }}>Almacen(s):</span>
        <span>{data?.almacen}</span>
        <span style={{color: data?.etiquetas || !main ? '#575E3B': 'auto' }}>Cantidad:</span>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <span>{data?.cantidad}</span>
          { data?.total && 
            <span>/{data?.total}</span>
          }
        </div>
        {
          data?.folios && (
            <>
              <span style={{color: !main ? '#575E3B': 'auto' }}>Seriales:</span>
              <div style={{display: 'flex', flexDirection:'row', justifyContent: 'center'}}>
                {
                  data?.folios?.join(' / ')
                }
              </div>
            </>
          )
        }
        {
          data?.etiquetas && (
            <>
              <span style={{color: '#575E3B'}}>Etiquetas:</span>
              <div style={{display: 'flex', flexDirection:'row', justifyContent: 'center'}}>
                {
                  tags_unicas?.join(' / ')
                }
              </div>
            </>
          )
        }
      </div>
    )
  }
  const EntryNodo = ({data}) => {
    return(
      <div key={`info_${data?.id}`} style={{display: 'flex', flexDirection: 'column'}}>
        <span style={{color: '#575E3B'}}>{data?.intermedio ? 'Movimiento intermedio' : 'Documento Folio'}</span>
        {
          !data?.intermedio &&
          <span>{data?.documento}</span>
        }
        {
          data?.cantidad !== null && (
            <>
              <span style={{color: '#575E3B'}}>Cantidad:</span>
              <span>{data?.cantidad}</span>
            </>
          )
        }
      </div>
    )
  }


  const search = (body) => {
    setAppLoading(true);
    getMapaTrazabilidadMutation(body,{
      onSuccess: (data) => {
        if(data.data){
          let nodes = data.data.nodes;
          let edges = data.data.edges;
  
          let x = 0;
          let y = 300;
          
          let nodos = nodes?.map((node, index) => {
  
            let nodo = {
              id: node?.id,
              position: { x: x, y: y },
              data: { 
                label: node?.documento ?
                <EntryNodo key={`${node.id}_info`} data={node}/> :
                <InfoNodo key={`${node.id}_info`} data={node} main={node.id === body.codigo}/>
              },
              style: node.id === body.codigo ? mainNodeStyle : multlineNodeStyle ,
            }
            if(nodes[index+1]?.level === node?.level){
              x+=500;
            }else{
              x=0;
              if(body.tipo === 1){
                nodes[index+1]?.level - node[0]?.level ? y+=200 : y-=200
              }else{
                if(nodes.some(obj => obj?.level > nodes[index+1]?.level)){
                  y+=350
                }else{
                  if(nodes.some(obj => obj?.level === nodes[index]?.level-1 && obj?.etiquetas?.length > 30) || !node?.etiquetas){
                    y+=450
                  }else{
                    y+=1000
                  }
                }
              }
            }
            return nodo;
          })
  
          setEdges(edges);
          setNodes(nodos);
        }
        setAppLoading(false);
      },
      onError: (error) => {
        console.log(error);
        setAppLoading(false);
      }
    })
  }

  return(
    <Grid key='container_maps' container spacing={0} sx={{height:'87vh'}}>
      <Grid item xs={12} sx={{padding:'10px 20px', flexDirection:'column', justifyContent: 'flex-start'}} className="center_item">
        <RequestFormTrazabilidad  key='form_trazabilidad' search={search}/>
        <MapaTrazabilidad key='mapas' initialEdges={edges} initialNodes={nodes} onEdgesChange={onEdgesChange} onNodesChange={onNodesChange} setEdges={setEdges} setNodes={setNodes}/>
      </Grid>
    </Grid>
  )
}