import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetSalidas = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/administracion-inventario/salidas/master/${params.master}?doc=${params.doc}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getSalidasMutation: mutation.mutate,
    };
}