import { Button, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TablaGeneral from "./components/tablageneral";
import CrearProducto from "./components/crearProducto";
import { GetAllPartes } from "../../../hooks/getAllPartes";
import { useDispatch } from "react-redux";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { useLoading } from "../../../components/Loading/useLoading";

const IndexProducts = () => {


    const dispatch = useDispatch();
    const {setAppLoading} = useLoading();
    const {getAllPartesMutation} = GetAllPartes();
    const [openNuevo, setOpenNuevo] = useState(false);
    const [filtro, setFiltro] = useState('');
    const [partesFiltradas, setPartesFiltradas] = useState([]);

    const get_data_tabla = () => {
        setAppLoading(true);
        getAllPartesMutation(filtro,{
            onSuccess: (data) => {
              setPartesFiltradas(data)
              setAppLoading(false);
            },
            onError: (error) => {
                console.log(error)
                setAppLoading(false);
            }
        })
    }

    useEffect(() => {
        dispatch(
            HEADER_DATA_UPDATE({nombreData: null})
        )
    }, []);

    const handleInputChange = (event) => {
        setFiltro(event.target.value);
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        get_data_tabla()
      }
    };



    return(
        <>
            <Grid container spacing={0} sx={{height:'10vh'}}>
                <Grid item xs={3} sm={3} md={3} lg={2} xl={2} sx={{padding:'10px 30px', height:'100%'}} className="center_item">
                    <Button 
                    variant="contained" 
                    className="btn_system" 
                    onClick={() => setOpenNuevo(true)}>
                        Crear
                    </Button>
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={0} sx={{height:'70vh'}}>
                <Grid item xs={12} sx={{padding:'10px 20px', height:'100%'}} className="center_item">
                    <TablaGeneral   key={'table_gen'} sample={partesFiltradas} filtro={filtro} handleInputChange={handleInputChange} onPressEnter={handleKeyDown}/>
                </Grid>
            </Grid>
            <CrearProducto  key={'modal_nuevo_prod'} open={openNuevo} setOpen={setOpenNuevo}/>
        </>
    )
}

export default IndexProducts;