import React, { useContext } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { EstatusDocumentoEnum } from "../../../../../@enums/status_documento.enum";
import { TipoOrdenEnum } from "../../../../../@enums/tipo_orden_compra";
import { TableVirtuoso } from "react-virtuoso";
import { ThemeContext } from "@emotion/react";


const TablaOrdenes = ({sample, getOrden}) => {

    const theme = useContext(ThemeContext);

    const rows = sample;

    const columns = [
        {
            width: 30,
            label: 'Folio',
            dataKey: 'folio',
        },
        {
            width: 20,
            label: 'Fecha',
            dataKey: 'fecha',
        },
        {
            width: 20,
            label: 'Vigencia',
            dataKey: 'vigencia',
        },
        {
            width: 30,
            label: 'Tipo',
            dataKey: 'tipo',
        },
        {
            width: 30,
            label: 'Estatus',
            dataKey: 'estatus',
        },
    ];

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    function fixedHeaderContent() {
        return (
            <TableRow>
                {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align="center"
                    style={{ width: column.width }}
                    sx={{
                        display: 'table-cell',
                    backgroundColor: theme.palette.primary.main,
                    color:'white',
                    fontWeight:600
                    }}
                >
                    {column.label}
                </TableCell>
                ))}
            </TableRow>
        );
    }

    function rowContent(_index, row) {
        return (
            <React.Fragment>
                {columns.map((column) => (
                    <TableCell
                    key={column.dataKey}
                    align="center"
                    sx={{
                        display: 'table-cell !important',
                        fontWeight:500,
                        color: theme.palette.primary.input,
                        background: 'white'
                    }}
                    onClick={()=>getOrden(row['docu_id'])}
                    >
                        {column.dataKey === 'estatus' ? (
                            <>
                                {row[column.dataKey] === EstatusDocumentoEnum.SIN_ESTATUS ? ('Activo') : 
                                row[column.dataKey] === EstatusDocumentoEnum.CERRADO ? ('Cerrado') : ('Cancelado')}
                            </>
                        ): column.dataKey === 'tipo' ? (
                            <>
                                {row[column.dataKey] === TipoOrdenEnum.CERRADA_CANTIDAD ? ('Cerrada por cantidad') :
                                row[column.dataKey] === TipoOrdenEnum.CERRADA_VIGENCIA ? ('Cerrada por vigencia'): ("Abierta")}
                            </>
                        ):
                        (
                            (
                                <>
                                    {
                                        row[column.dataKey] === null 
                                        ? '' : row[column.dataKey]}
                                </>
                            )
                        )}
                    </TableCell>
                ))}
            </React.Fragment>
        );
    }

    return(
        <Paper style={{ height: 300, width: '100%' }} sx={{boxShadow:'none'}}>
            <TableVirtuoso
                data={rows}
                components={VirtuosoTableComponents}
                fixedHeaderContent={fixedHeaderContent}
                itemContent={rowContent}
                style={{
                    boxShadow:'none',
                    border:'1px solid #f2f3f3',
                    height: '100%'
                }}
            />
        </Paper>
    )

}

export default TablaOrdenes;