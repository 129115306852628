import React, { useContext, useRef, useState } from "react";
import { 
    Modal, 
    Fade, 
    Box, 
    Backdrop, 
    Typography, 
    Grid, 
    TextField, 
    FormControl,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormHelperText,
    IconButton,
    Button,
    Checkbox,
    Select,
    MenuItem
} 
from "@mui/material";
import { Delete } from "@mui/icons-material";
import { CreateCertificado } from "../../../../../hooks/crearCertificado";
import { GetAllStandarsList } from "../../../../../hooks/getStandarsList";
import { PreguntasEnum } from "../../../../../@enums/tipo_pregunta.enum";
import { ThemeContext } from "@emotion/react";
import swal from 'sweetalert2'
import validateNumber from "../../../../../helpers/validateInt";

const ModalCrearEncuesta = ({open, setOpen, id_parte}) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        maxWidth: 1000,
        minWidth: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        pt: 3,
        display: 'flex',
        justifyContent: 'center',
        maxHeight: 550,
        overflowY: 'scroll'
    };

    const theme = useContext(ThemeContext)
    const formCreate = useRef(null);
    const {crearCertificadoMutation} = CreateCertificado()
    const {getAllStandarsListMutation} = GetAllStandarsList()
    const [errorName, setErrorName] = useState(false)
    const [standars, setStandars] = useState([]);
    const [preguntas, setPreguntas] = useState([
        {
            cupe_pregunta: null,
            cupe_descripcion:null,
            cupe_tipo:null,
            cupe_multiple:false,
            cupe_opciones: null,
            cupe_valorprellenado: null,
            stand_id: null
        }
    ])

    const changeDataPregunta = (index, name, data) => {
        let preguntas_data = preguntas;
        preguntas_data[index][name] = data;

        if(name === "cupe_tipo"){
            switch (parseInt(data)) {
                case PreguntasEnum.RANGO:
                    preguntas_data[index].cupe_valorprellenado = [
                        {
                            label: 'min',
                            value: null
                        },
                        {
                            label: 'max',
                            value: null
                        }
                    ]
                    preguntas_data[index].stand_id = null
                    break;
                
                case PreguntasEnum.MEDICIONES:
                    preguntas_data[index].cupe_valorprellenado = [
                        {
                            label: 'min',
                            value: null
                        },
                        {
                            label: 'max',
                            value: null
                        }
                    ]
                    getAllStandarsListMutation({},{
                        onSuccess: (data) => {
                            setStandars(data);
                            preguntas_data[index].stand_id = data[0]?.value;
                        },
                        onError : (error) => {
                            console.log(error)
                            preguntas_data[index].stand_id = null
                        }
                    })
                    
                    break;
                
                case PreguntasEnum.MEDICIONES_SIN_ESTANDAR:
                        preguntas_data[index].cupe_valorprellenado = [
                            {
                                label: 'min',
                                value: null
                            },
                            {
                                label: 'max',
                                value: null
                            },
                            {
                              label: 'mediciones',
                              value: null
                            },
                            {
                              label: 'muestra',
                              value: null
                            }
                        ]
                        preguntas_data[index].stand_id = null
                        
                        break;
                
                case PreguntasEnum.OPCIONES:
                    preguntas_data[index].cupe_opciones = [
                        {
                            label: null,
                            value: 1
                        },
                        {
                            label: null,
                            value: 2
                        }
                    ]
                    preguntas_data[index].cupe_valorprellenado = []
                    preguntas_data[index].stand_id = null
                    break;
            
                default:
                    preguntas_data[index].cupe_opciones = null
                    preguntas_data[index].cupe_valorprellenado = null
                    preguntas_data[index].stand_id = null
                    break;
            }
        }

        setPreguntas([...preguntas_data])
    }

    const changeDataOptions = (index_pregunta, index_opcion, data) => {
        let preguntas_data = preguntas;
        if( parseInt(preguntas_data[index_pregunta].cupe_tipo) === PreguntasEnum.RANGO || 
            parseInt(preguntas_data[index_pregunta].cupe_tipo) === PreguntasEnum.MEDICIONES ||
            parseInt(preguntas_data[index_pregunta].cupe_tipo) === PreguntasEnum.MEDICIONES_SIN_ESTANDAR
        ){
            preguntas_data[index_pregunta].cupe_valorprellenado[index_opcion].value = data === '' ? null : data
        }
        if(parseInt(preguntas_data[index_pregunta].cupe_tipo) === PreguntasEnum.OPCIONES){
            preguntas_data[index_pregunta].cupe_opciones[index_opcion].label = data
        }
        setPreguntas([...preguntas_data])
    }

    const changeDefaultOptions = (index_pregunta, index_opcion, data) => {
        let preguntas_data = preguntas;
        if(preguntas_data[index_pregunta]?.cupe_multiple){
            preguntas_data[index_pregunta]?.cupe_valorprellenado.includes(data) ? 
            preguntas_data[index_pregunta]?.cupe_valorprellenado.splice(
                preguntas_data[index_pregunta]?.cupe_valorprellenado.indexOf(data), 1) : 
            preguntas_data[index_pregunta]?.cupe_valorprellenado.push(data);
        } else{
            preguntas_data[index_pregunta].cupe_valorprellenado = data
        }

        setPreguntas([...preguntas_data])
    }

    const addPregunta = () => {
        setPreguntas([...preguntas, {
            cupe_pregunta: null,
            cupe_descripcion:null,
            cupe_tipo:null,
            cupe_multiple:false,
            cupe_opciones: null,
            cupe_valorprellenado: null
        }])
    }

    const addOption = (index) => {
        let preguntas_data = preguntas;
        preguntas_data[index].cupe_opciones = [...preguntas_data[index].cupe_opciones, 
            {
                label: null,
                value: +preguntas_data[index].cupe_opciones[
                    preguntas_data[index].cupe_opciones.length-1
                    ].value+1
            }
        ]
        setPreguntas([...preguntas_data])
    }

    const deleteOption = (index, index_option, data) => {
        let preguntas_data = preguntas;
        preguntas_data[index]?.cupe_opciones.splice(index_option, 1);
        if(preguntas_data[index].cupe_multiple){
            if(preguntas_data[index]?.cupe_valorprellenado.includes(data)){
                preguntas_data[index]?.cupe_valorprellenado.splice(
                    preguntas_data[index]?.cupe_valorprellenado.indexOf(data), 1
                )
            }
        }else{
            if(preguntas_data[index].cupe_valorprellenado === data) {
            preguntas_data[index].cupe_valorprellenado = null }
        }
        
        setPreguntas([...preguntas_data])
    }

    const onSubmit = (event) => {
        event.preventDefault();
        let exist_null = preguntas.some((preg) => {
            if(preg.cupe_tipo === null || preg.stand_id === undefined) return true
            if( 
              (preg?.cupe_tipo === PreguntasEnum.MEDICIONES_SIN_ESTANDAR && 
              preg?.cupe_valorprellenado[2]?.value === null) ||
              (preg?.cupe_tipo === PreguntasEnum.MEDICIONES_SIN_ESTANDAR && 
                preg?.cupe_valorprellenado[3]?.value === null)
            ) return true;
            return false;
        })
        if(!exist_null){
            let preguntas_send = preguntas.map(preg => {
                return {
                    cupe_pregunta: preg?.cupe_pregunta,
                    cupe_descripcion:   preg?.cupe_descripcion === '' ? 
                                        null : preg?.cupe_descripcion,
                    cupe_tipo: parseInt(preg?.cupe_tipo),
                    cupe_multiple: preg?.cupe_multiple,
                    cupe_opciones:  preg?.cupe_opciones?.length > 0 ? 
                                    JSON.stringify(preg?.cupe_opciones) : null,
                    cupe_valorprellenado: (parseInt(preg?.cupe_tipo) === PreguntasEnum.OPCIONES && preg.cupe_multiple) || PreguntasEnum.RANGO ?
                                            JSON.stringify(preg?.cupe_valorprellenado) : preg?.cupe_valorprellenado,
                    stand_id: preg.stand_id
                }
            })
            let data = {
                cuestionario :{
                    cues_nombre : event.target?.cues_nombre?.value,
                    cues_descripcion :  event.target?.cues_descripcion?.value === '' ? null :
                                        event.target?.cues_descripcion?.value,
                    cues_observaciones :  event.target?.cues_observaciones?.value === '' ? null :
                                        event.target?.cues_observaciones?.value
                },
                preguntas : preguntas_send
            }
            crearCertificadoMutation({id:id_parte, data: data}, {
                onSuccess: (data) => {
                    handleClose()
                    if(data.success){
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Certificado creado con éxito",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }else{
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Fallo al crear certificado",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                },
                onError: (error) => {
                    handleClose();
                    console.log(error)
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Fallo al crear certificado",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        }
    }

    const handleClose = () => {
        setOpen(false)
        setPreguntas([
            {
                cupe_pregunta: null,
                cupe_descripcion:null,
                cupe_tipo:null,
                cupe_multiple:false,
                cupe_opciones: null
            }
        ])
    };



    return(
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
            backdrop: {
                timeout: 500,
            },
        }}
        >
            <Fade in={open}>
                <Box sx={style}>
                <form ref={formCreate} onSubmit={onSubmit}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sx={{p:0, textAlign:'center'}} >
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Nuevo Certificado
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{p:2, mt:2, textAlign:'start'}} >
                            <TextField
                                id="cues_nombre"
                                label="Nombre del Certificado"
                                name="cues_nombre"
                                size="small"
                                fullWidth
                                required
                                onChange={(e)=> {
                                    e.target.value.length > 0 ?  
                                    setErrorName(false) :
                                    setErrorName(true)
                                }}  
                                sx={{color: theme.palette.primary.input}}
                                error={errorName}
                                helperText={errorName ? 'Ingrese un valor' : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{p:2, mt:-2, textAlign:'start'}} >
                            <TextField
                                id="cues_descripcion"
                                label="Descripción del Certificado"
                                name="cues_descripcion"
                                size="small"
                                fullWidth
                                multiline
                                minRows={2}
                                sx={{color: theme.palette.primary.input}}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{p:2, mt:-2, textAlign:'start'}} >
                            <TextField
                                id="cues_observaciones"
                                label="Observaciones"
                                name="cues_observaciones"
                                size="small"
                                fullWidth
                                multiline
                                minRows={2}
                                sx={{color: theme.palette.primary.input}}
                            />
                        </Grid>
                        {preguntas.map((pregunta, index) => (
                            <Grid key={index} item xs={12} sx={{p:2, mt:0, textAlign:'start'}}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sx={{p:0, textAlign:'start'}} >
                                        <Typography id="transition-modal-title" variant="h8" component="h4">
                                            Pregunta {index + 1}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} sx={{p:0, mt:0, textAlign:'start'}}>
                                        <Grid key={`principal_data_${index}`} item xs={12} sx={{p:0, mt:0, textAlign:'start'}}>
                                            <Grid item xs={12} sx={{p:2, mt:0, textAlign:'start'}}>
                                            <TextField
                                                id={`pregunta_${index}`}
                                                name={`pregunta_${index}`}
                                                label="Pregunta"
                                                sx={{color: theme.palette.primary.input}}
                                                size="small"
                                                fullWidth
                                                required
                                                error={ preguntas[index]?.cupe_pregunta === null ||
                                                        preguntas[index]?.cupe_pregunta === ''}
                                                helperText={
                                                    preguntas[index]?.cupe_pregunta === null ||
                                                    preguntas[index]?.cupe_pregunta === '' ? 
                                                    'Ingrese un valor' : 
                                                    ''
                                                }
                                                onChange={
                                                    (e) =>
                                                    changeDataPregunta(index, "cupe_pregunta", e.target.value)
                                                }
                                            />
                                            </Grid>
                                            <Grid item xs={12} sx={{p:2, mt:-1, textAlign:'start'}} >
                                                <TextField
                                                    id={`descripcion_${index}`}
                                                    label="Descripción"
                                                    name={`descripcion_${index}`}
                                                    size="small"
                                                    fullWidth
                                                    multiline
                                                    minRows={2}
                                                    sx={{color: theme.palette.primary.input}}
                                                    onChange={
                                                        (e) =>
                                                        changeDataPregunta(index, "cupe_descripcion", e.target.value)
                                                    } 
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} sx={{p:2, mt:-1, textAlign:'start'}} >
                                        <FormControl error={preguntas[index]?.cupe_tipo === null}>
                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                Tipo de pregunta
                                            </FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={
                                                    (e) => 
                                                    changeDataPregunta(index, "cupe_tipo", e.target.value)
                                                }
                                            >
                                                <FormControlLabel value={1}  control={<Radio />} label="Texto" />
                                                <FormControlLabel value={2} sx={{mt:-2}} control={<Radio />} label="Númerico" />
                                                <FormControlLabel value={3} sx={{mt:-2}} control={<Radio />} label="Rango" />
                                                <FormControlLabel value={4} sx={{mt:-2}} control={<Radio />} label="Opciones" />
                                                <FormControlLabel value={5} sx={{mt:-2}} control={<Radio />} label="Mediciones" />
                                                <FormControlLabel value={6} sx={{mt:-1.0, '& .MuiTypography-root':{lineHeight:1}}} control={<Radio/>} label="Mediciones S/E" />
                                            </RadioGroup>
                                            <FormHelperText>
                                                {preguntas[index]?.cupe_tipo === null ? 'Seleccione un tipo' : ''}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    {
                                        parseInt(pregunta?.cupe_tipo) === PreguntasEnum.TEXTO && (
                                            <Grid item xs={12} sx={{p:2, mt:-1, textAlign:'start'}} >
                                                <TextField
                                                    id={`default_${index}`}
                                                    label="Valor predeterminado"
                                                    name={`default_${index}`}
                                                    size="small"
                                                    fullWidth
                                                    multiline
                                                    minRows={2}
                                                    sx={{color: theme.palette.primary.input}}
                                                    onChange={
                                                        (e) =>
                                                        changeDataPregunta(index, "cupe_valorprellenado", e.target.value)
                                                    } 
                                                />
                                            </Grid> 
                                        )
                                    }
                                    {
                                        parseInt(pregunta?.cupe_tipo) === PreguntasEnum.NUMERICO && (
                                            <Grid item xs={12} sx={{p:2, mt:-1, textAlign:'start'}} >
                                                <TextField
                                                    id={`default_${index}`}
                                                    label="Valor predeterminado"
                                                    name={`default_${index}`}
                                                    size="small"
                                                    type="text"
                                                    fullWidth
                                                    sx={{color: theme.palette.primary.input}}
                                                    onKeyDown={
                                                        (e) =>
                                                        {
                                                            validateNumber(e)
                                                        }
                                                    }
                                                    onKeyUp={(e)=>
                                                        {
                                                        changeDataPregunta(index, "cupe_valorprellenado", e.target.value)
                                                        }
                                                    }
                                                />
                                            </Grid> 
                                        )
                                    }
                                    {
                                        parseInt(pregunta?.cupe_tipo) === PreguntasEnum.RANGO && (
                                            <>
                                                <Grid item xs={4} sx={{p:2, mt:-3, textAlign:'start'}}>
                                                    <TextField
                                                    id="rango_min"
                                                    name="rango_min"
                                                    label="Mínimo predeterminado"
                                                    sx={{color: theme.palette.primary.input}}
                                                    size="small"
                                                    type="number"
                                                    fullWidth
                                                    onChange={
                                                        (e) =>
                                                        changeDataOptions(index, 0, e.target.value)
                                                    }
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sx={{p:2, mt:-3, textAlign:'start'}}>
                                                    <TextField
                                                        id="rango_max"
                                                        name="rango_max"
                                                        label="Máximo"
                                                        sx={{color: theme.palette.primary.input}}
                                                        size="small"
                                                        type="number"
                                                        fullWidth
                                                        onChange={
                                                            (e) =>
                                                            changeDataOptions(index, 1, e.target.value)
                                                        }
                                                    />
                                                </Grid>
                                            </>
                                        )
                                    }
                                    {
                                        parseInt(pregunta?.cupe_tipo) === PreguntasEnum.OPCIONES && (
                                            <>
                                                <Grid item xs={9} sx={{p:0, mt:0, textAlign:'start'}}>
                                                    <Grid key={`principal_data_${index}`} item xs={12} sx={{p:0, mt:0, textAlign:'start'}}>
                                                        {pregunta?.cupe_opciones.map((opcion, index_opcion)=> (
                                                            <Grid item xs={12} sx={{p:2, mt:-2, textAlign:'start', display:'flex',flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                                                                {
                                                                    pregunta?.cupe_multiple && (
                                                                        <Checkbox value={opcion?.value} onChange={()=>changeDefaultOptions(index, index_opcion, opcion.value)}/>
                                                                    )
                                                                }
                                                                {
                                                                    !pregunta?.cupe_multiple && (
                                                                        <Radio
                                                                            checked={pregunta.cupe_valorprellenado === opcion?.value}
                                                                            onChange={()=>changeDefaultOptions(index, index_opcion, opcion.value)}
                                                                            value={opcion.value}
                                                                        />
                                                                    )
                                                                }
                                                                <TextField
                                                                id={`opcion_${index_opcion}`}
                                                                name={`opcion_${index_opcion}`}
                                                                label={`Opción ${index_opcion+1}`}
                                                                sx={{color: theme.palette.primary.input}}
                                                                size="small"
                                                                fullWidth
                                                                required
                                                                value={pregunta?.cupe_opciones[index_opcion]?.label}
                                                                error={
                                                                    pregunta?.cupe_opciones[index_opcion]?.label === null || 
                                                                    pregunta?.cupe_opciones[index_opcion]?.label === ''
                                                                }
                                                                helperText={
                                                                    pregunta?.cupe_opciones[index_opcion]?.label === null ||
                                                                    pregunta?.cupe_opciones[index_opcion]?.label === '' ? 
                                                                    'Ingrese un valor' : 
                                                                    ''
                                                                }
                                                                onChange={
                                                                    (e) =>
                                                                    changeDataOptions(index, index_opcion, e.target.value)
                                                                }
                                                                />
                                                                {
                                                                    pregunta?.cupe_opciones.length > 2 && (
                                                                        <IconButton 
                                                                            size="large" 
                                                                            sx={{ml:1}}
                                                                            onClick={()=>deleteOption(index, index_opcion, opcion.value)}>
                                                                            <Delete sx={{ fontSize: 25 }}/>
                                                                        </IconButton>
                                                                    )
                                                                }
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} sx={{p:2, mt:-2, textAlign:'start'}}>
                                                    <FormControl error={preguntas[index]?.cupe_multiple === null}>
                                                        <FormLabel id="tipo_opciones">
                                                            Tipo de opciones
                                                        </FormLabel>
                                                        <RadioGroup
                                                            aria-labelledby="tipo_opciones"
                                                            name="tipo_opciones"
                                                            onChange={
                                                                (e) => 
                                                                changeDataPregunta(index, "cupe_multiple", e.target.value === 'true' ? true : false)
                                                            }
                                                            defaultValue={preguntas[index]?.cupe_multiple.toString()}
                                                        >
                                                            <FormControlLabel value='false'  control={<Radio />} label="Unica" />
                                                            <FormControlLabel value='true' sx={{mt:-2}} control={<Radio />} label="Multiple" />
                                                        </RadioGroup>
                                                        <FormHelperText>
                                                            {preguntas[index]?.cupe_tipo === null ? 'Seleccione un tipo de opción' : ''}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={3} sx={{p:2, mt:-2, textAlign:'start'}}>
                                                    <Button variant="contained" 
                                                        className="btn_system" 
                                                        onClick={()=>addOption(index)}
                                                    >
                                                        Añadir opcion
                                                    </Button>
                                                </Grid>
                                            </>
                                        )
                                    }
                                    {
                                        parseInt(pregunta?.cupe_tipo) === PreguntasEnum.MEDICIONES && (
                                            <>
                                                <Grid item xs={4} sx={{p:2, mt:-3, textAlign:'start'}}>
                                                    <TextField
                                                    id="rango_min"
                                                    name="rango_min"
                                                    label="Mínimo predeterminado"
                                                    sx={{color: theme.palette.primary.input}}
                                                    size="small"
                                                    type="number"
                                                    fullWidth
                                                    onChange={
                                                        (e) =>
                                                        changeDataOptions(index, 0, e.target.value)
                                                    }
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sx={{p:2, mt:-3, textAlign:'start'}}>
                                                    <TextField
                                                        id="rango_max"
                                                        name="rango_max"
                                                        label="Máximo predeterminado"
                                                        sx={{color: theme.palette.primary.input}}
                                                        size="small"
                                                        type="number"
                                                        fullWidth
                                                        onChange={
                                                            (e) =>
                                                            changeDataOptions(index, 1, e.target.value)
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={8} sx={{p:2, mt:-1, textAlign:'start'}}>
                                                    <FormControl fullWidth size="small" error={pregunta?.stand_id === null || pregunta.stand_id === undefined}>
                                                        <FormLabel id="standar-label">Estándar</FormLabel>
                                                        <Select
                                                            labelId="standar-label"
                                                            id="stand_id"
                                                            name="stand_id"
                                                            required
                                                            value={pregunta?.stand_id}
                                                            onChange={
                                                                (e) =>
                                                                changeDataPregunta(index, "stand_id", e.target.value)
                                                            }
                                                        >   
                                                            {standars?.map(st => (
                                                                <MenuItem value={st?.value}>{st?.label}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        {(pregunta?.stand_id === null || pregunta.stand_id === undefined)  && <FormHelperText sx={{ml:0}}>Seleccione un estándar</FormHelperText>}
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        )
                                    }
                                    {
                                        parseInt(pregunta?.cupe_tipo) === PreguntasEnum.MEDICIONES_SIN_ESTANDAR && (
                                            <Grid item xs={12} sx={{p:0, textAlign:'start'}}>
                                                <Grid container spacing={0} sx={{mb:1.5}}>
                                                  <Grid item xs={4} sx={{p:2, mt:-3, textAlign:'start'}}>
                                                      <TextField
                                                      id="mediciones"
                                                      name="mediciones"
                                                      label="N° Mediciones"
                                                      sx={{color: theme.palette.primary.input}}
                                                      size="small"
                                                      type="number"
                                                      fullWidth
                                                      required
                                                      error={preguntas[index]?.cupe_valorprellenado[2].value === null}
                                                      onChange={
                                                          (e) =>
                                                          changeDataOptions(index, 2, e.target.value)
                                                      }
                                                      />
                                                  </Grid>
                                                  <Grid item xs={4} sx={{p:2, mt:-3, textAlign:'start'}}>
                                                      <TextField
                                                          id="muestra"
                                                          name="muestra"
                                                          label="Tamaño muestra"
                                                          sx={{color: theme.palette.primary.input}}
                                                          size="small"
                                                          type="number"
                                                          fullWidth
                                                          required
                                                          error={preguntas[index]?.cupe_valorprellenado[3].value === null}
                                                          onChange={
                                                              (e) =>
                                                              changeDataOptions(index, 3, e.target.value)
                                                          }
                                                      />
                                                  </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                  <Grid item xs={4} sx={{p:2, mt:-3, textAlign:'start'}}>
                                                      <TextField
                                                      id="rango_min"
                                                      name="rango_min"
                                                      label="Mínimo predeterminado"
                                                      sx={{color: theme.palette.primary.input}}
                                                      size="small"
                                                      type="number"
                                                      fullWidth
                                                      onChange={
                                                          (e) =>
                                                          changeDataOptions(index, 0, e.target.value)
                                                      }
                                                      />
                                                  </Grid>
                                                  <Grid item xs={4} sx={{p:2, mt:-3, textAlign:'start'}}>
                                                      <TextField
                                                          id="rango_max"
                                                          name="rango_max"
                                                          label="Máximo predeterminado"
                                                          sx={{color: theme.palette.primary.input}}
                                                          size="small"
                                                          type="number"
                                                          fullWidth
                                                          onChange={
                                                              (e) =>
                                                              changeDataOptions(index, 1, e.target.value)
                                                          }
                                                      />
                                                  </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item xs={12} sx={{p:2, mt:-2, textAlign:'start'}} >
                            <Button variant="contained" 
                                className="btn_system" 
                                onClick={addPregunta}
                            >
                                    Nueva Pregunta
                            </Button>
                        </Grid>
                        <Grid item xs={12} sx={{p:2, mt:-2, textAlign:'start'}} >
                            <Button variant="contained" 
                                className="btn_system" 
                                type="submit"
                            >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                </Box>
            </Fade>
        </Modal>
    )

}

export default ModalCrearEncuesta;