import { Button, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TablaGeneral from "./components/tablaGeneral";
import CrearAlmacen from "./components/crearAlmacen";
import { GetAllAlmacenes } from "../../../hooks/getAllAlmacenes";
import { useDispatch } from "react-redux";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";

const IndexAlmacenes = () => {

    const dispatch = useDispatch();
    const {getAllAlmacenesMutation} = GetAllAlmacenes();
    const [openNuevo, setOpenNuevo] = useState(false);
    const [sample, setSample] = useState([]);
    const [filtro, setFiltro] = useState('');

    const get_data_tabla = () => {
        getAllAlmacenesMutation({page:1, pageSize:5},{
            onSuccess: (data) => {
                setSample(data)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        dispatch(
            HEADER_DATA_UPDATE({nombreData: null})
        )
        get_data_tabla();
    }, []);

    const almacenesFiltrados = sample.filter((almacen) => {
        return (
            almacen?.alma_nombre?.toLowerCase()?.includes(filtro?.toLowerCase())
        );
    });

    const handleInputChange = (event) => {
        setFiltro(event.target.value);
    };

    return(
        <>
            <Grid container spacing={0} sx={{height:'10vh'}}>
                <Grid item xs={3} sm={3} md={3} lg={2} xl={2} sx={{padding:'10px 30px'}} className="center_item">
                    <Button 
                    variant="contained" 
                    className="btn_system" 
                    onClick={() => setOpenNuevo(true)}>
                        Crear
                    </Button>
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={0}sx={{height:'70vh'}} >
                <Grid item xs={12} sx={{padding:'10px 20px', height:'100%'}} className="center_item">
                    <TablaGeneral   key={'table_gen_alm'} sample={almacenesFiltrados} filtro={filtro} handleInputChange={handleInputChange}/>
                </Grid>
            </Grid>
            <CrearAlmacen  key={'modal_nuevo_alm'} open={openNuevo} setOpen={setOpenNuevo}/>
        </>
    )
}

export default IndexAlmacenes;