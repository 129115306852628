import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    token: null,
    permissions: null
};

export const AuthReducer = createSlice({
    name: "auth_data",
    initialState,
    reducers: {
        AUTH_LOGIN: (state, action) => {
            const {user, token, permissions} = action.payload;
            state.user = user;
            state.token = token;
            state.permissions = permissions;
        },
        AUTH_LOGOUT: (state, action) => {
            state.user = null;
            state.token = null;
            state.permissions = null;
        }
    }
})

export const {AUTH_LOGIN, AUTH_LOGOUT} = AuthReducer.actions;
export default AuthReducer.reducer