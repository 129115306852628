import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { NoAccess } from "../views/errors/no_access";
import { createSelector } from "reselect";

const selectPermission = (state) => state.auth.permissions;
const selectPermissions = createSelector(
  [selectPermission],
  (permissions) => ({ permissions })
);

export const AccessRoutes = ({children, permission}) => {

    const { permissions } = useSelector(selectPermissions);
    let can_access = permissions?.some(objeto => objeto.permissionKey === permission);

    if(!permissions || permissions?.length === 0) {
        return <Navigate to={"/login"}/>
    }

    if(!can_access) {
        return <NoAccess  key={'no_access_view'}/>
    }

    return children ? children : <Outlet/>
}