import useApi, {AvailableRequestContentTypes} from "./useApiRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { formatUrlQuery } from "./utils";
import { useDispatch } from "react-redux";
import { HEADER_DATA_UPDATE } from "../redux/reducers/header.reducer";

export const useApiEmpresas = ({ params, ...options }) => {
    const api = useApi();
    return useQuery(
        ["empresas"],
        async () => {
            if (params) {
                const { data: resultData } = await api.get(`/empresas?${formatUrlQuery(params, true)}`);
                return resultData || [];
            } else {
                const { data } = await api.get(`/empresas`);
                return data || [];
            }
        },
        options
    );
};

export const useApiCorporativos = (options) => {
    const api = useApi();
    return useQuery(
        ["empresas", "corporativos"],
        async () => {
            const { data } = await api.get(`/empresas/tipo/2`);
            return data || [];
        },
        options
    );
};


export const useApiEmpresa = (options) => {
    const api = useApi();
    const dispatch = useDispatch();
    return useQuery(
        ["empresa"],
        async () => {
            const { data } = await api.get(`/empresas/${options?.id}`);
            dispatch(
                HEADER_DATA_UPDATE({nombreData: data?.emp_razonsocial})
            )
            return data;
        },
        options
    );
};

export const useEmpresasMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/empresas`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        if (params.mutationType === "update") {
            delete params.mutationType;
            let id = params.emp_id;
            delete params.emp_id;
            const { data } = await api.put(`/empresas/${id}`, params, { dataType: "json" });
            return data;
        }
    };

    const {
        mutate: mutateEmpresa,
        isLoading: isLoadingEmpresaMutation,
        error: errorEmpresaMutation,
        reset: resetEmpresaMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["empresas"], { exact: true });
            queryClient.invalidateQueries(["empresa"], { exact: true });
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateEmpresa,
        isLoadingEmpresaMutation,
        errorEmpresaMutation,
        resetEmpresaMutation,
    };
};


export const useUpdateEmpresa = () => {
    const api = useApi();

    const updateEmpresa = async (empresa) => {
        const response = await api.put(`/empresas/${empresa.emp_id}`, empresa, { dataType: AvailableRequestContentTypes.JSON });
        return response?.data;
    };

    const mutation = useMutation(updateEmpresa);

    return {
        updateEmpresa: mutation.mutate,
        isUpdatingEmpresa: mutation.isLoading,
        isUpdatedEmpresa: mutation.isSuccess,
        isUpdatingEmpresaError: mutation.isError,
    };
};


export const useGuardaDatosConstanciaEmpresa = () => {
    const api = useApi();

    const guardaDatosConstanciaEmpresa = async (datosConstancia) => {
        const response = await api.post(`/empresas/${datosConstancia.emp_id}/datos-constancia`, datosConstancia, { dataType: AvailableRequestContentTypes.JSON });
        return response?.data;
    };

    const mutation = useMutation(guardaDatosConstanciaEmpresa);

    return {
        saveDatosConstanciaEmpresa: mutation.mutate,
        isSavingGuardaDatosConstanciaEmpresa: mutation.isLoading,
        isSavedDatosConstanciaEmpresa: mutation.isSuccess,
        isSavingDatosConstanciaEmpresaError: mutation.isError,
    };
};

/************************* servicios para constancias fiscales de las empresas ***********************/

export const useGetPdfConstanciaEmpresaInfo = () => {
    const api = useApi();

    const getDatosConstancia = async (fd) => {
        const response = await api.post(
            `/empresas/extrae-datos-constancia`,
            fd,
            { dataType: AvailableRequestContentTypes.MULTIPART, hasFormData: true }
        );
        return response?.data;
    };

    const mutation = useMutation(getDatosConstancia);

    return {
        getConstanciaEmpresaInfo: mutation.mutate,
        isGettingConstanciaEmpresaInfo: mutation.isLoading,
        isGettedConstanciaEmpresaInfo: mutation.isSuccess,
        isGettingEmpresaInfoError: mutation.isError,
    };
};

// -------------- hooks de campos extra de la empresa ----------------

export const useApiEmpresaCamposExtra = (options) => {
    const api = useApi();
    return useQuery(
        ["empresas", "campos_extra"],
        async () => {
            if (options?.id) {
                const { data } = await api.get(`/empresas/${options?.id}/campos_extra`);
                return data;
            } else return []
        },
        options
    );
};

export const useEmpresaCamposExtraMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/empresas/${params.emp_id}/campos_extra`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        if (params.mutationType === "delete") {
            const { data } = await api.del(`/empresas/${params.emp_id}/campos_extra/${params.came_id}`);
            return data;
        }
    };

    const {
        mutate: mutateEmpresaCamposExtra,
        isLoading: isLoadingEmpresaCamposExtraMutation,
        error: errorEmpresaCamposExtraMutation,
        reset: resetEmpresaCamposExtraMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["empresas", "campos_extra"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateEmpresaCamposExtra,
        isLoadingEmpresaCamposExtraMutation,
        errorEmpresaCamposExtraMutation,
        resetEmpresaCamposExtraMutation,
    };
};



// -------------------- domicilios de la empresa ---------------------
export const useApiEmpresaDomicilios = (options) => {
    const api = useApi();
    return useQuery(
        ["empresa", "domicilios"],
        async () => {
            const { data } = await api.get(`/empresas/${options?.id}/domicilios`);
            return data;
        },
        options
    );
};

export const useEmpresaDomiciliosMutation = (options) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const mutate = async (params) => {
        if (params.mutationType === "create") {
            delete params.mutationType;
            const { data } = await api.post(`/empresas/${params.emp_id}/domicilios`, params, {
                dataType: "json",
                hasFormData: false,
                hasCustomUrl: false,
            });
            return data;
        }
        if (params.mutationType === "update") {
            delete params.mutationType;
            let id = params.dom_id;
            delete params.dom_id;
            const { data } = await api.put(`/empresas/${params?.emp_id}/domicilios/${id}`, params, { dataType: "json" });
            return data;
        }
        // if (params.mutationType === "delete") {
        //     const { data } = await api.del(`/empresas/${params.emp_id}/campos_extra/${params.came_id}`);
        //     return data;
        // }
    };

    const {
        mutate: mutateEmpresaDomicilios,
        isLoading: isLoadingEmpresaDomiciliosMutation,
        error: errorEmpresaDomiciliosMutation,
        reset: resetEmpresaDomiciliosMutation,
    } = useMutation(mutate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["empresa", "domicilios"]);
            if (options?.onSuccess) options.onSuccess();
        },
    });

    return {
        mutateEmpresaDomicilios,
        isLoadingEmpresaDomiciliosMutation,
        errorEmpresaDomiciliosMutation,
        resetEmpresaDomiciliosMutation,
    };
};


// -------------- hooks de contactos de la empresa ----------------


export const useAddEmpresaContacto = () => {
    const api = useApi();

    const addResource = async (addingParams) => {
        const response = await api.post(`/empresas/${addingParams.emp_id}/contactos`, addingParams, { dataType: "json" });
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        addEmpresaContacto: mutation.mutate,
        isAddingEmpresaContacto: mutation.isLoading,
        isAddedEmpresaContacto: mutation.isSuccess,
        isAddingEmpresaContactoError: mutation.isError,
    };
};

export const useDeleteEmpresaContacto = () => {
    const api = useApi();

    const deleteResource = async (deleteParams) => {
        const response = await api.del(`/empresas/${deleteParams.emp_id}/contactos/${deleteParams.usu_id}`);
        return response?.data;
    };

    const mutation = useMutation(deleteResource);

    return {
        deleteEmpresaContacto: mutation.mutate,
        isDeletingEmpresaContacto: mutation.isLoading,
        isDeletedEmpresaContacto: mutation.isSuccess,
        isDeletingEmpresaContactoError: mutation.isError,
    };
};

/*********************************** hooks de giros de la empresa ******************************************/

export const useApiEmpresaGiros = (options) => {
    const api = useApi();
    return useQuery(
        ["empresas", "giros"],
        async () => {
            const { data } = await api.get(`/empresas/${options?.id}/giros`);
            return data;
        },
        options
    );
};

export const useCrearGiroEmpresa = () => {
    const api = useApi();

    const addResource = async (addingParams) => {
        const response = await api.post(`/empresas/${addingParams.emp_id}/giros`, addingParams, { dataType: AvailableRequestContentTypes.JSON });
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        saveGiroEmpresa: mutation.mutate,
        isSavingGiroEmpresa: mutation.isLoading,
        isSavedGiroEmpresa: mutation.isSuccess,
        isSavingGiroEmpresaError: mutation.isError,
    };
};

export const useUpdateGiroEmpresa = () => {
    const api = useApi();

    const updateResource = async (updateParams) => {
        const response = await api.put(`/empresas/${updateParams.emp_id}/giros/${updateParams.giro_id}`, updateParams, { dataType: AvailableRequestContentTypes.JSON });
        return response?.data;
    };

    const mutation = useMutation(updateResource);

    return {
        updateGiroEmpresa: mutation.mutate,
        isUpdatingGiroEmpresa: mutation.isLoading,
        isUpdatedGiroEmpresa: mutation.isSuccess,
        isUpdatingGiroEmpresaError: mutation.isError,
    };
};

export const useEliminarGiroEmpresa = () => {
    const api = useApi();

    const deleteResource = async (deleteParams) => {
        const response = await api.del(`/empresas/${deleteParams.emp_id}/giros/${deleteParams.giro_id}`);
        return response?.data;
    };

    const mutation = useMutation(deleteResource);

    return {
        deleteGiroEmpresa: mutation.mutate,
        isDeletingGiroEmpresa: mutation.isLoading,
        isDeletedGiroEmpresa: mutation.isSuccess,
        isDeletingGiroEmpresaError: mutation.isError,
    };
};


/*********************************** hooks de regimenes fiscales de la empresa ******************************************/

export const useApiEmpresaRegimenes = (options) => {
    const api = useApi();
    return useQuery(
        ["empresas", "regimenes"],
        async () => {
            const { data } = await api.get(`/empresas/${options?.id}/regimenes`);
            return data;
        },
        options
    );
};

export const useCrearEmpresaregimen = () => {
    const api = useApi();

    const addResource = async (addingParams) => {
        const response = await api.post(`/empresas/${addingParams.emp_id}/regimenes-fiscales`, addingParams, { dataType: AvailableRequestContentTypes.JSON });
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        saveEmpresaRegimen: mutation.mutate,
        isSavingEmpresaRegimen: mutation.isLoading,
        isSavedEmpresaRegimen: mutation.isSuccess,
        isSavingEmpresaRegimenError: mutation.isError,
    };
};

export const useEliminarEmpresaregimen = () => {
    const api = useApi();

    const addResource = async (deletingParams) => {
        const response = await api.del(`/empresas/${deletingParams.emp_id}/regimenes-fiscales/${deletingParams.refi_id}`);
        return response?.data;
    };

    const mutation = useMutation(addResource);

    return {
        deleteEmpresaRegimen: mutation.mutate,
        isDeletingEmpresaRegimen: mutation.isLoading,
        isDeletedEmpresaRegimen: mutation.isSuccess,
        isDeletingEmpresaRegimenError: mutation.isError,
    };
};