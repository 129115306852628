import React, { useContext, useEffect } from "react";
import { useApiEmpresaRegimenes, useEliminarEmpresaregimen } from "../../../../hooks/empresas";
import { useModal } from "../../../../hooks/use/useModal";
import swal from 'sweetalert2'
import { Backdrop, Box, Fade, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ThemeContext } from "@emotion/react";
import { AddCircle, Delete } from "@mui/icons-material";
import { TableVirtuoso } from "react-virtuoso";
import CrearEmpresaRegimen from "./regimenesFiscales/crearEmpresaRegimen";

const EmpresaRegimenes = ({ empresa }) => {

    const style = {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center'
    };


    const theme = useContext(ThemeContext);
    const [modalCrearVisible, showCrear, closeCrear] = useModal();
    const { data: regimenes, isLoading: isLoadingRegimenes, refetch: refetchRegimenes } = useApiEmpresaRegimenes({ initialData: [], id: empresa?.emp_id })
    const { deleteEmpresaRegimen, isDeletingEmpresaRegimen } = useEliminarEmpresaregimen();

    const eliminarRegimen = (regimen) => {
        deleteEmpresaRegimen({
            emp_id: empresa.emp_id,
            refi_id: regimen.refi_id
        },
            {
                onSuccess: res => {
                    if (res.success) {
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Régimen asignado con éxito",
                            showConfirmButton: false,
                            timer: 1500
                        })
                        refetchRegimenes();
                    } else {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Fallo al agregar régimen",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                },
                onError: () => {
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Fallo al agregar régimen",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            }
        )
    }

    useEffect(() => {
        if (empresa?.emp_id) {
            refetchRegimenes();
        }
    }, [empresa])

    const columns = [
        {
            width: 50,
            label: 'Régimen fiscal',
            dataKey: 'regimen',
        },
        {
            width: 20,
            label: 'Clave SAT',
            dataKey: 'clave',
        },
        {
            width: 20,
            label: 'Acciones',
            dataKey: 'acciones',
        },
    ];

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    function fixedHeaderContent() {
        return (
            <TableRow sx={{height:'10px !important'}}>
                {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align="center"
                    style={{ width: column.width, height:'10px !important' }}
                    sx={{
                        display: 'table-cell',
                        backgroundColor: theme.palette.primary.main,
                        color:'white',
                        fontWeight:600,
                        height:'10px !important',
                        padding: '2px 15px'
                    }}
                >
                    {column.label}
                </TableCell>
                ))}
            </TableRow>
        );
    }

    function rowContent(_index, row) {
        return (
            <>
                <React.Fragment>
                    {columns.map((column) => (
                        <TableCell
                        key={column.dataKey}
                        align="center"
                        sx={{
                            display: 'table-cell !important',
                            fontWeight:500,
                            color: theme.palette.primary.input,
                            background: 'white',
                            verticalAlign: 'center',
                            padding:'8px 15px'
                        }}
                        >
                            {column.dataKey === 'acciones' ? (
                                <>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => eliminarRegimen(row)}
                                    >
                                        <Delete/>
                                    </IconButton>
                                </> 
                            ):column.dataKey === 'regimen' ? (
                                <>
                                    {row?.regimenes_fiscales?.refi_nombre}
                                </> 
                            ):column.dataKey === 'clave' ? (
                                <>
                                    {row?.regimenes_fiscales?.refi_clave}
                                </> 
                            ):(
                                (
                                    <>
                                        {null}
                                    </>
                                )
                            )}
                        </TableCell>
                    ))}
                </React.Fragment>
            </>
        );
    }

    return(
        <div style={{display:'flex', flexDirection:'column', width:'100%', textAlign:'center', padding:'0px 10px', marginBottom:8}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', paddingTop:5, justifyContent:'space-between'}}>
                <Typography style={{ fontSize: 18, fontWeight: "bold" }}>Régimenes fiscales</Typography>
                <IconButton size="small" sx={{right:0, justifySelf:'flex-end', marginRight:2}} onClick={showCrear}>
                    <AddCircle sx={{ fontSize: 25 }}/>
                </IconButton>
            </div>
            <Paper style={{ height: 200, width: '100%', marginTop:0 }} sx={{boxShadow:'none'}}>
                <TableVirtuoso
                    data={regimenes}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={fixedHeaderContent}
                    itemContent={rowContent}
                    style={{
                        boxShadow:'none',
                        border:'1px solid #f2f3f3',
                        width: '100%'
                    }}
                />
            </Paper>

                        
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={modalCrearVisible}
                onClose={closeCrear}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                >
                    <Fade in={modalCrearVisible}>
                        <Box sx={style}>
                            <div style={{width: '100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                                <CrearEmpresaRegimen  
                                key={'crear_er'} 
                                closeModal={closeCrear} 
                                empresa={empresa} 
                                empresaRegimenes={regimenes}/>
                            </div>
                        </Box>
                    </Fade>
            </Modal>
        </div>
    )

}

export default EmpresaRegimenes;