const validateEmail = (value) => {

    const emailRegex = /^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/;

    if (emailRegex.test(value)) {
        return true
    }else{
        return false
    }
}

export default validateEmail;