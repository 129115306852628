import React, { useContext, useEffect, useState } from "react";
import { useEmpresasMutation } from "../../../../hooks/empresas";
import swal from 'sweetalert2'
import { IconButton, TextField, Typography } from "@mui/material";
import { Save } from "@mui/icons-material";
import { ThemeContext } from "@emotion/react";

const EmpresaObservaciones = ({ empresa }) => {

    const theme = useContext(ThemeContext);
    const [observacion, setObservacion] = useState(null)
    const { mutateEmpresa, isLoadingEmpresaMutation, resetEmpresaMutation } = useEmpresasMutation();

    const handleClickGuardarObervaciones = () => {
        if(observacion !== null && observacion !== ''){
            mutateEmpresa(
                { emp_observaciones: observacion, emp_id: empresa?.emp_id, mutationType: "update" },
                {
                    onSuccess: (res) => {
                        if (res?.success) {
                            resetEmpresaMutation();
                            swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "Observación creada con éxito",
                                showConfirmButton: false,
                                timer: 1500
                            })
                        } else {
                            swal.fire({
                                position: "top-end",
                                icon: "error",
                                title: "Hubo un error al procesar la observación",
                                text: "Intenta de nuevo",
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    },
                    onError: () => {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Hubo un error al procesar la observación",
                            text: "Intenta de nuevo",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    },
                }
            );
        }
    }

    useEffect(() => {
        setObservacion(empresa?.emp_observaciones);
    }, [empresa])

    return(
        <div style={{display:'flex', flexDirection:'column', width:'100%', textAlign:'center', padding:'0px 10px', marginBottom:8}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', paddingTop:5}}>
                <Typography style={{ fontSize: 18, fontWeight: "bold" }}>Observaciones</Typography>
                <IconButton size="small" sx={{right:0, position:'absolute', marginRight:2}} onClick={handleClickGuardarObervaciones}>
                    <Save sx={{ fontSize: 25 }}/>
                </IconButton>
            </div>
            <div style={{width:'100%'}}>
                <TextField
                    id="observacion"
                    size="small"
                    variant="standard"
                    fullWidth
                    required
                    multiline
                    rows={3}
                    defaultValue={observacion}
                    onChange={(e)=> {
                        setObservacion(e.target.value)
                    }}  
                    sx={{color: theme.palette.primary.input, mt:1,ml:1}}
                    />
            </div>
        </div>
    )
}

export default EmpresaObservaciones;