import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableContainer,
  Tooltip,
  Typography,
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { ThemeContext } from "@emotion/react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { GetExistenciaIndividualCargado } from "../../../hooks/getExistenciasIndividuales";

const TableProductsPanel = ({
  sample,
  almacen
}) => {
  const theme = useContext(ThemeContext);

  const rows = sample;

  const VerSerial = ({data}) => {
    const seriales = data;

    const Title = () => {

      return (
        <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', flexDirection:'column'}}>
          {seriales.map(item => (
            <span>{item}</span>
          ))}
        </div>
      )
    }

    return(
      <Tooltip title={<Title />} placement="top" arrow>
        <Button variant="text">Ver Seriales</Button>
      </Tooltip>
    )
  }

  function Row(props) {
    const { row } = props;
    const {getExistenciaIndividualCargadoMutation} = GetExistenciaIndividualCargado();
    const [open, setOpen] = useState(false);
    const [movimientos, setMovimientos] = useState([]);

    const get_movimientos = (id_parte) => {
      getExistenciaIndividualCargadoMutation({almacen: almacen, parte: id_parte},{
        onSuccess: (data) => {
          for(let mov of data){
            let seriales = new Set(mov?.moin_serial);
            mov.moin_serial = [...seriales]
          }
          setMovimientos(data)
        },
        onError: (error) => {
          console.log(error)
        }
      })
    }

    const open_subtabla = (id_parte) => {
      if (open) {
        setOpen(!open);
        setMovimientos([]);
      } else {
        get_movimientos(id_parte);
        setOpen(!open);
      }
    };


    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell sx={{p:1}}>
            <IconButton
              aria-label="expand row"
              size="small"
              style={{width:25}}
              onClick={() => open_subtabla(row?.part_id)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
          >
            {row.codigo}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
          >
            {row.nombre}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
          >
            {row.existencia_total}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, marginBottom: 10 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Desgloce de contenedores
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ color: theme.palette.primary.main, fontSize: 16 }}
                      >
                        Serial
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: theme.palette.primary.main, fontSize: 16 }}
                      >
                        Etiqueta
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: theme.palette.primary.main, fontSize: 16 }}
                      >
                        Cantidad
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {movimientos.map((mov) => (
                      <TableRow key={mov?.moin_id}>
                        <TableCell align="center">{mov?.moin_serial.length === 1 ? mov?.moin_serial[0]: <VerSerial  key={`serial_${mov?.moin_id}`} data={mov?.moin_serial}/>}</TableCell>
                        <TableCell align="center">
                          {mov?.etiq_codigo}
                        </TableCell>
                        <TableCell align="center">
                          {mov?.moin_cantidad}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <Paper
      style={{width: "100%", maxHeight: 400, overflowY: 'scroll'}}
      sx={{ boxShadow: "none" }}
    >
      <Typography variant="h5" sx={{fontWeight:500}} gutterBottom component="div">
        Cargados
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead
            sx={{
              display: "table-head",
              backgroundColor: theme.palette.primary.main,
              fontWeight: 600,
            }}
          >
            <TableRow>
              <TableCell style={{width:25}}/>
              <TableCell align="center" sx={{ color: "white" }}>
                Código
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Nombre
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Unidades
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableProductsPanel;