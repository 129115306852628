import React, { useContext, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useModal } from "../../../hooks/use/useModal";
import { useApiEmpresa, useGetPdfConstanciaEmpresaInfo, useGuardaDatosConstanciaEmpresa } from "../../../hooks/empresas";
import { Spin } from "antd";
import { Backdrop, Box, Button, Fade, Grid, Modal, Typography } from "@mui/material";
import VisorConstanciaFiscal from "./components/VisorConstanciaFiscal";
import FileDropper from "../../../components/FileDropper";
import Elevation from "../../../components/Elevation";
import EmpresaCard from "./components/empresaCard";
import TablaDomicilios from "./components/domicilios";
import EmpresasContactos from "./components/empresas_contactos";
import EmpresaCamposExtra from "./components/empresasCamposExtras";
import EmpresasGiros from "./components/empresasGiros";
import EmpresaRegimenes from "./components/empresaRegimenes";
import EmpresaObservaciones from "./components/empresaObservaciones";
import { GetEntrada } from "../../../hooks/getEntrada";
import { CrearOrden } from "../../../hooks/crearOrden";
import { GetOrdenes } from "../../../hooks/getOrdenes";
import { ActualizarOrden } from "../../../hooks/updateOrden";
import { PaperClipOutlined } from "@ant-design/icons";
import swal from 'sweetalert2'
import { ThemeContext } from "@emotion/react";
import TablaOrdenes from "./components/ordenes_compra/tabla_ordenes";
import DataOrden from "./components/ordenes_compra/dataDoc";
import TablaMovimientosOrden from "./components/ordenes_compra/tabla_mov";
import DataMovOrd from "./components/ordenes_compra/data_mov";
import moment from "moment";
import { AccionMovInvBackEnum } from "../../../@enums/action_mov_inv_back";
import { ActualizarEstatusOrden } from "../../../hooks/updateStatusOrden";
import { EstatusDocumentoEnum } from "../../../@enums/status_documento.enum";
import { TipoOrdenEnum } from "../../../@enums/tipo_orden_compra";


const Empresa = ({ emp_id }) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center',
        minWidth: 600,
        maxWidth: 1000,
        height: 500
    };
    const { id } = useParams();
    const formData = useRef(null);
    const queryClient = useQueryClient();
    const theme = useContext(ThemeContext)
    const {getOrdenesMutation} = GetOrdenes();
    const {crearOrdenMutation} = CrearOrden();
    const {getEntradaMutation} = GetEntrada();
    const {actualizarEstatusOrdenMutation} = ActualizarEstatusOrden();
    const {actualizarOrdenMutation} = ActualizarOrden();
    const [cargarConstanciaVisible, showCargarConstancia, closeCargarConstancia] = useModal();
    const [constanciaInfo, setConstanciaInfo] = useState(null);
    const { getConstanciaEmpresaInfo, isGettingConstanciaEmpresaInfo } = useGetPdfConstanciaEmpresaInfo();
    const { saveDatosConstanciaEmpresa, isSavingDatosConstanciaEmpresa } = useGuardaDatosConstanciaEmpresa();
    const [seccionId, setSeccionId] = useState(1);
    const [sampleOrd, setSampleOrd] = useState([]);
    const [movimientos, setMovimientos] = useState([]);
    const [movSend, setMovSend] = useState([]);
    const [hiddenMov, setHiddenMovs] = useState(true);
    const [isUpdate, setIsUpdate] = useState(false);
    const [errMoneda, setErrMoneda] = useState(false);
    const [errTipo, setErrTipo] = useState(false);
    const [tipo, setTipo] = useState(null);
    const [openNewMov, setOpenNewMov] = useState(false);
    const [itemSelect, setItemSelect] = useState(null);
    const [dataOrden, setDataOrden] = useState(null);

    const { data: empresa, refetch: refetchEmpresa, isFetched } = useApiEmpresa({
        id: emp_id || id,
        initialData: null,
    });

    const handleChangeConstancia = (files) => {
        if (files.length > 0) {
            let fd = new FormData();
            fd.append("files[]", files[0], files[0].fileName);
            getConstanciaEmpresaInfo(fd, {
                onSuccess: res => {
                    if (res.success) {
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: res.mensaje,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        setConstanciaInfo(res.data);
                    } else {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Hubo un error, intente nuevamente",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                },
                onError: () => {
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Hubo un error, intente nuevamente",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        }
    }

    const handleCloseDatosConstancia = () => {
        closeCargarConstancia();
        setConstanciaInfo(null);
    }

    const handleSaveDatosConstancia = () => {
        constanciaInfo.emp_id = empresa?.emp_id;
        saveDatosConstanciaEmpresa(constanciaInfo, {
            onSuccess: res => {
                if (res.success) {
                    swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.mensaje,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    queryClient.invalidateQueries(["empresa"]);
                    queryClient.resetQueries(["empresa", "domicilios"], { exact: true })
                    handleCloseDatosConstancia();
                    refetchEmpresa()
                } else {
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Fallo al cargar la constancia",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            }, onError: () => {
                swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Fallo al cargar la constancia",
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        });
    }

    const get_ordenes = () => {
        getOrdenesMutation(id,{
            onSuccess: (data) => {
                let orders = data.map(item => {
                    return {
                        docu_id:  item?.docu_id,
                        folio: item?.docu_folio,
                        fecha: moment(item?.docu_fecha).format('yyyy-MM-DD'),
                        vigencia: item?.docu_fechavencimiento !== null ? 
                                    moment(item?.docu_fechavencimiento).format('yyyy-MM-DD') : '',
                        tipo: parseInt(JSON.parse(item?.docu_extradata)?.tipo_orden),
                        estatus: item?.docu_estatus
                    }   
                })
                setSampleOrd(orders)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        if (id) {
            refetchEmpresa()
            get_ordenes();
        }
    }, [id])

    const get_orden = (id) => {
        getEntradaMutation(id, {
            onSuccess: (data) => {
                let ord = {
                    docu_id: data?.docu_id,
                    docu_fecha: data?.docu_fecha,
                    docu_folio: data?.docu_folio,
                    docu_estatus: data?.docu_estatus,
                    referencia: JSON.parse(data?.docu_extradata)?.referencia_cliente,
                    mone_id: data?.mone_id,
                    credito: JSON.parse(data?.docu_extradata)?.tipo_credito,
                    tipo: parseInt(JSON.parse(data?.docu_extradata)?.tipo_orden),
                    docu_fechavencimiento: data?.docu_fechavencimiento
                }
                if(
                    parseInt(JSON.parse(data?.docu_extradata)?.tipo_orden) === 1 && 
                    data?.docu_estatus === EstatusDocumentoEnum.SIN_ESTATUS
                ){
                    let date = moment(data?.docu_fechavencimiento);
                    let today = moment();
                    if(!date.isAfter(today)){
                        actualizarEstatusOrdenMutation({id: data?.docu_id, estatus: EstatusDocumentoEnum.CERRADO},{
                            onSuccess: (data) => {
                                swal.fire({
                                    position: "top-end",
                                    icon: "info",
                                    title: "Entrada cerrada por vigencia",
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            },
                            onError: (error) => {
                                
                            }
                        })
                    }
                    ord.docu_estatus = EstatusDocumentoEnum.CERRADO
                }
                let mov = data?.documentos_movimientos.map(item => {
                    return {
                        id: item?.domo_id,
                        parte: item?.partes?.part_nombre,
                        domo_cantidad: item?.domo_cantidad,
                        part_id: item?.part_id,
                        domo_importeunitario: item?.domo_importeunitario,
                        unidad: item?.unidades_medida?.unme_nombre,
                        unme_id: item?.unme_id
                    }
                })
                let mov_send = data?.documentos_movimientos.map(item => {
                    return {
                        domo_id: item?.domo_id,
                        domo_cantidad: item?.domo_cantidad,
                        part_id: item?.part_id,
                        domo_importeunitario: item?.domo_importeunitario,
                        unme_id: item?.unme_id,
                        action: AccionMovInvBackEnum.ACTUALIZAR,
                    }
                })
                setMovimientos([...mov])
                setMovSend([...mov_send])
                setDataOrden(ord);
                setHiddenMovs(false);
                setIsUpdate(true);
                setTipo(parseInt(JSON.parse(data?.docu_extradata)?.tipo_orden))
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    const openNewOrden = () => {
        setHiddenMovs(false);
        setIsUpdate(false);
    }

    const onCloseOrden = () => {
        setHiddenMovs(true);
        formData.current.reset();
        get_ordenes();
        setErrTipo(false);
        setErrMoneda(false);
        setTipo(null);
        setIsUpdate(false);
        setMovSend([]);
        setMovimientos([]);
        setDataOrden(null)
    }

    const validateForm = () => {
        setErrTipo(false);
        setErrMoneda(false)
        if(tipo === null || formData.current.mone_id.value === ''){
            if(tipo === null) setErrTipo(true);
            if(formData.current.mone_id.value === '') setErrMoneda(true);
            return false;
        }else if(tipo === 1 && formData.current.docu_fechavencimiento.value === ''){
            return false;
        }else{
            return true
        }
    }

    const openData = (id) => {
        let itemSelect =  movimientos.find(obj => obj.id === id);
        setItemSelect(itemSelect);
        setOpenNewMov(true);
    }

    useEffect(() => {
        if(tipo !== TipoOrdenEnum.CERRADA_CANTIDAD){
            let mov = movimientos;
            let mov_send = movSend;
            mov.map(item => item.domo_cantidad = 0);
            mov_send.map(item => item.domo_cantidad = 0);
            setMovimientos(mov);
            setMovSend(mov_send);
        }
    }, [tipo])


    const saveOrden = () => {
        if(dataOrden !== null){
            actualizarOrdenMutation({id:dataOrden.docu_id, data:movSend}, {
                onSuccess: (data) => {
                    swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Orden actualizada con éxito",
                        showConfirmButton: false,
                        timer: 1500
                    })
                    onCloseOrden()
                },
                onError: (error) => {
                    swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Hubo un error al actualizar",
                        text: "Intenta de nuevo",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        }else{
            if(validateForm()){
                let data_orden = {
                    dataDocumento: {
                        docu_fecha: formData.current.docu_fecha.value,
                        docu_fechavencimiento: formData?.current?.docu_fechavencimiento?.value !== '' &&  formData?.current?.docu_fechavencimiento?.value !== undefined?
                                                formData?.current?.docu_fechavencimiento?.value : null,
                        docu_folio: parseInt(formData.current.docu_folio.value),
                        mone_id: parseInt(formData.current.mone_id.value),
                        emp_id: parseInt(id),
                        docu_extradata: JSON.stringify(
                                            {
                                                referencia_cliente: formData.current.referencia.value,
                                                tipo_credito: formData.current.credito.value,
                                                tipo_orden: formData.current.tipo.value
                                            })
                        
                    },
                    arrayDocMov: movSend
                }
                crearOrdenMutation(data_orden, {
                    onSuccess: (data) => {
                        if(data.success){
                            swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: data.mensaje,
                                showConfirmButton: false,
                                timer: 1500
                            })
                            onCloseOrden()
                        }else{
                            swal.fire({
                                position: "top-end",
                                icon: "error",
                                title: "Hubo un error al actualizar",
                                text: "Intenta de nuevo",
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                        onCloseOrden()
                    },
                    onError: (error) => {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Hubo un error al crear",
                            text: "Intenta de nuevo",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                })
            }
        }
    }
    const changeStatus = (id_estatus) => {
        swal.fire({
            title: "¿Esta seguro que desea realizar esta accion?",
            text: "Una vez hecho no se podra revertir la accion",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, cambiar"
        }).then((result) => {
            if (result.isConfirmed) {
                actualizarEstatusOrdenMutation({id: dataOrden?.docu_id, estatus: id_estatus},{
                    onSuccess: (data) => {
                        swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Estatus de orden actualizada con éxito",
                            showConfirmButton: false,
                            timer: 1500
                        })
                        onCloseOrden()
                    },
                    onError: (error) => {
                        swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Hubo un error al actualizar",
                            text: "Intenta de nuevo",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                })
            }
        });
        
    }

    const btn_seccion = [
        {
            id: 1,
            name: 'Generales',
            component: (
                <>
                    <Button
                        variant="contained"
                        style={{ display: "flex", alignItems: "center", height:30, marginBottom:10}}
                        onClick={showCargarConstancia}
                    >
                        <PaperClipOutlined style={{ marginRight:2 }} />
                        Cargar constancia
                    </Button>
                    <Elevation style={{marginBottom:5}}>
                        <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                            <TablaDomicilios key={'dom_emp'} sample={empresa?.domicilios} clie_id={empresa?.emp_id} get_data={refetchEmpresa}/>
                        </div>
                    </Elevation>
                    <Elevation style={{marginBottom:5}}>
                        <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                            <EmpresasContactos key={'table_cont'} sample={empresa?.contactos} contactos_empresa={empresa?.contactos_empresas} refecth={refetchEmpresa}/>
                        </div>
                    </Elevation>
                    <Elevation style={{marginBottom:5}}>
                        <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                            <EmpresaCamposExtra emp_id={empresa?.emp_id} />
                        </div>
                    </Elevation>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{display:'flex', flexDirection:'column', paddingRight:1}}>
                            <Elevation style={{marginBottom:5}}>
                                <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                                    <EmpresasGiros  key={'emp_gir'} empresa={empresa}/>
                                </div>
                            </Elevation>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{display:'flex', flexDirection:'column', paddingLeft:1}}>
                            <Elevation style={{marginBottom:5}}>
                                <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                                    <EmpresaRegimenes  key={'emp_reg'} empresa={empresa}/>
                                </div>
                            </Elevation>
                        </Grid>
                    </Grid>
                    <Elevation style={{ flex: 0.4 }}>
                        <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                            <EmpresaObservaciones empresa={empresa} />
                        </div>
                    </Elevation>
                </>
            )
        },
        {
            id: 2,
            name: 'Ordenes de Compra',
            component: (
                <>
                    {hiddenMov && 
                    <>
                        <div style={{width: '100%', display:'flex', justifyContent:'flex-end', padding:10}}>
                            <Button variant="contained" onClick={openNewOrden} >
                                + Nueva
                            </Button>
                        </div>
                        <Elevation>
                            <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                                <TablaOrdenes
                                key={'table_ord_clie'}
                                sample={sampleOrd}
                                getOrden={get_orden}/>
                            </div>
                        </Elevation>
                    </>
                    }
                    {
                        !hiddenMov &&
                        <Elevation style={{marginBottom:5}}>
                            <form ref={formData} id="form_dom"  style={{width: '100%'}}>
                                <div className="e-panel-container" style={{ overflowY: "auto", width:'100% !important', overflowX:'scroll', padding:2 }}>
                                    <DataOrden 
                                    key={'data_ord_head'}
                                    isUpdate={isUpdate}
                                    errMoneda={errMoneda}
                                    errTipo={errTipo}
                                    setErrTipo={setErrTipo}
                                    setTipo={setTipo}
                                    onClose={onCloseOrden}
                                    saveOrden={saveOrden}
                                    tipo={tipo}
                                    data={dataOrden}
                                    changeStatus={changeStatus}
                                    />
                                </div>
                                <div className="e-panel-container" style={{ overflowY: "auto", width:'100% !important', overflowX:'scroll', padding:2 }}>
                                    {
                                        openNewMov ? (
                                            <DataMovOrd
                                            key={'data_mov_ord'}
                                            form={formData}
                                            idClient={id}
                                            isUpdate={isUpdate}
                                            movimientos={movimientos}
                                            setMovimientos={setMovimientos}
                                            movSend={movSend}
                                            setMovSend={setMovSend}
                                            setOpenNew={setOpenNewMov}
                                            item={itemSelect}
                                            setItem={setItemSelect}
                                            tipo={tipo}
                                            />
                                        ) : (
                                            <>
                                                <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                    <Typography id="transition-modal-title" variant="h6" component="h2">
                                                        Datos Movimientos
                                                    </Typography>
                                                    <Button 
                                                    variant="contained" 
                                                    className="btn_system" 
                                                    onClick={()=>setOpenNewMov(true)} 
                                                    disabled={
                                                        dataOrden?.docu_estatus === EstatusDocumentoEnum.CANCELADO ||
                                                        dataOrden?.docu_estatus === EstatusDocumentoEnum.CERRADO ||
                                                        tipo === null
                                                    }
                                                    sx={{width:150}}>
                                                        + Nuevo
                                                    </Button>
                                                </Grid>
                                                <TablaMovimientosOrden
                                                key={'table_mov_ord'}
                                                isUpdate={isUpdate}
                                                movimientos={movimientos}
                                                setMovimientos={setMovSend}
                                                movSend={movSend}
                                                setMovSend={setMovSend}
                                                openData={openData}
                                                sample={movimientos}
                                                isCancel={
                                                    dataOrden?.docu_estatus === EstatusDocumentoEnum.CANCELADO ||
                                                    dataOrden?.docu_estatus === EstatusDocumentoEnum.CERRADO
                                                }
                                                setTipo={setTipo}
                                                tipo={tipo}
                                                />
                                            </>
                                        ) 
                                    }
                                </div>
                            </form>
                        </Elevation>
                    }
                    
                </>
            )
        },
    ]

    const changeSection = (id) => {
        setSeccionId(id)
        get_ordenes();
    }


    return(
        <>
            <Grid container spacing={0}>
                <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={cargarConstanciaVisible}
                onClose={handleCloseDatosConstancia}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                >
                    <Fade in={cargarConstanciaVisible}>
                        <Box sx={style}>
                            <div style={{overflowY:'scroll', width:'100%',}}>
                                <Spin spinning={isGettingConstanciaEmpresaInfo} >
                                    {
                                        constanciaInfo && <VisorConstanciaFiscal constanciaInfo={constanciaInfo} onSaveDatosConstancia={handleSaveDatosConstancia} />
                                    }
                                    {
                                        !constanciaInfo &&
                                        <div style={{width:'100%'}}>
                                            <FileDropper
                                                onChange={handleChangeConstancia}
                                                limit={1}
                                                allowedExtensions={["pdf"]}
                                            />
                                        </div>
                                    }
                                </Spin>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{display:'flex', flexDirection:'column', p:2}}>
                    <Elevation  >
                        <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                            <EmpresaCard key={emp_id} empresa={empresa} refetch={refetchEmpresa} />
                        </div>
                    </Elevation>
                    <Elevation  >
                        <div className="e-panel-container" style={{ overflowY: "auto", overflowX: "hidden" }}>
                            {/* <EmpresaRedes empresa={empresa} /> */}
                        </div>
                    </Elevation>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} sx={{display:'flex', flexDirection:'column', p:2}}>
                    <Grid container spacing={0}>
                        {btn_seccion.map((btn) => (
                            <Grid key={btn.name} item xs={6} sm={6} md={2} lg={4} xl={4} sx={{p:1}} className="center_item">
                                <Button variant="contained"
                                    className="btn_square"
                                    sx={ seccionId === btn.id ? 
                                        {color: theme.palette.primary.contrastText, bg: theme.palette.primary.main, fontSize:12}:
                                        {color: theme.palette.primary.title, bgcolor: theme.palette.secondary.btn, fontSize:12}} 
                                    onClick={()=>changeSection(btn.id)}
                                    disableElevation>
                                    {btn.name}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                    {btn_seccion[seccionId-1]?.component}
                </Grid>
            </Grid>
        </>
    )
}

export default Empresa;